<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>  
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin/alunos?secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/alunos?secretaria')"
              >Gerenciamento de alunos</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Aluno</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left mb-4">
                <h1 class="aluno_font_color">
                  Dados do
                  <span>Aluno</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <!-- Dados do aluno -->
              <div class="row">
                <div class="col-12">
                  <tabs :options="{ useUrlFragment: false }">
                    <!-- Dados pessoais -->
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Dados pessoais"
                    >
                      <div class="row">
                        <fast-cadastro-pessoa :readonly="false" :perfil="'aluno'" :id_pessoa="parseInt($route.params.id_pessoa_aluno)" :id_plataforma="parseInt($route.params.id_plataforma)" />
                      </div>
                    </tab>
                    <!-- Dados médicos -->
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Dados médicos"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-4 m-2">
                              <h4>Dados médicos</h4>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              v-if="fastPlataformaAlunoDadosMedicos.carregando"
                              class="col-12 text-center"
                            >
                              <b-spinner
                                type="grow"
                                label="Spinning"
                              />
                            </div>
                            <div
                              v-else
                              class="col-12 p-4"
                            >
                              <div class="fd-app-curso-aulas">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div
                                    class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0"
                                  >
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Catapora</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_catapora
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Meningite</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_meningite
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Hepatite</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_hepatite
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Dengue</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_dengue
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Pneumonia</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_pneumonia
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Alergia pele</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_alergia_pele
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Alergia alimentar</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_alergia_alimentar
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Bronquite</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_alergia_bronquite
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Rinite</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_alergia_rinite
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Problema crescimento</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.problema_crescimento
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Atraso desenvolvimento</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.atraso_desenvolvimento
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Dificuldade visão</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.dificuldade_visao
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Dificuldade fala</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.dificuldade_fala
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Dificuldade audição</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.dificuldade_audicao
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Dificuldade de movimento</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.dificuldade_movimento
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Problema no coração</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.problema_coracao
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Intolerante à lactose</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_intolerancia_lactose
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Intolerante ao glúten</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_intolerancia_gluten
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Usa prótese</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_usa_protese
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Convulsão</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_convulsao
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Desmaios</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_desmaio
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Diabetes</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_diabetes
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Problema na coluna</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_coluna
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Fratura</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_fratura
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Fez cirurgia</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_cirurgia
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Problema de peso</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_problema_peso
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-3 mb-3"
                                    >
                                      <div class="col-12 text-center mb-1">
                                        <span>Foi internado</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoDadosMedicos.anamnse_foi_internado
                                            "
                                            type="checkbox"
                                            @change="
                                              atualizaAlunoDadosMedicos()
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>Vive com</label>
                                  <div class="form-group">
                                    <input
                                      v-model="
                                        fastPlataformaAlunoDadosMedicos.vive_com
                                      "
                                      class="form-control"
                                      type="text"
                                      @keyup="
                                        fastPlataformaAlunoDadosMedicos.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>Parto foi</label>
                                  <div class="form-group">
                                    <input
                                      v-model="
                                        fastPlataformaAlunoDadosMedicos.parto_foi
                                      "
                                      class="form-control"
                                      type="text"
                                      @keyup="
                                        fastPlataformaAlunoDadosMedicos.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4">
                                  <label>Tipo sanguíneo</label>
                                  <div class="form-group">
                                    <input
                                      v-model="
                                        fastPlataformaAlunoDadosMedicos.tipo_sanquineo
                                      "
                                      class="form-control"
                                      type="text"
                                      @keyup="
                                        fastPlataformaAlunoDadosMedicos.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                  <label>Observações</label>
                                  <div class="form-group">
                                    <textarea
                                      v-model="
                                        fastPlataformaAlunoDadosMedicos.observacao
                                      "
                                      class="form-control"
                                      @keyup="
                                        fastPlataformaAlunoDadosMedicos.alteracao_pendente = true
                                      "
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="
                                  fastPlataformaAlunoDadosMedicos.alteracao_pendente
                                "
                                class="row"
                              >
                                <div
                                  class="col-sm-12 col-md-12 col-lg-12 m-2 text-center"
                                >
                                  <button
                                    class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                                    @click="atualizaAlunoDadosMedicos()"
                                  >
                                    <small>
                                      <i
                                        class="fa fa-floppy-o"
                                        aria-hidden="true"
                                      />
                                      &nbsp;Salvar alterações
                                    </small>
                                  </button>
                                  <button
                                    class="btn btn-secondary btn-sm mt-2 ml-2"
                                    @click.prevent="
                                      getFastPlataformaAlunoDadosMedicos()
                                    "
                                  >
                                    <small>
                                      <i
                                        class="fa fa-ban"
                                        aria-hidden="true"
                                      />
                                      &nbsp;Cancelar
                                    </small>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <!-- Documentos -->
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Documentos"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-4 m-2">
                              <h4>Documentos</h4>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              v-if="fastPlataformaAlunoDocumento.carregando"
                              class="col-12 text-center"
                            >
                              <b-spinner
                                type="grow"
                                label="Spinning"
                              />
                            </div>
                            <div
                              v-else
                              class="col-12"
                            >
                              <div class="row">
                                <div
                                  class="col-sm-12 col-md-12 col-lg-12 text-right"
                                >
                                  <button
                                    v-if="fastSecretariaTipoDocumentos.length"
                                    class="btn btn-sm btn-primary"
                                    @click.prevent="
                                      showModal('modalCadastrarAlunoDocumento'),
                                      docDuplicado();
                                    "
                                  >
                                    <small>+ Documento</small>
                                  </button>
                                  <div v-else>
                                    <small
                                      class="text-danger"
                                    >Você não possui nenhum tipo de documento
                                      cadastrado na plataforma</small>
                                    <br>
                                    <a href="">Cadastrar documentos</a>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12 table-responsive p-4">
                                  <table class="table table-sm table-striped">
                                    <thead class="thead-dark">
                                      <tr>
                                        <th>Tipo documento</th>
                                        <th class="text-center">
                                          Link
                                        </th>
                                        <th class="text-center">
                                          Ações
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody
                                      v-if="
                                        fastPlataformaAlunoDocumentos.length
                                      "
                                    >
                                      <tr
                                        v-for="(
                                          d, index
                                        ) in fastPlataformaAlunoDocumentos"
                                        :key="index"
                                      >
                                        <td>
                                          {{ d.nome_documento }}
                                        </td>
                                        <td class="text-center">
                                          <a
                                            :href="ajustaLinkApiAntiga(d.path_documento)"
                                            target="_blank"
                                            download
                                          >{{ d.path_documento }}</a>
                                        </td>
                                        <td class="text-center">
                                          <button
                                            class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                            @click="
                                              exibeModalExcluirAlunoDocumento(d)
                                            "
                                          >
                                            <small
                                              class="text-white"
                                            >Excluir</small>
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tbody v-else>
                                      <tr>
                                        <td
                                          colspan="3"
                                          class="text-center"
                                        >
                                          <span>Nenhum documento cadastrado</span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <!-- Bolsa -->
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Bolsa"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-2 m-2">
                              <h4>Bolsa</h4>
                              <div class="fd-app-curso-aulas mt-0">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div
                                    class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0"
                                  >
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-12 p-0"
                                    >
                                      <div class="col-12">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoBolsa.bolsa
                                            "
                                            type="checkbox"
                                            @change="
                                              fastPlataformaAlunoBolsa.alteracao_pendente = true
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              v-if="fastPlataformaAlunoBolsa.carregando"
                              class="col-12 text-center"
                            >
                              <b-spinner
                                type="grow"
                                label="Spinning"
                              />
                            </div>
                            <div
                              v-else
                              class="col-12 p-4"
                            >
                              <div
                                v-show="fastPlataformaAlunoBolsa.bolsa"
                                class="row"
                              >
                                <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
                                  <h6>Percentual de desconto</h6>
                                  <div class="form-group">
                                    <input
                                      v-model="
                                        fastPlataformaAlunoBolsa.desconto_percentual
                                      "
                                      class="form-control"
                                      type="number"
                                      @keyup="
                                        fastPlataformaAlunoBolsa.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
                                  <h6>Valor do desconto</h6>
                                  <div class="form-group">
                                    <input
                                      v-model="
                                        fastPlataformaAlunoBolsa.desconto_valor
                                      "
                                      class="form-control"
                                      type="number"
                                      step=".01"
                                      @keyup="
                                        fastPlataformaAlunoBolsa.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
                                  <div class="form-group">
                                    <div class="fd-app-curso-aulas mt-0">
                                      <div class="fd-app-curso-aulas-list mt-0">
                                        <div
                                          class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0"
                                        >
                                          <div
                                            class="col-sm-12 col-md-12 col-lg-12"
                                          >
                                            <div
                                              class="col-12 text-center mb-1"
                                            >
                                              <span>Participante do bolsa
                                                família</span>
                                            </div>
                                            <div class="col-12 text-center">
                                              <label class="switch">
                                                <input
                                                  v-model="
                                                    fastPlataformaAlunoBolsa.participante_bolsa_familia
                                                  "
                                                  type="checkbox"
                                                  @change="
                                                    fastPlataformaAlunoBolsa.alteracao_pendente = true
                                                  "
                                                >
                                                <span class="slider round" />
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                  <h6>Observações</h6>
                                  <div class="form-group">
                                    <textarea
                                      v-model="fastPlataformaAlunoBolsa.OBS"
                                      class="form-control"
                                      @keyup="
                                        fastPlataformaAlunoBolsa.alteracao_pendente = true
                                      "
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="
                                  fastPlataformaAlunoBolsa.alteracao_pendente
                                "
                                class="row"
                              >
                                <div
                                  class="col-sm-12 col-md-12 col-lg-12 m-2 text-center"
                                >
                                  <button
                                    class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                                    @click="atualizaPlataformaAlunoBolsa()"
                                  >
                                    <small>
                                      <i
                                        class="fa fa-floppy-o"
                                        aria-hidden="true"
                                      />
                                      &nbsp;Salvar alterações
                                    </small>
                                  </button>
                                  <button
                                    class="btn btn-secondary btn-sm mt-2 ml-2"
                                    @click.prevent="
                                      getFastPlataformaAlunoBolsa(
                                        $route.params.id_plataforma,
                                        $route.params.id_pessoa_aluno
                                      )
                                    "
                                  >
                                    <small>
                                      <i
                                        class="fa fa-ban"
                                        aria-hidden="true"
                                      />
                                      &nbsp;Cancelar
                                    </small>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <!-- Carteira -->
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Carteira"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-4 m-2">
                              <h4>Carteira</h4>
                              <div class="fd-app-curso-aulas mt-0">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div
                                    class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0"
                                  >
                                    <div
                                      class="col-sm-12 col-md-12 col-lg-12 p-0"
                                    >
                                      <div class="col-12">
                                        <label class="switch">
                                          <input
                                            v-model="
                                              fastPlataformaAlunoCarteira.carteira_gerada
                                            "
                                            type="checkbox"
                                            @change="
                                              fastPlataformaAlunoCarteira.alteracao_pendente = true
                                            "
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              v-if="fastPlataformaAlunoCarteira.carregando"
                              class="col-12 text-center"
                            >
                              <b-spinner
                                type="grow"
                                label="Spinning"
                              />
                            </div>
                            <div
                              v-else
                              class="col-12 p-4"
                            >
                              <div
                                v-show="
                                  fastPlataformaAlunoCarteira.carteira_gerada
                                "
                                class="row"
                              >
                                <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
                                  <h6>Validade</h6>
                                  <div class="form-group">
                                    <input
                                      v-model="
                                        fastPlataformaAlunoCarteira.validade
                                      "
                                      class="form-control"
                                      type="date"
                                      @keyup="
                                        fastPlataformaAlunoCarteira.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="
                              fastPlataformaAlunoCarteira.alteracao_pendente
                            "
                            class="row"
                          >
                            <div
                              class="col-sm-12 col-md-12 col-lg-12 m-2 text-center"
                            >
                              <button
                                class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                                @click="atualizaPlataformaAlunoCarteira()"
                              >
                                <small>
                                  <i
                                    class="fa fa-floppy-o"
                                    aria-hidden="true"
                                  />
                                  &nbsp;Salvar alterações
                                </small>
                              </button>
                              <button
                                class="btn btn-secondary btn-sm mt-2 ml-2"
                                @click.prevent="
                                  getFastPlataformaAlunoCarteira(
                                    $route.params.id_plataforma,
                                    $route.params.id_pessoa_aluno
                                  )
                                "
                              >
                                <small>
                                  <i
                                    class="fa fa-ban"
                                    aria-hidden="true"
                                  />
                                  &nbsp;Cancelar
                                </small>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <!-- Responsáveis -->
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Responsáveis"
                    >
                      <div class="row">
                        <fast-cadastro-pessoa-responsavel :id_plataforma="parseInt($route.params.id_plataforma)" :id_pessoa_aluno="parseInt($route.params.id_pessoa_aluno)" />
                      </div>
                    </tab>
                  </tabs>
                </div>
              </div>
              <!-- /Dados do aluno -->
              <section class="fd-app-welcome text-left mb-2 mt-2">
                <h3 class="aluno_font_color">
                  Registros do
                  <span>Aluno</span>
                </h3>
              </section>
              <!-- Registros do aluno -->
              <div class="row">
                <div class="col-12">
                  <tabs :options="{ useUrlFragment: false }">
                    <!-- Matrículas -->
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Matrículas"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-4 m-2">
                              <h4>Matrículas</h4>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              v-if="fastMatriculaAluno.carregando"
                              class="col-12 text-center"
                            >
                              <b-spinner
                                type="grow"
                                label="Spinning"
                              />
                            </div>
                            <div
                              v-else
                              class="col-12"
                            >
                              <div class="row">
                                <div
                                  class="col-sm-12 col-md-12 col-lg-12 text-right"
                                >
                                  <button
                                    class="btn btn-sm btn-primary d-none"
                                    @click.prevent="
                                      showModal('modalPlataformaTurmas')
                                    "
                                  >
                                    <small>Realizar matrícula</small>
                                  </button>
                                  <button
                                    class="btn btn-sm btn-primary"
                                    @click="validaDadosMatricula()"
                                  >
                                    <small>Realizar matrícula</small>
                                  </button>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12 table-responsive p-4 min-height-table">
                                  <table class="table table-sm table-striped">
                                    <thead class="thead-dark">
                                      <tr>
                                        <th class="text-center">
                                          Matrícula
                                        </th>
                                        <th class="text-center">
                                          Data
                                        </th>
                                        <th class="text-center">
                                          Nível
                                        </th>
                                        <th class="text-center">
                                          Turma
                                        </th>
                                        <th class="text-center">
                                          Plano
                                        </th>
                                        <th class="text-center">
                                          Ativo
                                        </th>
                                        <th class="text-center">
                                          Pago
                                        </th>
                                        <th class="text-center">
                                          Encerrado
                                        </th>
                                        <th class="text-center">
                                          Ações
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody v-if="fastMatriculasAluno.length">
                                      <tr
                                        v-for="(
                                          matricula, index
                                        ) in fastMatriculasAluno"
                                        :key="index"
                                      >
                                        <td class="text-center">
                                          #{{ matricula.id_matricula }}
                                        </td>
                                        <td class="text-center">
                                          <span v-if="matricula.data_matricula">{{ formataDataT(matricula.data_matricula) }}</span>
                                        </td>
                                        <td class="text-center">
                                          {{
                                            retornaCursoNivel(matricula.nivel)
                                          }}
                                        </td>
                                        <td class="text-center">
                                          {{ matricula.sigla_turma }}
                                        </td>
                                        <td class="text-center">
                                          <span
                                            v-if="
                                              matricula.matricula_por_programa
                                            "
                                          >{{
                                            matricula.qtde_modulos_compra
                                          }}
                                            módulo(s)</span>
                                          <span
                                            v-else-if="
                                              matricula.matricula_por_unidade
                                            "
                                          >{{
                                            matricula.qtde_unidades_compra
                                          }}
                                            unidade(s)</span>
                                          <span v-else>Curso completo</span>
                                        </td>
                                        <td class="text-center">
                                          <button
                                            v-if="matricula.ativo"
                                            class="btn btn-sm btn-success pt-0 pb-0"
                                          >
                                            <small>Sim</small>
                                          </button>
                                          <button
                                            v-else
                                            class="btn btn-sm btn-danger pt-0 pb-0"
                                          >
                                            <small>Não</small>
                                          </button>
                                        </td>
                                        <td class="text-center">
                                          <button
                                            v-if="matricula.pago"
                                            class="btn btn-sm btn-success pt-0 pb-0"
                                          >
                                            <small>Sim</small>
                                          </button>
                                          <button
                                            v-else
                                            class="btn btn-sm btn-danger pt-0 pb-0"
                                          >
                                            <small>Não</small>
                                          </button>
                                        </td>
                                        <td class="text-center">
                                          <button
                                            v-if="matricula.curso_concluido"
                                            class="btn btn-sm btn-success pt-0 pb-0"
                                          >
                                            <small>Sim</small>
                                          </button>
                                          <button
                                            v-else
                                            class="btn btn-sm btn-danger pt-0 pb-0"
                                          >
                                            <small>Não</small>
                                          </button>
                                        </td>
                                        <td class="text-center text-nowrap">
                                            <a
                                              class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                              @click="
                                                exibeModalMatricularAlunoEditar(
                                                  matricula
                                                )
                                              "
                                            >
                                              <small
                                                class="text-white"
                                              >Editar</small>
                                          </a>
                                          <b-dropdown size="sm" variant="light rounded">
                                            <b-dropdown-item @click.prevent="$router.push('/plataforma/' + matricula.id_plataforma + '/secretaria/turma/' + matricula.id_turma + '/alunos?id_matricula=' + matricula.id_matricula)"><small>Estatísticas</small></b-dropdown-item>
                                            <b-dropdown-item class="danger" @click.prevent="exibeModalHistoricosECertificados(matricula.id_matricula, matricula.id_secretaria_curso)"> <small>Históricos/Certificados</small> </b-dropdown-item>
                                            <b-dropdown-item v-if="matricula.id_assinatura_plataforma_documento" @click.prevent="exibeModalConsultaDocumentos(matricula.id_assinatura_plataforma_documento, matricula.id_matricula)"><small>Consultar documento para assinatura</small></b-dropdown-item>
                                            <b-dropdown-item v-else-if="matricula.id_invoice" @click.prevent="exibeModalAssinaturaDocumentos(matricula.id_matricula, matricula.id_secretaria_curso)"><small>Criar documento para assinatura</small></b-dropdown-item>
                                            <b-dropdown-item v-else @click.prevent="exibeToasty('Assinatura indisponível para matrículas sem invoice gerado', 'error')"><small>Assinatura de documentos</small></b-dropdown-item>
                                            <b-dropdown-item v-if="!matricula.curso_concluido" @click.prevent="exibeModalMatriculaAlunoEncerrarCursoMatricula(matricula)"><small>Encerrar matrícula</small></b-dropdown-item>
                                            <b-dropdown-item v-else disabled><small>Encerrar matrícula</small></b-dropdown-item>
                                          </b-dropdown>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tbody v-else>
                                      <tr>
                                        <td
                                          colspan="8"
                                          class="text-center"
                                        >
                                          <span>Nenhuma matrícula cadastrada</span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <!-- /Matrículas -->
                    <!-- Financeiro -->
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Financeiro"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-4 m-2 mb-4">
                              <h4>Financeiro</h4>
                            </div>
                          </div>
                          <transition name="slide-fade">
                            <div>
                                <div class="row">
                                  <div class="col-12 table-responsive">
                                    <table id="relatorio" class="table d-none">
                                      <thead>
                                        <tr>
                                          <th>
                                            <small>Vencimento</small>
                                          </th>
                                          <th>
                                            <small>Recebimento</small>
                                          </th>
                                          <th>
                                            <small>Dias de Atraso</small>
                                          </th>
                                          <th>
                                            <small>Invoice</small>
                                          </th>
                                          <th>
                                            <small>Parcela</small>
                                          </th>
                                          <th>
                                            <small>Aluno</small>
                                          </th>
                                          <th>
                                            <small>Valor Parcela</small>
                                          </th>
                                          <th>
                                            <small>Valor Recebido</small>
                                          </th>
                                          <th>
                                            <small>Status</small>
                                          </th>
                                          <th>
                                            <small>Boleto</small>
                                          </th>
                                        </tr>
                                      </thead>
                                  <tbody>
                                    <tr
                                      v-for="(e, index) in fastRecebiveisRel.filtro"
                                      :key="index"
                                    >
                                      <td>
                                        <small>{{ formataDataT(e.data_vencimento) }}</small>                                  
                                      </td>
                                      <td>
                                        <small>{{ formataDataT(e.data_pagamento) }}</small>                                  
                                      </td>
                                      <td>
                                        <small v-if="!e.pago && !e.cancelado && new Date(e.data_vencimento) < new Date() && calculaDiasAtraso(e.data_vencimento) > 0">{{ calculaDiasAtraso(e.data_vencimento) }}</small>     
                                        <small v-else>-</small>                            
                                      </td>
                                      <td>
                                          <small>{{ e.id_invoice }}</small>                               
                                      </td>
                                      <td>
                                        <small> {{ e.parcela }}/{{ e.nr_parcelas }} </small>                             
                                      </td>
                                      <td>
                                        <small> {{ e.nome_aluno }} </small>                                 
                                      </td>
                                      <td>
                                        <small>{{ formataPreco(e.valor_parcela) }}</small>                                  
                                      </td>
                                      <td>
                                        <small v-if="e.valor_pago && e.pago">{{ formataPreco(e.valor_pago) }}</small> 
                                        <small v-else>{{ formataPreco(0)}} </small>                                 
                                      </td>
                                      <td>
                                        <span v-if="e.cancelado"><small>Cancelado</small> </span>
                                        <span v-else-if="e.pago"><small>Pago</small> </span>
                                        <span v-else-if="!e.pago && !e.cancelado && new Date(e.data_vencimento) < new Date()"><small>Vencido</small></span>
                                        <span v-else-if="!e.pago && !e.cancelado && new Date(e.data_vencimento) >= new Date()"><small>Pendente</small> </span>
                                      </td>
                                      <td>
                                        <small v-if="e.path_boleto">{{ e.path_boleto }}</small>
                                        <small v-else>-</small>
                                      </td>
                                      <td>
                                        <small v-if="e.log_pagto_auditoria">{{ e.log_pagto_auditoria }}</small>
                                        <small v-else>-</small>
                                      </td>
                                    </tr>
                                  </tbody>
                                    </table>
                                    <table class="table table-sm text-nowrap table-hover">
                                      <thead class="thead-dark" v-if="fastRecebiveisRel.loading">
                                        <tr>
                                          <th
                                            colspan="12"
                                            class="text-center"
                                          >
                                          <b-icon icon="gear-fill" animation="spin"/> Carregando 
                                          </th>
                                        </tr>
                                      </thead>
                                      <thead class="thead-dark" v-if="!fastRecebiveisRel.loading">
                                        <tr>
                                          <th class="pl-3 pt-2">
                                            <b-form-checkbox v-model="fastRecebiveisRel.selectAll"/>
                                          </th>
                                          <th class="text-center">
                                            <small :class="{ active: this.fastRecebiveisRel.orderBy.vencimento !== 0 }" @click="orderByColumn('vencimento')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                                              Vencimento&nbsp;
                                              <div class="d-flex flex-column">
                                                <b-icon v-if="this.fastRecebiveisRel.orderBy.vencimento === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                                <b-icon v-if="this.fastRecebiveisRel.orderBy.vencimento === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                                              </div>
                                            </small>
                                          </th>
                                          <th class="text-center">
                                            <small :class="{ active: this.fastRecebiveisRel.orderBy.recebimento !== 0 }" @click="orderByColumn('recebimento')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                                              Recebimento&nbsp;
                                              <div class="d-flex flex-column">
                                                <b-icon v-if="this.fastRecebiveisRel.orderBy.recebimento === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                                <b-icon v-if="this.fastRecebiveisRel.orderBy.recebimento === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                                              </div>
                                            </small>
                                          </th>
                                          <th class="text-center">
                                            <small :class="{ active: this.fastRecebiveisRel.orderBy.dias_de_atraso !== 0 }" @click="orderByColumn('dias_de_atraso')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                                              Dias de Atraso&nbsp;
                                              <div class="d-flex flex-column">
                                                <b-icon v-if="this.fastRecebiveisRel.orderBy.dias_de_atraso === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                                <b-icon v-if="this.fastRecebiveisRel.orderBy.dias_de_atraso === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                                              </div>
                                            </small>
                                          </th>
                                          <th class="text-center">
                                            <small :class="{ active: this.fastRecebiveisRel.orderBy.invoice !== 0 }" @click="orderByColumn('invoice')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                                              Invoice&nbsp;
                                              <div class="d-flex flex-column">
                                                <b-icon v-if="this.fastRecebiveisRel.orderBy.invoice === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                                <b-icon v-if="this.fastRecebiveisRel.orderBy.invoice === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                                              </div>
                                            </small>
                                          </th>
                                          <th class="text-center">
                                            <small class="font-weight-bold d-flex pb-1 justify-content-center">
                                              Parcela
                                              <div class="d-flex flex-column"></div>
                                            </small>
                                          </th>
                                          <th class="text-center">
                                            <small class="font-weight-bold d-flex pb-1 justify-content-center">
                                              Aluno
                                              <div class="d-flex flex-column">
                                              </div>
                                            </small>
                                          </th>
                                          <th class="text-center">
                                            <small :class="{ active: this.fastRecebiveisRel.orderBy.valor_parcela !== 0 }" @click="orderByColumn('valor_parcela')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                                              Valor Parcela&nbsp;
                                              <div class="d-flex flex-column">
                                                <b-icon v-if="this.fastRecebiveisRel.orderBy.valor_parcela === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                                <b-icon v-if="this.fastRecebiveisRel.orderBy.valor_parcela === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                                              </div>
                                            </small>
                                          </th>
                                          <th class="text-center">
                                            <small :class="{ active: this.fastRecebiveisRel.orderBy.valor_recebido !== 0 }" @click="orderByColumn('valor_recebido')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                                              Valor Recebido&nbsp;
                                              <div class="d-flex flex-column">
                                                <b-icon v-if="this.fastRecebiveisRel.orderBy.valor_recebido === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                                <b-icon v-if="this.fastRecebiveisRel.orderBy.valor_recebido === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                                              </div>
                                            </small>
                                          </th>
                                          <th class="text-center">
                                            <small :class="{ active: this.fastRecebiveisRel.orderBy.status !== 0 }" @click="orderByColumn('status')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                                              Status&nbsp;
                                              <div class="d-flex flex-column">
                                                <b-icon v-if="this.fastRecebiveisRel.orderBy.status === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                                <b-icon v-if="this.fastRecebiveisRel.orderBy.status === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                                              </div>
                                            </small>
                                          </th>
                                          <th class="text-center">
                                            <small class="font-weight-bold d-flex pb-1 justify-content-center">
                                              Boleto
                                              <div class="d-flex flex-column"></div>
                                            </small>
                                          </th>
                                        </tr>
                                      </thead>
                                  <tbody v-if="fastRecebiveisRel.loading">
                                    <tr>
                                      <td
                                        colspan="12"
                                        class="text-center"
                                      >
                                      <b-icon icon="gear-fill" animation="spin"/> Carregando 
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else-if="fastRecebiveisRel.paginacao.length">
                                    <tr
                                      v-for="(e, index) in fastRecebiveisRel.paginacao"
                                      :key="index"
                                    >
                                      <td class="pl-3 pt-2"
                                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                                      >
                                        <b-form-checkbox role="button" type="checkbox" v-model="fastRecebiveisRel.selectedItems" :value="e.id_invoice + '-' + e.parcela"/>
                                      </td>
                                      <td class="align-middle text-center"
                                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                                        role="button"
                                        @click="exibeDetalhes(e)"
                                      >
                                        <small>{{ formataDataT(e.data_vencimento) }}</small>                                  
                                      </td>
                                      <td class="align-middle text-center"
                                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}" 
                                        role="button"
                                        @click="exibeDetalhes(e)"
                                      >
                                        <small>{{ formataDataT(e.data_pagamento) }}</small>                                  
                                      </td>
                                      <td class="align-middle text-center"
                                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                                        role="button"
                                        @click="exibeDetalhes(e)"
                                      >
                                        <small v-if="!e.pago && !e.cancelado && new Date(e.data_vencimento) < new Date() && calculaDiasAtraso(e.data_vencimento) > 0">{{ calculaDiasAtraso(e.data_vencimento) }}</small>     
                                        <small v-else>-</small>                            
                                      </td>
                                      <td class="align-middle text-center"
                                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                                        role="button"
                                        @click="exibeDetalhes(e)"  
                                      >
                                          <small>{{ e.id_invoice }}</small>                               
                                      </td>
                                      <td class="align-middle text-center"
                                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                                        role="button"
                                        @click="exibeDetalhes(e)"
                                      >
                                        <small>{{ e.parcela }}/{{ e.nr_parcelas }}</small>                             
                                      </td>
                                      <td class="align-middle text-center"
                                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                                        role="button"
                                        @click="exibeDetalhes(e)"
                                      >
                                        <small>{{ e.nome_aluno }}</small>                                 
                                      </td>
                                      <td class="align-middle text-center"
                                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                                        role="button"
                                        @click="exibeDetalhes(e)"
                                      >
                                        <small>R$ {{ formataPreco(e.valor_parcela) }}</small>                                  
                                      </td>
                                      <td class="align-middle text-center"
                                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                                        role="button"
                                        @click="exibeDetalhes(e)"
                                      >
                                        <small v-if="e.valor_pago && e.pago">R$ {{ formataPreco(e.valor_pago) }}</small> 
                                        <small v-else>R$ 0,00</small>                                 
                                      </td>
                                      <td class="text-center"
                                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                                        role="button"
                                        @click="exibeDetalhes(e)"
                                      >
                                        <span v-if="e.cancelado" class="text-secondary"><small>Cancelado</small> </span>
                                        <span v-else-if="e.pago" class="text-success"><small>Pago</small> </span>
                                        <span v-else-if="!e.pago && !e.cancelado && new Date(e.data_vencimento) < new Date()"><small class="text-danger">Vencido</small></span>
                                        <span v-else-if="!e.pago && !e.cancelado && new Date(e.data_vencimento) >= new Date()" class="text-primary"><small>Pendente</small> </span>
                                      </td>
                                      <td class="align-middle text-center pl-2"
                                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                                      >
                                        <a title="ABRIR BOLETO" :href="e.path_boleto" target="_blank" class="text-dark mr-2"><b-icon icon="file-earmark-pdf" font-scale="1" ></b-icon></a>
                                        <a title="COPIAR URL BOLETO" href="#" @click.prevent="copyURLBoleto(e.path_boleto); exibeToasty('Boleto copiado para a Área de Transferência!','success')" target="_blank" class="text-dark"><b-icon icon="back" font-scale="1"></b-icon></a>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else>
                                    <tr>
                                      <td
                                        colspan="12"
                                        class="text-center"
                                      >
                                        Nenhuma parcela encontrada
                                      </td>
                                    </tr>
                                  </tbody>
                                    </table>
                                  </div>
                                  <div class="col-12 text-right mt-4">
                                      <div class="d-inline">
                                        <h6 class="font-weight-bold d-inline">Valor Total: </h6>
                                        <h5 class="d-inline"><b-badge variant="primary">R$ {{ formataPreco(totalParcelaSelected) }}</b-badge></h5>
                                      </div>
                                      <div class="mt-2 mb-2">
                                        <h6 class="font-weight-bold d-inline">Valor Recebido: </h6>
                                        <h5 class="d-inline"><b-badge variant="success">R$ {{ formataPreco(totalParcelaPagoSelected) }}</b-badge></h5>
                                      </div>
                                  </div>
                                  <div class="col-12 text-center mt-4">
                                <Pagination
                                  :page-size="20"
                                  :items="fastRecebiveisRel.filtro"
                                  @changePage="fastRecebiveisRel.paginacao=$event"/>
                              </div>
                                </div>
                            </div>
                          </transition>
                        </div>
                      </div>
                    </tab>
                    <!-- /Financeiro -->
                    <!-- Ocorrências -->
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Ocorrências"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-4 m-2">
                              <h4>Ocorrências</h4>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <div class="row">
                                <div
                                  class="col-sm-12 col-md-12 col-lg-12 text-right"
                                >
                                  <button
                                    class="btn btn-sm btn-primary"
                                    @click.prevent="showModal('modalSelecionaTipoOcorrencia')"
                                  >
                                    <small>+ Registrar ocorrência</small>
                                  </button>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12 table-responsive p-4">
                                  <table class="table table-sm table-striped">
                                    <thead class="thead-dark">
                                      <tr>
                                        <th class="text-center">
                                          Id_ocorrencia
                                        </th>
                                        <th class="text-center">
                                          Nome
                                        </th>
                                        <th class="text-center">
                                          Data registro
                                        </th>
                                        <th class="text-center">
                                          Ações
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody v-if="fastOcorrencias.length">
                                      <tr
                                        v-for="ocorrencia in fastOcorrencias"
                                        :key="ocorrencia.id_registro_ocorrencia"
                                      >
                                        <td class="text-center">
                                          #{{ ocorrencia.id_registro_ocorrencia }}
                                        </td>
                                        <td class="text-center">
                                          {{ ocorrencia.nome_ocorrencia }}
                                        </td>
                                        <td class="text-center">
                                          {{ formataDataT(ocorrencia.data_registro) }}
                                        </td>
                                        <td class="text-center">
                                          <button
                                            class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                            @click="exibeModalEditarOcorrencia(ocorrencia)"
                                          >
                                            <small
                                              class="text-white"
                                            >Editar</small>
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tbody v-else>
                                      <tr>
                                        <td
                                          colspan="4"
                                          class="text-center"
                                        >
                                          <span>Nenhuma ocorrência registrada</span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <!-- /Ocorrências -->
                    <!-- Requerimentos -->
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Requerimentos"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-4 m-2">
                              <h4>Requerimentos</h4>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <div class="row">
                                <div
                                  class="col-sm-12 col-md-12 col-lg-12 text-right"
                                >
                                  <button
                                    class="btn btn-sm btn-primary"
                                    @click.prevent="showModal('modalSelecionaTipoRequerimento')"
                                  >
                                    <small>+ Criar requerimento</small>
                                  </button>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12 table-responsive p-4">
                                  <table class="table table-sm table-striped">
                                    <thead class="thead-dark">
                                      <tr>
                                        <th class="text-center">
                                          Id_requerimento
                                        </th>
                                        <th class="text-center">
                                          Nome
                                        </th>
                                        <th class="text-center">
                                          Data registro
                                        </th>
                                        <th class="text-center">
                                          Data análise
                                        </th>
                                        <th class="text-center">
                                          Aprovado
                                        </th>
                                        <th class="text-center">
                                          Pago
                                        </th>
                                        <th class="text-center">
                                          Ações
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody v-if="fastRequerimentos.length">
                                      <tr
                                        v-for="requerimento in fastRequerimentos"
                                        :key="requerimento.id_registro_requerimento"
                                      >
                                        <td class="text-center">
                                          #{{ requerimento.id_registro_requerimento }}
                                        </td>
                                        <td class="text-center">
                                          {{ requerimento.nome_requerimento }}
                                        </td>
                                        <td class="text-center">
                                          {{ formataDataT(requerimento.data_registro) }}
                                        </td>
                                        <td class="text-center">
                                          {{ formataDataT(requerimento.data_inicio_analise) }}
                                        </td>
                                        <td class="text-center">
                                          <div v-if="requerimento.aprovada">
                                            <button
                                              v-if="requerimento.aprovada.trim() == 'S'"
                                              class="btn btn-sm btn-success pt-0 pb-0"
                                            >
                                              <small>Sim</small>
                                            </button>
                                            <button
                                              v-else
                                              class="btn btn-sm btn-danger pt-0 pb-0"
                                            >
                                              <small>Não</small>
                                            </button>
                                          </div>
                                          <button
                                            v-else
                                            class="btn btn-sm btn-danger pt-0 pb-0"
                                          >
                                            <small>Não</small>
                                          </button>
                                        </td>
                                        <td class="text-center">
                                          <button
                                            v-if="requerimento.pago"
                                            class="btn btn-sm btn-success pt-0 pb-0"
                                          >
                                            <small>Sim</small>
                                          </button>
                                          <button
                                            v-else
                                            class="btn btn-sm btn-danger pt-0 pb-0"
                                          >
                                            <small>Não</small>
                                          </button>
                                        </td>
                                        <td class="text-center">
                                          <button
                                            class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                            @click="exibeModalEditarRequerimento(requerimento)"
                                          >
                                            <small
                                              class="text-white"
                                            >Editar</small>
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tbody v-else>
                                      <tr>
                                        <td
                                          colspan="7"
                                          class="text-center"
                                        >
                                          <span>Nenhum requerimento cadastrado</span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <!-- /Requerimentos -->
                  </tabs>
                </div>
              </div>
              <!-- Registros -->
              <div class="mt-5 mb-5">
                <a
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/admin/alunos?secretaria'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/alunos?secretaria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- #region Modals -->    
    <modal
      name="modalCadastrarAlunoDocumento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Cadastrar documento</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCadastrarAlunoDocumento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Tipo de documento</label>
              <div class="form-group">
                <select
                  v-model="fastPlataformaAlunoDocumento.tipo_documento"
                  class="form-control"
                  @change="docDuplicado()"
                >
                  <option value="">Selecione um documento</option>
                  <option
                    v-for="documento in fastSecretariaTipoDocumentos"
                    :key="documento.tipo_documento"
                    :value="documento.tipo_documento"
                  >
                    {{ documento.nome_documento }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="fastPlataformaAlunoDocumentoValida" class="mx-auto">
              <div class="alert alert-warning"  role="alert">
                <div class="text-center mb-2">Já existe um documento do tipo {{ fastPlataformaAlunoDocumentoValida.nome_documento }} adicionado</div>
                <div class="text-center">
                  <button class="btn btn-sm btn-danger pt-0 pb-0 mr-2" @click="exibeModalExcluirAlunoDocumento(fastPlataformaAlunoDocumentoValida)">
                    <small class="text-white" >Excluir</small>
                  </button>
                </div>
              </div>
            </div>
            <div v-else-if="fastPlataformaAlunoDocumento.tipo_documento != ''" class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <uploader
                :file-status-text="statusText"
                :options="optionsArquivo"
                class="uploader-example"
                @file-success="fileSuccess"
                @file-added="fileValidation"
              >
                <uploader-unsupport />
                <uploader-drop>
                  <p class="aluno_font_color">
                    Arraste um documento aqui ou
                  </p>
                  <uploader-btn
                    :attrs="restrictArquivo"
                  >
                    Clique aqui para selecionar
                  </uploader-btn>
                </uploader-drop>
                <uploader-list />
              </uploader>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirAlunoDocumento"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir documento?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirAlunoDocumento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirAlunoDocumento()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    
    <modal
      name="modalMatricularAlunoEditar"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar matrícula</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalMatricularAlunoEditar')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
              <label>RA</label>
              <input
                v-model="fastMatriculaAlunoEditar.RA"
                type="text"
                class="form-control"
                maxlength="30"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Vencimento da matrícula</label>
              <input
                v-model="fastMatriculaAlunoEditar.vencimento_matricula"
                type="date"
                class="form-control"
              >
            </div>
            
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div
                    class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0"
                  >
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center mb-1">
                        <span>Ativo</span>
                      </div>
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastMatriculaAlunoEditar.ativo"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 table-responsive">  
                <table class="table table-sm table-striped">
                  <thead class="thead-dark">
                    <tr>
                      <th class="text-center">
                        Parcela
                      </th>
                      <th class="text-center">
                        Valor
                      </th>
                      <th class="text-center">
                        Status
                      </th>
                      <th class="text-center">
                        Vencimento
                      </th>
                      <th class="text-center">
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="fastMatriculaInvoice.loading">
                    <tr class="text-center"> 
                      <td colspan="5">
                        Carregando...
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="fastMatriculaInvoice.parcelas.length">
                    <tr
                      v-for="(parcela, index) in fastMatriculaInvoice.parcelas"
                      :key="index"
                    >
                      <td class="text-center">
                        {{ parcela.parcela }}
                      </td>
                      <td class="text-center">
                        R$ {{ formataPreco(parcela.valor_parcela) }}
                      </td>
                      <td class="text-center">
                        <button
                          v-if="parcela.pago"
                          class="btn btn-sm btn-success pt-0 pb-0"
                        >
                          <small>Pago</small>
                        </button>
                        <button
                          v-else-if="parcela.cancelado_invoice"
                          class="btn btn-sm btn-secondary pt-0 pb-0"
                        >
                          <small>Cancelado</small>
                        </button>
                        <button
                          v-else-if="!parcela.pago && !parcela.cancelado_invoice && new Date(parcela.data_vencimento) < new Date()"
                          class="btn btn-sm btn-danger pt-0 pb-0"
                        >
                          <small>Vencido</small>
                        </button>
                        <button
                          v-else
                          class="btn btn-sm btn-info pt-0 pb-0"
                        >
                          <small>Pendente</small>
                        </button>
                      </td>
                      <td class="text-center">
                        {{ formataDataT(parcela.data_vencimento) }}
                      </td>
                      <td class="text-center">
                        <a v-if="parcela.path_boleto" class="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-1 mx-auto" :href="parcela.path_boleto" target="_blank">
                          <small class="text-white">Download</small>
                        </a>
                        <span v-else class="text-secondary">
                          <small>Boleto sem registro</small>
                        </span>
                        &nbsp;
                        <a v-if="parcela.path_boleto && ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S')" class="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-1 mx-auto" 
                        :href="'/plataforma/' + $route.params.id_plataforma + '/tesouraria/emissao-boletos?idInvoice=' + parcela.id_invoice + '&parcela=' + parcela.parcela" 
                        >
                          <small class="text-white">Detalhes</small>
                        </a>
                        &nbsp;
                        <button v-if="parcela.path_boleto" class="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-1 mx-auto" @click="exibeModalWhatsApp(parcela)">
                          <small><b-icon icon="share" aria-hidden="true"></b-icon> WhatsApp</small>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td
                        colspan="5"
                        class="text-center"
                      >
                        <span>Nenhuma parcela gerada</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Total da matrícula:</label>
                R$ <span>{{ retornaTotalMatricula(fastMatriculaAlunoEditar.valor_matricula, fastMatriculaAlunoEditar.desconto_matricula, fastMatriculaAlunoEditar.outros_valores)}}</span>
                          
            </div>
            
            
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2 mt-2">
              <label class="font-weight-bold">Dados de pagamento do curso</label>
              <div class="row mb-2">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <span>Total: R$ {{formataPreco(fastTesourariaInvoice.total)}}</span>
                  <div v-if="fastProfessorComissao.id_professor">
                    
                    <div>
                      Comissão registrada de 
                      <span v-if="fastProfessorComissao.comissao_porcent">{{fastProfessorComissao.comissao_porcent}}%</span>
                      <span v-else>R$ {{formataPreco(fastProfessorComissao.comissao_valor)}}</span>
                      <span> para o professor {{fastProfessorComissao.nome_razao + " " + fastProfessorComissao.sobrenome_fantasia}}</span> em cima do valor total
                    </div>
                  </div>
                </div>                
              </div>
              
              <div class="row">
                <div class="col-12 table-responsive">
                  <table class="table table-sm table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th class="text-center">
                          Parcela
                        </th>
                        <th class="text-center">
                          Valor
                        </th>
                        <th class="text-center">
                          Status
                        </th>
                        <th class="text-center">
                          Vencimento
                        </th>
                        <th class="text-center">
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="fastTesourariaInvoice.parcelas.length">
                      <tr
                        v-for="(
                          parcela, index
                        ) in fastTesourariaInvoice.parcelas"
                        :key="index"
                      >
                        <td class="text-center">
                          {{ parcela.parcela }}
                        </td>
                        <td class="text-center">
                          R$ {{ formataPreco(parcela.valor_parcela) }}
                        </td>
                        <td class="text-center">
                            <button
                            v-if="parcela.pago"
                            class="btn btn-sm btn-success pt-0 pb-0"
                          >
                            <small>Pago</small>
                          </button>
                          <button
                            v-else-if="parcela.cancelado_invoice"
                            class="btn btn-sm btn-secondary pt-0 pb-0"
                          >
                            <small>Cancelado</small>
                          </button>
                          <button
                            v-else-if="!parcela.pago && !parcela.cancelado_invoice && new Date(parcela.data_vencimento) < new Date()"
                            class="btn btn-sm btn-danger pt-0 pb-0"
                          >
                            <small>Vencido</small>
                          </button>
                          <button
                            v-else
                            class="btn btn-sm btn-info pt-0 pb-0"
                          >
                            <small>Pendente</small>
                          </button>
                        </td>
                        <td class="text-center">
                          {{ formataDataT(parcela.data_vencimento) }}
                        </td>
                        <td class="text-center">
                          <a v-if="parcela.path_boleto" class="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-1 mx-auto" :href="parcela.path_boleto" target="_blank">
                            <small class="text-white">Download</small>
                          </a>
                          <span v-else class="text-secondary">
                            <small>Boleto sem registro</small>
                          </span>
                          &nbsp;
                          <a v-if="parcela.path_boleto && ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S')" class="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-1 mx-auto" 
                          :href="'/plataforma/' + $route.params.id_plataforma + '/tesouraria/emissao-boletos?idInvoice=' + parcela.id_invoice + '&parcela=' + parcela.parcela" 
                          >
                            <small class="text-white">Detalhes</small>
                          </a>
                          &nbsp;
                          <button v-if="parcela.path_boleto" class="btn btn-sm btn-primary pt-0 pb-0 ml-1 mr-1 mb-1 mx-auto" @click="exibeModalWhatsApp(parcela)">
                            <small><b-icon icon="share" aria-hidden="true"></b-icon> WhatsApp</small>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td
                          colspan="5"
                          class="text-center"
                        >
                          <span>Nenhuma parcela gerada</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              
            </div>

            <!-- Opções de contrato -->
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <h5>Opções de contrato 
                    <button class="btn btn-sm btn-secondary pt-0 pb-0" @click="fastExibirOpcoesContrato = !fastExibirOpcoesContrato">
                      <span v-if="!fastExibirOpcoesContrato">
                        <b-icon icon="eye-fill" aria-hidden="true"></b-icon> exibir
                      </span> 
                      <span v-else>
                        <b-icon icon="eye-slash-fill" aria-hidden="true"></b-icon> ocultar
                      </span>                                           
                    </button>  
                  </h5>
                </div>
              </div>
              <div v-if="fastExibirOpcoesContrato" class="row">
                <div class="col-12 border">
                  <div class="row">
                    <div
                      v-if="fastSecretariaContratosCurso.length"
                      class="col-sm-12 col-md-12 col-lg-12 mb-4 mt-4"
                    >
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <label>Templates de contrato</label>
                          <select
                            v-model="fastContratoEscolhido.id_contrato"
                            class="form-control"
                          >
                            <option
                              v-for="contrato in fastSecretariaContratosCurso"
                              :key="contrato.id_contrato"
                              :value="contrato.id_contrato"
                            >
                              {{ contrato.nome_contrato }}
                            </option>
                          </select>
                          <a class="ml-1" :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/contratos'"
                          ><small>Configurar contratos</small>
                          </a>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div>
                            <small
                              class="text-info"
                            >Obs: selecione um contrato e faça download do contrato</small>
                          </div>
                          <div class="mt-2">
                            <button
                              class="btn btn-sm btn-primary mr-1 ml-1"
                              @click="geraPdfContrato()"
                            >
                              <small><b-icon-download /> Download</small>
                            </button>
                            <button
                              class="btn btn-sm btn-primary mr-1 ml-1"
                              @click="showModal('modalEnviarContratoemail')"
                            >
                              <small><b-icon-envelope /> Enviar por email</small>
                            </button>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    <div
                      v-else
                      class="col-sm-12 col-md-12 col-lg-12 mb-4 mt-4"
                    >
                      <label
                        class="text-danger"
                      >Nenhum templates de contrato cadastrado</label>
                    </div>
                    <div v-if="fastMatriculaAlunoEditar.path_contrato" class="col-sm-12 col-md-12 col-lg-12 mb-2 mt-2">
                      <label>Contrato assinado atual</label>
                      <div v-if="fastMatriculaAlunoEditar.path_contrato">
                        <a
                          :href="ajustaLinkApiAntiga(fastMatriculaAlunoEditar.path_contrato)"
                          target="_blank"
                        >{{ fastMatriculaAlunoEditar.path_contrato }}</a>
                      </div>
                      <div v-else>
                        <span class="text-secondary">Nenhum contrato anexado</span>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-2 mt-4">
                      <label>Novo contrato</label>
                      <uploader
                        :file-status-text="statusText"
                        :options="optionsArquivo"
                        class="uploader-example"
                        @file-success="fileSuccessEditar"
                        @file-added="fileValidation"
                      >
                        <uploader-unsupport />
                        <uploader-drop>
                          <p class="aluno_font_color">
                            Arraste o contrato assinado aqui ou
                          </p>
                          <uploader-btn
                            :attrs="restrictArquivo"
                          >
                            Clique aqui para selecionar
                          </uploader-btn>
                        </uploader-drop>
                        <uploader-list />
                      </uploader>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
            <!-- /Opções de contrato -->

            <!-- Opções de inscrição -->
            <div class="col-12 mt-2">
              <div class="row">
                <div class="col-12">
                  <h5>Opções de inscrição 
                    <button class="btn btn-sm btn-secondary pt-0 pb-0" @click="fastExibirOpcoesInscricao = !fastExibirOpcoesInscricao">
                      <span v-if="!fastExibirOpcoesInscricao">
                        <b-icon icon="eye-fill" aria-hidden="true"></b-icon> exibir
                      </span> 
                      <span v-else>
                        <b-icon icon="eye-slash-fill" aria-hidden="true"></b-icon> ocultar
                      </span>                                           
                    </button>  
                  </h5>
                </div>
              </div>
              <div v-if="fastExibirOpcoesInscricao" class="row">
                <div class="col-12 border">
                  <div class="row">
                    <div
                      v-if="fastInscricoes.length"
                      class="col-sm-12 col-md-12 col-lg-12 mb-4 mt-4"
                    >
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <label>Templates de inscrição</label>
                          <select
                            v-model="fastInscricao.id_inscricao_template"
                            class="form-control"
                          >
                            <option
                              v-for="inscricao in fastInscricoes"
                              :key="inscricao.id_inscricao_template"
                              :value="inscricao.id_inscricao_template"
                            >
                              {{ inscricao.nome_inscricao_template }}
                            </option>
                          </select>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div>
                            <small
                              class="text-info"
                            >Obs: selecione um template de inscrição e faça download</small>
                          </div>
                          <div class="mt-2">
                            <button
                              class="btn btn-sm btn-primary mr-1 ml-1"
                              @click="gerarPdfInscricao()"
                            >
                              <small><b-icon-download /> Download</small>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class="col-sm-12 col-md-12 col-lg-12 mb-4 mt-4"
                    >
                      <label
                        class="text-danger"
                      >Nenhum template de inscrição cadastrado</label>
                    </div>
                    <div v-if="fastMatriculaAlunoEditar.path_inscricao" class="col-sm-12 col-md-12 col-lg-12 mb-2 mt-2">
                      <label>Documento de inscrição assinado</label>
                      <div v-if="fastMatriculaAlunoEditar.path_inscricao">
                        <a
                          :href="ajustaLinkApiAntiga(fastMatriculaAlunoEditar.path_inscricao)"
                          target="_blank"
                        >{{ fastMatriculaAlunoEditar.path_inscricao }}</a>
                      </div>
                      <div v-else>
                        <span class="text-secondary">Nenhum documento anexado</span>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-2 mt-4">
                      <label>Novo documento de inscrição assinado</label>
                      <uploader
                        :file-status-text="statusText"
                        :options="optionsArquivo"
                        class="uploader-example"
                        @file-success="fileSuccessEditarInscricao"
                        @file-added="fileValidation"
                      >
                        <uploader-unsupport />
                        <uploader-drop>
                          <p class="aluno_font_color">
                            Arraste o documento assinado aqui ou
                          </p>
                          <uploader-btn
                            :attrs="restrictArquivo"
                          >
                            Clique aqui para selecionar
                          </uploader-btn>
                        </uploader-drop>
                        <uploader-list />
                      </uploader>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
            <!-- /Opções de inscrição -->

            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-6 text-left">
                    <button
                      href="#"
                      type="button"
                      class="btn btn-primary"
                      @click="atualizarMatricula()"
                    >
                      Salvar alterações
                    </button>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6 text-right d-none">
                    <button
                      href="#"
                      type="button"
                      class="btn btn-sm btn-danger pt-0 pb-0"
                      @click="
                        showModal(
                          'modalExcluirMatriculaAluno',
                          'modalMatricularAlunoEditar'
                        )
                      "
                    >
                      <small>Excluir matrícula</small>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalMatriculaAlunoEncerrarCursoMatricula"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Encerrar matrícula</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalMatriculaAlunoEncerrarCursoMatricula')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <div class="col-sm-12 col-md-12 col-lg-6 p-0 mb-2">
            <label>Data do certificado:</label>
            <input
              v-model="fastDataParaEncerramento"
              type="date"
              class="form-control"
            >
            <a
              class="btn btn-danger mt-3"
              href="#"
              @click.prevent="exibeModalConfirmaEncerrarCursoMatricula()"
            >
              Encerrar matrícula
            </a>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalConfirmaEncerrarCursoMatricula"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Encerrar curso</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalConfirmaEncerrarCursoMatricula')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 pl-4 pr-4">
          <div class="col-sm-12 col-md-12 col-lg-12 p-0 mb-2">
            <h2 class="text-center">Atenção</h2>
            <h6>A ação de encerramento de um curso não pode ser desfeita.</h6>
            <a
              class="btn btn-danger mt-3"
              href="#"
              @click.prevent="encerraCursoMatricula()"
            >
              Prosseguir
            </a>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalHistoricosECertificados"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Históricos e Certificados</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalHistoricosECertificados')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 pl-4 pr-4">
          <div class="row">
              <div class="row">
                <div
                  class="col-sm-12 col-md-12 col-lg-12 text-right"
                >
                  <button
                    class="btn btn-sm btn-primary d-none"
                    @click.prevent="exibeModalHistoricoNovo()"
                  >
                    <small>+ Adicionar histórico</small>
                  </button>
                </div>
              </div>
              <div class="row">                                
                <div class="col-12 table-responsive p-4">
                  <table class="table table-sm table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th>
                          <small
                            class="font-weight-bold"
                          >Nome do curso</small>
                        </th>
                        <th>
                          <small class="font-weight-bold">Nível</small>                                  
                        </th>
                        <th class="text-center">
                          <small
                            class="font-weight-bold"
                          >Carga horária</small>
                        </th>
                        <th class="text-center">
                          <small class="font-weight-bold">Ações</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="fastHistoricoSecretariaCursos.loading">
                      <b-icon
                        icon="gear-fill"
                        animation="spin"
                      /> Carregando
                    </tbody>
                    <tbody v-else-if="fastHistoricoSecretariaCursos.total.length">
                      <tr
                        v-for="curso in fastFiltroHistoricoCertificados"
                        :key="curso.id_secretaria_curso"
                      >
                        <td class="align-middle">
                          <small>
                            <a
                              :href="
                                '/plataforma/' +
                                  $route.params.id_plataforma +
                                  '/secretaria/curso/' +
                                  curso.id_secretaria_curso
                              "
                              @click.prevent="$router.push('/plataforma/' +
                                $route.params.id_plataforma +
                                '/secretaria/curso/' +
                                curso.id_secretaria_curso)"
                            >
                                {{ curso.nome_curso }}
                            </a>  
                            
                          </small>  
                        </td>  
                        <td class="align-middle">
                          <small>{{ retornaCursoNivel(curso.nivel) }}</small> 
                        </td>  
                        <td class="align-middle text-center">
                          <span v-if="curso.carga_horaria">
                            <small>{{ curso.carga_horaria }}h</small>                                    
                          </span>                                  
                        </td>                 
                        <td class="align-middle">                                                                   
                          <button
                            class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                            @click="exibeModalHistoricoSecretariaCurso(curso)"
                          >
                            <small>Gerar histórico</small>
                          </button>
                          <button
                            class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                            @click="exibeModalAproveitamentoSecretariaCurso(curso)"
                          >
                            <small>+ Aproveitamento</small>
                          </button>
                          <button
                            v-if="curso.gera_certificado"
                            class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                            @click="exibeModalGerarCertificado(curso)"
                          >
                            <small>Certificado</small>
                          </button>
                          <button
                            v-else
                            class="btn btn-sm btn-secondary pt-0 pb-0 mr-2"
                            @click.prevent="exibeToasty('O curso não está configurado para gerar certificado', 'error')"
                          >
                            <small>Certificado</small>
                          </button>
                          <button
                            class="btn btn-sm btn-secondary pt-0 pb-0 mr-2"
                            @click.prevent="exibeToasty('O curso não está configurado para gerar ata de encerramento', 'error')"
                          >
                            <small>Ata de encerramento</small>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td
                          colspan="4"
                          class="text-center"
                        >
                          <span>Nenhum histórico encontrado</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalAssinaturaDeDocumentos"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div v-if="$store.state.fastCarregando" class="row">
        <div class="text-center my-5 mx-auto">
          <b-icon icon="gear-fill" animation="spin" /> Carregando
        </div>
      </div>
      <div v-else class="fd-app-table-novo-logs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Assinatura de Documentos</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalAssinaturaDeDocumentos') ">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div class="row p-4">
          <div class="col-sm-12 col-md-12 col-lg-12 pl-4 pr-4">
            <div class="row">
              <fast-cria-documentos 
              :id_pessoa="parseInt($route.params.id_pessoa_aluno)" 
              :id_plataforma="parseInt($route.params.id_plataforma)" 
              :id_matricula="parseInt(fastIdMatricula)"
              :id_secretaria_curso="parseInt(fastIdAssinaturaSecretariaCurso)"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalConsultaAssinaturaDeDocumentos"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div v-if="$store.state.fastCarregando" class="row">
        <div class="text-center my-5 mx-auto">
          <b-icon icon="gear-fill" animation="spin" /> Carregando
        </div>
      </div>
      <div v-else class="fd-app-table-novo-logs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Assinatura de Documentos</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalConsultaAssinaturaDeDocumentos') ">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div class="row p-4">
          <div class="col-sm-12 col-md-12 col-lg-12 pl-4 pr-4">
            <div class="row">
              <fast-consulta-documentos-assinatura 
              :id_assinatura_matricula="parseInt(fastIdAssinaturaMatricula)" 
              :id_plataforma="parseInt($route.params.id_plataforma)"
              :id_matricula="parseInt(fastIdMatricula)" 
              />
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalPlataformaTurmas"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Lista de turmas disponíveis</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalPlataformaTurmas')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="card mb-4">
                <div class="card-header py-3">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Lista de turmas
                      </h6>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 text-right" />
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Filtro por sigla da turma"
                            @keyup="filtroSiglaTurma"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
                          <select
                            v-model="filtroCursoEscolhido"
                            class="form-control form-control-sm"
                            @change="aplicaFiltroTurmas()"
                          >
                            <option value="">
                              -- Filtrar por curso --
                            </option>
                            <option
                              v-for="(curso, index) in filtroCursos"
                              :key="index"
                              :value="curso"
                            >
                              <span>{{ curso }}</span>
                            </option>
                          </select>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
                          <select
                            v-model="filtroNivelEscolhido"
                            class="form-control form-control-sm"
                            @change="aplicaFiltroTurmas()"
                          >
                            <option value="">
                              -- Filtrar por nível --
                            </option>
                            <option
                              v-for="(nivel, index) in filtroNiveis"
                              :key="index"
                              :value="nivel"
                            >
                              {{ retornaCursoNivel(nivel) }}
                            </option>
                          </select>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4 mb-1 d-none">
                          <select
                            v-model="filtroPlataformasEscolhido"
                            class="form-control form-control-sm"
                            @change="aplicaFiltroTurmas()"
                          >
                            <option value="">
                              -- Filtrar por plataforma --
                            </option>
                            <option
                              v-for="(plataforma, index) in filtroPlataformas"
                              :key="index"
                              :value="plataforma"
                            >
                              {{ plataforma }}
                            </option>
                          </select>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
                          <select
                            v-model="filtroTurnosEscolhido"
                            class="form-control form-control-sm"
                            @change="aplicaFiltroTurmas()"
                          >
                            <option value="">
                              -- Filtrar por turno --
                            </option>
                            <option
                              v-for="(turno, index) in filtroTurnos"
                              :key="index"
                              :value="turno"
                            >
                              <span v-if="turno == 1">Manhã</span>
                              <span v-else-if="turno == 2">Tarde</span>
                              <span v-else-if="turno == 3">Noite</span>
                              <span v-else>Indefinido</span>
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 table-responsive mt-2">
                          <table class="table table-sm table-striped">
                            <thead class="thead-dark">
                              <tr>
                                <th>
                                  <small class="font-weight-bold">Turma</small>
                                </th>
                                <th>
                                  <small class="font-weight-bold">Curso</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Nível</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Turno</small>
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Finalizada</small>
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Abertura Turma</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Ativo</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Ações</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastPlataformaTurmaFiltro.length">
                              <tr
                                v-for="turma in pageOfTurmas"
                                :key="turma.id_turma"
                              >
                                <td class="align-middle">
                                  <small>{{ turma.sigla_turma }}</small>
                                </td>
                                <td class="align-middle">
                                  <small>{{ turma.nome_curso }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <small>{{
                                    retornaCursoNivel(turma.nivel)
                                  }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <small v-if="turma.turno == 1">Manhã</small>
                                  <small
                                    v-else-if="turma.turno == 2"
                                  >Tarde</small>
                                  <small
                                    v-else-if="turma.turno == 3"
                                  >Noite</small>
                                  <small v-else>Indefinido</small>
                                </td>
                                <td class="text-center align-middle">
                                  <div v-if="turma.data_final">
                                    <small
                                      v-if="
                                        new Date(
                                          turma.data_atual.replaceAll('-', '/')
                                        ) >
                                          new Date(
                                            turma.data_final
                                              .split('T')[0]
                                              .replaceAll('-', '/')
                                          )
                                      "
                                      class="text-info"
                                    >
                                      Sim
                                    </small>
                                    <small
                                      v-else
                                      class="text-danger"
                                    >Não</small>
                                  </div>
                                  <div v-else>
                                    <small class="text-danger">Não</small>
                                  </div>
                                </td>
                                <td class="text-center align-middle">
                                  <small>{{
                                    new Date(turma.data_registro)
                                      .toLocaleString()
                                      .split(" ")[0]
                                  }}</small>
                                </td>
                                <td class="text-center align-middle">
                                  <small
                                    v-if="turma.ativo"
                                    class="text-success"
                                  >
                                    Sim
                                  </small>
                                  <small
                                    v-else
                                    class="text-danger"
                                  >
                                    Não
                                  </small>
                                </td>
                                <td class="text-center align-middle">
                                  <button
                                    v-if="turma.ativo"
                                    class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                                    @click="getFastProgramas(turma)"
                                  >
                                    <small>Acessar turma </small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="8"
                                  class="text-center"
                                >
                                  Nenhuma turma cadastrada
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-12 text-center mt-4">
                          <Pagination
                            :page-size="10"
                            :items="fastPlataformaTurmaFiltro"
                            @changePage="pageOfTurmas = $event"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalPlataformaTurmaProgramas"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Lista de módulos</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalPlataformaTurmaProgramas')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="card mb-4">
                <div class="card-header py-3">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Lista de módulos da turma {{ fastTurma.sigla_turma }}
                      </h6>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 text-right" />
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 table-responsive mt-2">
                      <table class="table table-sm">
                        <thead class="thead-dark">
                          <tr>
                            <th>
                              <small class="font-weight-bold">Sequência</small>
                            </th>
                            <th>
                              <small class="font-weight-bold">Módulo</small>
                            </th>
                            <th class="text-center">
                              <small class="font-weight-bold">Ano</small>
                            </th>
                            <th class="text-center">
                              <small
                                class="font-weight-bold"
                              >Início do módulo</small>
                            </th>
                            <th class="text-center">
                              <small
                                class="font-weight-bold"
                              >Fim do módulo</small>
                            </th>

                            <th class="text-center">
                              <small class="font-weight-bold">Ações</small>
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="fastTurma.programas.length">
                          <tr
                            v-for="programa in fastTurma.programas"
                            :key="programa.id_programa_turma"
                          >
                            <td class="align-middle">
                              {{ programa.sequencia }}
                            </td>
                            <td class="align-middle">
                              <small>{{ programa.sigla_programa }}</small>
                            </td>
                            <td class="text-center align-middle">
                              <small>{{ programa.ano }}</small>
                            </td>
                            <td class="text-center align-middle">
                              <small v-if="programa.data_inicio">{{
                                programa.data_inicio.split("-")[2] +
                                  "/" +
                                  programa.data_inicio.split("-")[1] +
                                  "/" +
                                  programa.data_inicio.split("-")[0]
                              }}</small>
                            </td>
                            <td class="text-center align-middle">
                              <small v-if="programa.data_final">{{
                                programa.data_final.split("-")[2] +
                                  "/" +
                                  programa.data_final.split("-")[1] +
                                  "/" +
                                  programa.data_final.split("-")[0]
                              }}</small>
                            </td>
                            <td class="text-center align-middle">
                              <button
                                class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                                @click="selecionaProgramaMatricula(programa)"
                              >
                                <small>Selecionar módulo</small>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td
                              colspan="6"
                              class="text-center"
                            >
                              Nenhum módulo cadastrado
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button
                class="btn btn-primary"
                @click="
                  showModal(
                    'modalPlataformaTurmas',
                    'modalPlataformaTurmaProgramas'
                  )
                "
              >
                Voltar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirMatriculaAluno"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir matrícula?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirMatriculaAluno')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger mr-2"
                  @click.prevent="excluirMatriculaAluno()"
                >
                  Confirmar
                </button>
                <button
                  href="#"
                  type="button"
                  class="btn btn-secondary mr-2"
                  @click.prevent="
                    showModal(
                      'modalMatricularAlunoEditar',
                      'modalExcluirMatriculaAluno'
                    )
                  "
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalSelecionaTipoRequerimento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Tipo de requerimento</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalSelecionaTipoRequerimento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <select
                class="custom-select custom-select-md"
                @change="validaTipoRequerimento"
              >
                <option value="">
                  -- Selecione um tipo de requerimento --
                </option>
                <option
                  v-for="req in fastTipoRequerimentos"
                  :key="req.id_tipo_requerimento"
                  :value="req.id_tipo_requerimento"
                >
                  {{ req.nome_requerimento }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCriarRequerimento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Novo requerimento</h4>
          <b>{{ fastRequerimentoNovo.nome_requerimento }}</b>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarRequerimento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">                    
            <div v-if="fastRequerimentoNovo.id_item_cobranca" class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Valor</label>
              <money                
                :value="fastRequerimentoNovo.valor_requerimento"                
                class="form-control"
                readonly
              />              
            </div>
            <div v-if="fastRequerimentoNovo.id_item_cobranca" class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Taxa</label>
              <money
                v-model="fastRequerimentoNovo.taxa"
                class="form-control"
              />
            </div>
            <div v-if="fastRequerimentoNovo.id_item_cobranca" class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Desconto</label>
              <money
                v-model="fastRequerimentoNovo.desconto"
                class="form-control"
              />
            </div>
            <div v-if="fastRequerimentoNovo.id_item_cobranca" class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Valor total</label>
              <money
                v-model="fastRequerimentoNovo.total_requerimento"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Data início análise</label>
              <input
                v-model="fastRequerimentoNovo.data_inicio_analise"
                type="date"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Data fim análise</label>
              <input
                v-model="fastRequerimentoNovo.data_fim_analise"
                type="date"
                class="form-control"
              >
            </div>        
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Detalhes</label>
              <textarea
                v-model="fastRequerimentoNovo.detalhe_requerimento"
                class="form-control"
              />
            </div>
            <div
              v-if="fastRequerimentoNovo.path_anexo"
              class="col-sm-12 col-md-12 col-lg-12 mb-2"
            >
              <label>Anexo</label>
              <br>
              <a
                :href="fastRequerimentoNovo.path_anexo"
                target="_blank"
                download
              >{{ fastRequerimentoNovo.path_anexo }}</a>
            </div>
            <div
              v-if="fastRequerimentoNovo.aceita_anexo"
              class="col-sm-12 col-md-12 col-lg-12 mb-2"
            >
              <label>Upload do anexo</label>
              <uploader
                :file-status-text="statusText"
                :options="optionsArquivo"
                class="uploader-example"
                @file-success="fileSuccessRequerimento"
                @file-added="fileValidation"
              >
                <uploader-unsupport />
                <uploader-drop>
                  <p class="aluno_font_color">
                    Arraste um documento aqui ou
                  </p>
                  <uploader-btn
                    :attrs="restrictArquivo"
                  >
                    Clique aqui para selecionar
                  </uploader-btn>
                </uploader-drop>
                <uploader-list />
              </uploader>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  v-if="!fastRequerimentoNovo.id_item_cobranca"
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="criarRequerimento()"
                >
                  Cadastrar
                </button>
                <button
                  v-else
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="criarRequerimentoPago()"
                >
                  Gerar fatura
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarRequerimento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Editar requerimento</h4>
          <b>{{ fastRequerimentoNovo.nome_requerimento }}</b>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarRequerimento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">                    
            <div v-if="fastRequerimentoEditar.id_item_cobranca" class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Valor</label>
              <money
                v-model="fastRequerimentoEditar.valor_requerimento"
                class="form-control"
                readonly
              />
            </div>
            <div v-if="fastRequerimentoEditar.id_item_cobranca" class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Taxa</label>
              <money
                v-model="fastRequerimentoEditar.taxa"
                class="form-control"
                readonly
              />
            </div>
            <div v-if="fastRequerimentoEditar.id_item_cobranca" class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Desconto</label>
              <money
                v-model="fastRequerimentoEditar.desconto"
                class="form-control"
                readonly
              />
            </div>
            <div v-if="fastRequerimentoEditar.id_item_cobranca" class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Valor total</label>
              <money
                v-model="fastRequerimentoEditar.total_requerimento"
                class="form-control"
                readonly
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Data início análise</label>
              <input
                v-model="fastRequerimentoEditar.data_inicio_analise"
                type="date"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Data fim análise</label>
              <input
                v-model="fastRequerimentoEditar.data_fim_analise"
                type="date"
                class="form-control"
              >
            </div>        
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Detalhes</label>
              <textarea
                v-model="fastRequerimentoEditar.detalhe_requerimento"
                class="form-control"
              />
            </div>
            <div
              v-if="fastRequerimentoEditar.path_anexo"
              class="col-sm-12 col-md-12 col-lg-12 mb-2"
            >
              <label>Anexo</label>
              <br>
              <a
                :href="ajustaLinkApiAntiga(fastRequerimentoEditar.path_anexo)"
                target="_blank"
                download
              >{{ fastRequerimentoEditar.path_anexo }}</a>
            </div>
            <div
              v-if="fastRequerimentoEditar.aceita_anexo"
              class="col-sm-12 col-md-12 col-lg-12 mb-2"
            >
              <label>Upload do anexo</label>
              <uploader
                :file-status-text="statusText"
                :options="optionsArquivo"
                class="uploader-example"
                @file-success="fileSuccessRequerimentoEditar"
                @file-added="fileValidation"
              >
                <uploader-unsupport />
                <uploader-drop>
                  <p class="aluno_font_color">
                    Arraste um documento aqui ou
                  </p>
                  <uploader-btn
                    :attrs="restrictArquivo"
                  >
                    Clique aqui para selecionar
                  </uploader-btn>
                </uploader-drop>
                <uploader-list />
              </uploader>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="atualizarRequerimento()"
                >
                  Confirmar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalSelecionaTipoOcorrencia"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Tipo de ocorrência</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalSelecionaTipoOcorrencia')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <select
                class="custom-select custom-select-md"
                @change="validaTipoOcorrencia"
              >
                <option value="">
                  -- Selecione um tipo de ocorrência --
                </option>
                <option
                  v-for="o in fastTipoOcorrencias"
                  :key="o.id_tipo_ocorrencia"
                  :value="o.id_tipo_ocorrencia"
                >
                  {{ o.nome_ocorrencia }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCriarOcorrencia"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Nova ocorrência</h4>
          <b>{{ fastOcorrenciaNovo.nome_ocorrencia }}</b>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarOcorrencia')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">    
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Detalhes</label>
              <textarea
                v-model="fastOcorrenciaNovo.detalhe_ocorrencia"
                class="form-control"
              />
            </div>
            <div
              v-if="fastOcorrenciaNovo.path_anexo"
              class="col-sm-12 col-md-12 col-lg-12 mb-2"
            >
              <label>Anexo</label>
              <br>
              <a
                :href="fastOcorrenciaNovo.path_anexo"
                target="_blank"
                download
              >{{ fastOcorrenciaNovo.path_anexo }}</a>
            </div>
            <div
              v-if="fastOcorrenciaNovo.aceita_anexo"
              class="col-sm-12 col-md-12 col-lg-12 mb-2"
            >
              <label>Upload do anexo</label>
              <uploader
                :file-status-text="statusText"
                :options="optionsArquivo"
                class="uploader-example"
                @file-success="fileSuccessOcorrencia"
                @file-added="fileValidation"
              >
                <uploader-unsupport />
                <uploader-drop>
                  <p class="aluno_font_color">
                    Arraste um documento aqui ou
                  </p>
                  <uploader-btn
                    :attrs="restrictArquivo"
                  >
                    Clique aqui para selecionar
                  </uploader-btn>
                </uploader-drop>
                <uploader-list />
              </uploader>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="criarOcorrencia()"
                >
                  Cadastrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarOcorrencia"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Editar ocorrência</h4>
          <b>{{ fastOcorrenciaEditar.nome_ocorrencia }}</b>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarOcorrencia')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">  
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Detalhes</label>
              <textarea
                v-model="fastOcorrenciaEditar.detalhe_ocorrencia"
                class="form-control"
              />
            </div>
            <div
              v-if="fastOcorrenciaEditar.path_anexo"
              class="col-sm-12 col-md-12 col-lg-12 mb-2"
            >
              <label>Anexo</label>
              <br>
              <a
                :href="ajustaLinkApiAntiga(fastOcorrenciaEditar.path_anexo)"
                target="_blank"
                download
              >{{ fastOcorrenciaEditar.path_anexo }}</a>
            </div>
            <div
              v-if="fastOcorrenciaEditar.aceita_anexo"
              class="col-sm-12 col-md-12 col-lg-12 mb-2"
            >
              <label>Upload do anexo</label>
              <uploader
                :file-status-text="statusText"
                :options="optionsArquivo"
                class="uploader-example"
                @file-success="fileSuccessOcorrenciaEditar"
                @file-added="fileValidation"
              >
                <uploader-unsupport />
                <uploader-drop>
                  <p class="aluno_font_color">
                    Arraste um documento aqui ou
                  </p>
                  <uploader-btn
                    :attrs="restrictArquivo"
                  >
                    Clique aqui para selecionar
                  </uploader-btn>
                </uploader-drop>
                <uploader-list />
              </uploader>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="atualizarOcorrencia()"
                >
                  Confirmar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEnviarContratoemail"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Enviar contrato por email</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEnviarContratoemail')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <p class="text-info">
            Certifique-se de revisar o contrato antes de enviá-lo.
          </p>
          <p>Ao realizar o upload do contrato um email será enviado para o aluno com link para o contrato. A personalização do email pode ser feita através da personalização da plataforma.</p>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <h5>Upload do contrato</h5>
            </div>
            <div class="col-12">
              <uploader
                :file-status-text="statusText"
                :options="optionsArquivo"
                class="uploader-example"
                @file-success="fileSuccessemail"
              >
                <uploader-unsupport />
                <uploader-drop>
                  <p class="aluno_font_color">
                    Arraste o contrato aqui ou
                  </p>
                  <uploader-btn :attrs="restrictArquivo">
                    Clique aqui para selecionar
                  </uploader-btn>
                </uploader-drop>
                <uploader-list />
              </uploader>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEnviarInscricaoemail"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Enviar inscrição por email</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEnviarInscricaoemail')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <p class="text-info">
            Certifique-se de revisar o documento antes de enviá-lo.
          </p>
          <p>Ao realizar o upload do documento um email será enviado para o aluno com link. A personalização do email pode ser feita através da personalização da plataforma.</p>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <h5>Upload do documento de inscrição</h5>
            </div>
            <div class="col-12">
              <uploader
                :file-status-text="statusText"
                :options="optionsArquivo"
                class="uploader-example"
                @file-success="fileSuccessemail"
              >
                <uploader-unsupport />
                <uploader-drop>
                  <p class="aluno_font_color">
                    Arraste o contrato aqui ou
                  </p>
                  <uploader-btn :attrs="restrictArquivo">
                    Clique aqui para selecionar
                  </uploader-btn>
                </uploader-drop>
                <uploader-list />
              </uploader>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalWhatsApp"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Compartilhar link via WhatsApp</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalMatricularAlunoEditar', 'modalWhatsApp')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label class="font-weight-bold text-secondary">Telefone do aluno:</label>&nbsp; <span>{{fastLinkWhats.telefone_aluno}}</span>
              <a target="_blank" :href="retornaLinkWhats(fastLinkWhats.telefone_aluno, fastLinkWhats.path_boleto, fastLinkWhats.parcela, fastLinkWhats.valor_parcela_total, fastLinkWhats.data_vencimento, fastLinkWhats.nome_plataforma, fastLinkWhats.tipo)" class="btn btn-sm btn-success pb-0 pt-0 ml-2">
                <small><b-icon icon="share" aria-hidden="true"></b-icon> WhatsApp</small>                                
              </a>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12">
              <button class="btn btn-secondary mr-2" @click="showModal('modalMatricularAlunoEditar', 'modalWhatsApp')">
                Fechar
              </button>
            </div>
          </div>

        </div>
      </div>
    </modal>

    <modal
      name="modalHistoricoNovo"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Cadastrar Histórico</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalHistoricoNovo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">  
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Ano</label>
              <input type="number" class="form-control" v-model="fastHistoricoNovo.ano" />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Período</label>
              <select class="form-control" v-model="fastHistoricoNovo.periodo">
                <option value="A" selected>Anual</option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-5 mb-2">
              <label>Curso</label>
              <select v-if="fastSecretariaCursos.loading" class="form-control">       
                <option value="">Carregando...</option>     
              </select>
              <select v-else-if="!fastSecretariaCursos.total.length" class="form-control">       
                <option value="">-- Nenhum curso encontrado ---</option>     
              </select>              
              <select v-else class="form-control" v-model="fastHistoricoNovo.id_secretaria_curso" readonly>        
                <option v-for="(c, index) in fastSecretariaCursos.total" :key="index" :value="c.id_secretaria_curso">{{c.nome_curso}}</option>  
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Módulo</label>
              <select v-if="!fastHistoricoNovo.id_secretaria_curso" class="form-control">       
                <option value="">-- Aguardando seleção do módulo --</option>     
              </select>
              <select v-else-if="fastSecretariaCursoModulos.loading" class="form-control">       
                <option value="">Carregando...</option>     
              </select>
              <select v-else-if="!fastSecretariaCursoModulos.total.length" class="form-control" >       
                <option value="">-- Nenhum módulo encontrado ---</option>     
              </select>
              <select v-else class="form-control" v-model="fastHistoricoNovo.id_secretaria_curso_programa" @change="getFastCursosPrograma()">        
                <option v-for="(c, index) in fastSecretariaCursoModulos.total" :key="index" :value="c.id_secretaria_curso_programa">{{c.sigla_programa}}</option>  
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Turma</label>              
              <select v-if="!fastHistoricoNovo.id_secretaria_curso" class="form-control">       
                <option value="">-- Aguardando seleção do curso --</option>     
              </select>
              <select v-else-if="fastSecretariaTurmas.loading" class="form-control">       
                <option value="">Carregando...</option>     
              </select>
              <select v-else-if="!fastSecretariaTurmas.total.length" class="form-control" >       
                <option value="">-- Nenhuma turma encontrada no curso ---</option>     
              </select>
              <select v-else class="form-control" v-model="fastHistoricoNovo.id_turma">        
                <option v-for="(c, index) in fastSecretariaTurmas.total" :key="index" :value="c.id_turma">{{c.sigla_turma}}</option>  
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Carga horária</label>
              <input type="number" class="form-control" v-model="fastHistoricoNovo.carga_horaria"/>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Dias letivos</label>
              <input type="number" class="form-control" v-model="fastHistoricoNovo.dias_letivos" />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Faltas</label>
              <input type="number" class="form-control" v-model="fastHistoricoNovo.faltas" />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2 d-none">
              <label>Resultado</label>
              <select v-if="fastResultados.loading" class="form-control">       
                <option value="">Carregando...</option>     
              </select>
              <select v-else-if="!fastResultados.total.length" class="form-control" >       
                <option value="">-- Nenhum resultado encontrado ---</option>     
              </select>
              <select v-else class="form-control" v-model="fastHistoricoNovo.id_tipo_resultado_aluno">   
                <option value="">-- Selecione ---</option>      
                <option v-for="(c, index) in fastResultados.total" :key="index" :value="c.id_tipo_resultado_aluno">{{c.nome_tipo_resultado}}</option>  
              </select>                      
            </div>
            <div class="col-sm-12 col-md-12 col-lg-5 mb-2">
              <label>Instituição (parceiro)</label>
              <select v-if="fastPessoasParceiros.loading" class="form-control">       
                <option value="">Carregando...</option>     
              </select>
              <select v-else-if="!fastPessoasParceiros.total.length" class="form-control" >       
                <option value="">-- Nenhum parceiro encontrado ---</option>     
              </select>
              <select v-else class="form-control" v-model="fastHistoricoNovo.id_pessoa">   
                <option value="">-- Selecione ---</option>      
                <option v-for="(c, index) in fastPessoasParceiros.total" :key="index" :value="c.id_pessoa">{{c.nome_razao}} {{c.sobrenome_fantasia}}</option>  
              </select>  
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Forma de avaliação</label>
              <select class="form-control" v-model="fastHistoricoNovo.tipo_avaliacao">      
                <option value="N" selected>Nota</option>      
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Nota máxima</label>
              <input type="number" class="form-control" v-model="fastHistoricoNovo.nota_maxima" />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Nro decimais</label>
              <input type="number" class="form-control" v-model="fastHistoricoNovo.nro_decimais" />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Turno</label>
              <select class="form-control" v-model="fastHistoricoNovo.turno">         
                <option value="M" selected>Manhã</option>        
                <option value="T">Tarde</option>   
                <option value="N">Noite</option>        
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Forma de carga horária</label>
              <div>
                <input type="radio" name="formaCargaHoraria" value="P" v-model="fastHistoricoNovo.forma_carga_horaria" checked/> Período
                <input type="radio" name="formaCargaHoraria" value="H" v-model="fastHistoricoNovo.forma_carga_horaria"/> Horas/minuto
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Mínimo para aprovação</label>
              <input type="number" class="form-control" v-model="fastHistoricoNovo.nota_aprovacao" />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Frequência</label>
              <input type="number" class="form-control" v-model="fastHistoricoNovo.frequencia" />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <div class="row mb-2">
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <label>Unidades curriculares</label>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 pr-0">
                  <select v-if="!fastHistoricoNovo.id_secretaria_curso_programa" class="form-control">       
                    <option value="">-- Aguardando seleção do módulo --</option>     
                  </select>
                  <select v-else-if="fastCursosPrograma.loading" class="form-control">       
                    <option value="">Carregando...</option>     
                  </select>
                  <select v-else-if="!fastCursosPrograma.total.length" class="form-control" >       
                    <option value="">-- Nenhuma unidade encontrada ---</option>     
                  </select>
                  <select v-else class="form-control rounded-right" v-model="fastHistoricoUnidade.id_curso">   
                    <option value="">-- Selecione ---</option>      
                    <option v-for="(c, index) in fastCursosPrograma.total" :key="index" :value="c.id_curso">{{c.nome_curso ? c.nome_curso : c.nome_curso_mae}}</option>  
                  </select>    
                </div>
                <div class="col-sm-12 col-md-12 col-lg-2 pl-0">
                  <button class="btn btn-primary btn-block rounded-left" @click="adicionaUnidadeHistorico()">+ Adicionar</button>
                </div>
              </div>
              
              <div class="row">                
                <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                  <div class="card">
                    <div class="card-body table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Ano</th>
                            <th scope="col">Unidade</th>   
                            <th scope="col">Carga horária</th>
                            <th scope="col">Nota</th>   
                            <th scope="col">Situação</th>
                            <th scope="col">Ação</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(unidade, index) in fastHistoricoNovo.unidades" :key="index">
                            <td>
                              <input type="text" class="form-control" v-model="unidade.ano">
                            </td>
                            <td>{{unidade.nome_curso ? unidade.nome_curso : unidade.nome_curso_mae}}</td>
                            <td>
                              <input type="text" class="form-control" v-model="unidade.carga_horaria">
                            </td>
                            <td>
                              <input type="text" class="form-control" v-model="unidade.nota">
                            </td>
                            <td>
                              <select v-if="fastResultados.loading" class="form-control">       
                                <option value="">Carregando...</option>     
                              </select>
                              <select v-else-if="!fastResultados.total.length" class="form-control" >       
                                <option value="">-- Nenhum resultado encontrado ---</option>     
                              </select>
                              <select v-else class="form-control" v-model="unidade.id_tipo_resultado_aluno">   
                                <option value="">-- Selecione ---</option>      
                                <option v-for="(c, index) in fastResultados.total" :key="index" :value="c.id_tipo_resultado_aluno">{{c.nome_tipo_resultado}}</option>  
                              </select>  

                            </td>
                            <td>
                              <button
                                class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                @click="excluirHistoricoNovoUnidade(unidade)"
                              >
                                <small>Excluir</small>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="criarHistoricoNovo()"
                >
                  Cadastrar histórico
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <b-modal id="modalAproveitamentos" scrollable title="Aproveitamentos" size="xl">
      <div class="row" v-if="fastAproveitamentoAluno.total.length">
        <div class="col-12">
        <div class="row">  
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Ano</label>
              <input type="number" class="form-control" v-model="fastHistoricoEditar.ano" />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Período</label>
              <select class="form-control" v-model="fastHistoricoEditar.periodo">
                <option value="A" selected>Anual</option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-5 mb-2">
              <label>Curso</label>
              <select v-if="fastSecretariaCursos.loading" class="form-control">       
                <option value="">Carregando...</option>     
              </select>
              <select v-else-if="!fastSecretariaCursos.total.length" class="form-control" >       
                <option value="">-- Nenhum curso encontrado ---</option>     
              </select>
              <select v-else class="form-control" v-model="fastHistoricoEditar.id_secretaria_curso" readonly>        
                <option v-for="(c, index) in fastSecretariaCursos.total" :key="index" :value="c.id_secretaria_curso">{{c.nome_curso}}</option>  
              </select>              
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Módulo</label>
              <select v-if="!fastHistoricoEditar.id_secretaria_curso" class="form-control">       
                <option value="">-- Aguardando seleção do módulo --</option>     
              </select>
              <select v-else-if="fastSecretariaCursoModulos.loading" class="form-control">       
                <option value="">Carregando...</option>     
              </select>
              <select v-else-if="!fastSecretariaCursoModulos.total.length" class="form-control" >       
                <option value="">-- Nenhum módulo encontrado ---</option>     
              </select>
              <select v-else class="form-control" v-model="fastHistoricoEditar.id_secretaria_curso_programa" @change="getFastCursosProgramaEditar()">        
                <option v-for="(c, index) in fastSecretariaCursoModulos.total" :key="index" :value="c.id_secretaria_curso_programa">{{c.sigla_programa}}</option>  
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Turma</label>              
              <select v-if="!fastHistoricoEditar.id_secretaria_curso" class="form-control">       
                <option value="">-- Aguardando seleção do curso --</option>     
              </select>
              <select v-else-if="fastSecretariaTurmas.loading" class="form-control">       
                <option value="">Carregando...</option>     
              </select>
              <select v-else-if="!fastSecretariaTurmas.total.length" class="form-control" >       
                <option value="">-- Nenhuma turma encontrada no curso ---</option>     
              </select>
              <select v-else class="form-control" v-model="fastHistoricoEditar.id_turma">        
                <option v-for="(c, index) in fastSecretariaTurmas.total" :key="index" :value="c.id_turma">{{c.sigla_turma}}</option>  
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Carga horária</label>
              <input type="number" class="form-control" v-model="fastHistoricoEditar.carga_horaria"/>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Dias letivos</label>
              <input type="number" class="form-control" v-model="fastHistoricoEditar.dias_letivos" />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Faltas</label>
              <input type="number" class="form-control" v-model="fastHistoricoEditar.faltas" />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2 d-none">
              <label>Resultado</label>
              <select v-if="fastResultados.loading" class="form-control">       
                <option value="">Carregando...</option>     
              </select>
              <select v-else-if="!fastResultados.total.length" class="form-control" >       
                <option value="">-- Nenhum resultado encontrado ---</option>     
              </select>
              <select v-else class="form-control" v-model="fastHistoricoEditar.id_tipo_resultado_aluno">   
                <option value="">-- Selecione ---</option>      
                <option v-for="(c, index) in fastResultados.total" :key="index" :value="c.id_tipo_resultado_aluno">{{c.nome_tipo_resultado}}</option>  
              </select>                      
            </div>
            <div class="col-sm-12 col-md-12 col-lg-5 mb-2">
              <label>Instituição (parceiro)</label>
              <select v-if="fastPessoasParceiros.loading" class="form-control">       
                <option value="">Carregando...</option>     
              </select>
              <select v-else-if="!fastPessoasParceiros.total.length" class="form-control" >       
                <option value="">-- Nenhum parceiro encontrado ---</option>     
              </select>
              <select v-else class="form-control" v-model="fastHistoricoEditar.id_pessoa">   
                <option value="">-- Selecione ---</option>      
                <option v-for="(c, index) in fastPessoasParceiros.total" :key="index" :value="c.id_pessoa">{{c.nome_razao}} {{c.sobrenome_fantasia}}</option>  
              </select>  
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Forma de avaliação</label>
              <select class="form-control" v-model="fastHistoricoEditar.tipo_avaliacao">      
                <option value="N" selected>Nota</option>      
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Nota máxima</label>
              <input type="number" class="form-control" v-model="fastHistoricoEditar.nota_maxima" />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Nro decimais</label>
              <input type="number" class="form-control" v-model="fastHistoricoEditar.nro_decimais" />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Turno</label>
              <select class="form-control" v-model="fastHistoricoEditar.turno">         
                <option value="M" selected>Manhã</option>        
                <option value="T">Tarde</option>   
                <option value="N">Noite</option>        
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Forma de carga horária</label>
              <div>
                <input type="radio" name="formaCargaHoraria" value="P" v-model="fastHistoricoEditar.forma_carga_horaria" checked/> Período
                <input type="radio" name="formaCargaHoraria" value="H" v-model="fastHistoricoEditar.forma_carga_horaria"/> Horas/minuto
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Mínimo para aprovação</label>
              <input type="number" class="form-control" v-model="fastHistoricoEditar.nota_aprovacao" />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Frequência</label>
              <input type="number" class="form-control" v-model="fastHistoricoEditar.frequencia" />
            </div>
          </div>
      </div>
      <div class="col-12 mt-4">
        <div class="row mb-2">
            <div class="col-sm-12 col-md-12 col-lg-4">
              <label>Unidades curriculares</label>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 pr-0">
              <select v-if="!fastHistoricoEditar.id_secretaria_curso_programa" class="form-control">       
                <option value="">-- Aguardando seleção do módulo --</option>     
              </select>
              <select v-else-if="fastCursosPrograma.loading" class="form-control">       
                <option value="">Carregando...</option>     
              </select>
              <select v-else-if="!fastCursosPrograma.total.length" class="form-control" >       
                <option value="">-- Nenhuma unidade encontrada ---</option>     
              </select>
              <select v-else class="form-control rounded-right" v-model="fastHistoricoUnidade.id_curso">   
                <option value="">-- Selecione ---</option>      
                <option v-for="(c, index) in fastCursosPrograma.total" :key="index" :value="c.id_curso">{{c.nome_curso ? c.nome_curso : c.nome_curso_mae}}</option>  
              </select>    
            </div>
            <div class="col-sm-12 col-md-12 col-lg-2 pl-0">
              <button class="btn btn-primary btn-block rounded-left" @click="adicionaUnidadeHistoricoEditar()">+ Adicionar</button>
            </div>
          </div>
      </div>
      <div class="col-12 mt-4" v-for="(modulo, index2) in fastAproveitamentoAluno.total" :key="index2">
        <div class="row">
          <div class="col-12">
            <h5>{{modulo.sigla_programa}}</h5>
          </div>
        </div>        
        <div class="card">          
          <div class="card-body table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Ano</th>
                  <th scope="col">Unidade</th>   
                  <th scope="col">Carga horária</th>
                  <th scope="col">Nota</th>   
                  <th scope="col">Situação</th>
                  <th scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(unidade, index) in modulo.unidades" :key="index">
                  <td>
                    <input type="text" class="form-control" v-model="unidade.ano_unidade" @blur="atualizaUnidadeHistorico(unidade)">
                  </td>
                  <td>{{unidade.nome_curso ? unidade.nome_curso : unidade.nome_curso_mae}}</td>
                  <td>
                    <input type="text" class="form-control" v-model="unidade.carga_horaria_unidade" @blur="atualizaUnidadeHistorico(unidade)">
                  </td>
                  <td>
                    <input type="text" class="form-control" v-model="unidade.nota_unidade"  @blur="atualizaUnidadeHistorico(unidade)">
                  </td>
                  <td>
                    <select v-if="fastResultados.loading" class="form-control">       
                      <option value="">Carregando...</option>     
                    </select>
                    <select v-else-if="!fastResultados.total.length" class="form-control" >       
                      <option value="">-- Nenhum resultado encontrado ---</option>     
                    </select>
                    <select v-else class="form-control" v-model="unidade.id_tipo_resultado_aluno" @change="atualizaUnidadeHistorico(unidade)">   
                      <option value="">-- Selecione ---</option>      
                      <option v-for="(c, index) in fastResultados.total" :key="index" :value="c.id_tipo_resultado_aluno">{{c.nome_tipo_resultado}}</option>  
                    </select>  
                  </td>
                  <td>
                    <button
                      class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                      @click="excluirHistoricoEditarUnidade(unidade)"
                    >
                      <small>Excluir</small>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      </div>
      <template #modal-footer="{ hide }">
        <b-button size="sm" variant="primary" @click="salvarAlteracoesHistorico()">
            Salvar alterações <b-icon-x-circle-fill />
        </b-button>
        <b-button size="sm" variant="secondary" @click="hide('forget')">
            Cancelar <b-icon-x-circle-fill />
        </b-button>
      </template>

    </b-modal>

    <b-modal id="modalGerarHistorico" scrollable title="Histórico escolar" size="xl">
      <div class="row" >       
        <div class="col-12 text-center">
          <h4>Selecione o template:</h4>
          <select class="form-control" v-model="idTemplateHistoricoEscolhido">
            <option v-for="t in templatesHistorico" :value="t.id_secretaria_historico_escolar" :key="t.id_secretaria_historico_escolar">
              {{ t.nome_template }}
            </option>
          </select>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button v-if="!idTemplateHistoricoEscolhido" 
            size="sm" variant="primary" @click="gerarHistoricoEscolar()" disabled>
            Gerar Histórico Escolar <b-icon-x-circle-fill />
        </b-button>
        <b-button v-else size="sm" variant="primary" @click="gerarHistoricoEscolar()">
            Gerar Histórico Escolar <b-icon-x-circle-fill />
        </b-button>
        <b-button size="sm" variant="secondary" @click="hide('forget')">
            Cancelar <b-icon-x-circle-fill />
        </b-button>
      </template>

    </b-modal>

    <b-modal id="modalGerarCertificado" scrollable title="Certificado de conclusão" size="xl">
      <div class="row" >       
        <div class="col-12 text-center">
          <h4>Selecione o template:</h4>
          <select class="form-control" v-model="idTemplateCertificadoEscolhido">
            <option v-for="t in templatesCertificado" :value="t.id_secretaria_certificado" :key="t.id_secretaria_certificado">
              {{ t.nome_template }}
            </option>
          </select>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-button v-if="!idTemplateCertificadoEscolhido" size="sm" variant="primary" @click="gerarCertificadoConclusao()" disabled>
            Gerar certificado <b-icon-x-circle-fill />
        </b-button>
        <b-button v-else size="sm" variant="primary" @click="gerarCertificadoConclusao()">
            Gerar certificado <b-icon-x-circle-fill />
        </b-button>
        <b-button size="sm" variant="secondary" @click="hide('forget')">
            Cancelar <b-icon-x-circle-fill />
        </b-button>
      </template>

    </b-modal>

    <!-- #endregion -->

    <div class="divPdfContrato">
    <VueHtml2pdf
      ref="html2Pdf"
      class="mx-auto"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-content-width="955px"
      :manual-pagination="false"
      :paginate-elements-by-height="10000"
    >
      <section slot="pdf-content">
        <div
          class="fast-pdf-html-template"
          v-html="fastContratoEscolhido.html_template"
        />
      </section>
    </VueHtml2pdf>
    </div>

    <div id="divPdfRequerimento">
      <VueHtml2pdf
      ref="html2PdfRequerimento"
      class="mx-auto"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-content-width="955px"
      :manual-pagination="false"
      :paginate-elements-by-height="10000"
    >
      <section slot="pdf-content">
        <div
          class="fast-pdf-html-template-inscricao"
          v-html="fastInscricao.html_template"
        />
      </section>
    </VueHtml2pdf>
    </div>

    <VueHtml2pdf
      ref="html2PdfHistorico"
      class="mx-auto"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-content-width="955px"
      :manual-pagination="false"
      :paginate-elements-by-height="10000"
    >
      <section slot="pdf-content">
        <div
          class="fast-pdf-html-template"
          v-html="boletim_html_template"
        >
        </div>
      </section>
    </VueHtml2pdf>

    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import FastCriaDocumentos from "../components/FastCriaDocumentos";
import FastConsultaDocumentosAssinatura from "../components/FastConsultaDocumentosAssinatura";
import FastCadastroPessoa from "../components/FastCadastroPessoa";
import FastCadastroPessoaResponsavel from "../components/FastCadastroPessoaResponsavel";
import VueHtml2pdf from "vue-html2pdf"; //https://www.npmjs.com/package/vue-html2pdf

export default {
  name: "HomeInternoSecretariaAluno",
  components: {
    Pagination,
    VueHtml2pdf,
    FastCriaDocumentos,
    FastConsultaDocumentosAssinatura,
    FastCadastroPessoa,
    FastCadastroPessoaResponsavel,
  },
  mixins: [methods],
  data: function () {
    return {
      
      modalWidth: "80%",
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      //#region Vars 
      // Loading da ação
      fastCarregandoAcao: false,
      // Pessoa editável
      fastPessoaReadOnly: true,
      // Dados complementares
      fastPlataformaAlunoDadoComplementar: {
        id_plataforma: 0,
        id_pessoa_aluno: 0,
        id_pessoa_pai: "",
        id_pessoa_mae: "",
        profissao_pai: "",
        profissao_mae: "",
        local_trabalho_pai: "",
        endereco_trabalho_pai: "",
        fone_endereco_pai: "",
        fone_endereco_mae: "",
        local_trabalho_mae: "",
        local_endereco_mae: "",
        nome_mae: "",
        nome_pai: "",
        email_mae: "",
        email_pai: "",
        carregando: false,
        alteracao_pendente: false,
      },
      fastPlataformaAlunoDadoComplementarBkp: {
        id_plataforma: 0,
        id_pessoa_aluno: 0,
        id_pessoa_pai: "",
        id_pessoa_mae: "",
        profissao_pai: "",
        profissao_mae: "",
        local_trabalho_pai: "",
        endereco_trabalho_pai: "",
        fone_endereco_pai: "",
        fone_endereco_mae: "",
        local_trabalho_mae: "",
        local_endereco_mae: "",
        nome_mae: "",
        nome_pai: "",
        email_mae: "",
        email_pai: "",
      },
      // Dados médicos
      fastPlataformaAlunoDadosMedicos: {
        id_plataforma: 0,
        id_pessoa_aluno: 0,
        vive_com: "",
        parto_foi: "",
        anamnse_catapora: "",
        anamnse_meningite: "",
        anamnse_hepatite: "",
        anamnse_dengue: "",
        anamnse_pneumonia: "",
        anamnse_alergia_pele: "",
        anamnse_alergia_alimentar: "",
        anamnse_alergia_bronquite: "",
        anamnse_alergia_rinite: "",
        problema_crescimento: "",
        atraso_desenvolvimento: "",
        dificuldade_visao: "",
        dificuldade_fala: "",
        dificuldade_audicao: "",
        dificuldade_movimento: "",
        problema_coracao: "",
        anamnse_intolerancia_lactose: "",
        anamnse_intolerancia_gluten: "",
        anamnse_usa_protese: "",
        anamnse_convulsao: "",
        anamnse_desmaio: "",
        anamnse_diabetes: "",
        anamnse_coluna: "",
        anamnse_fratura: "",
        anamnse_cirurgia: "",
        anamnse_problema_peso: "",
        anamnse_foi_internado: "",
        observacao: "",
        tipo_sanquineo: "",
        carregando: true,
        alteracao_pendente: false,
      },
      // Documentos
      fastPlataformaAlunoDocumento: {
        id_plataforma: 0,
        id_pessoa_aluno: 0,
        tipo_documento: "",
        path_documento: "",
        carregando: true,
      },
      fastPlataformaAlunoDocumentoValida: '',
      fastPlataformaAlunoDocumentoExcluir: {
        id_plataforma: 0,
        id_pessoa_aluno: 0,
        tipo_documento: "",
        path_documento: "",
      },
      fastPlataformaAlunoDocumentos: [],
      // Documentos da plataforma
      fastSecretariaTipoDocumentos: [],
      // Upload arquivo
      optionsArquivo: {
        target:
          settings.endApiFastEad + "api/fast_plataforma_aluno_documento/upload",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: true,
        query: {
          id_plataforma: this.$route.params.id_plataforma,
          id_pessoa_aluno: this.$route.params.id_pessoa_aluno,
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: [
            "pdf",
            "odt",
            "doc",
            "docx",
            "ppt",
            "pptx",
            "gif",
            "jpg",
            "jpeg",
            "png",
          ],
        },
        parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictArquivo: {
        accept:
          "application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/vnd.ms-powerpoint,image/*",
      },
      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },
      // Bolsa
      fastPlataformaAlunoBolsa: {
        id_pessoa_aluno: 0,
        id_plataforma: 0,
        bolsa: "",
        id_pessoa_pagamento_bolsa: "",
        desconto_percentual: 0,
        desconto_valor: 0,
        OBS: "",
        participante_bolsa_familia: "",
        carregando: true,
        alteracao_pendente: false,
      },
      // Carteira
      fastPlataformaAlunoCarteira: {
        id_pessoa_aluno: 0,
        id_plataforma: 0,
        carteira_gerada: "",
        validade: "",
        carregando: true,
        alteracao_pendente: false,
      },
      // Responsáveis
      fastPlataformaAlunoResponsavel: {
        id_pessoa_aluno: 0,
        id_plataforma: 0,
        id_pessoa_responsavel: "",
        fone_comercial: "",
        celular: "",
        email_responsavel: "",
        parentesco: "",
        responsavel_financeiro: false,
        nome_responsavel: "",
        datanascimento_abertura: null,
        cpf_cnpj: "",
        sexo: "",
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        uf: "",
        carregando: true,
        alteracao_pendente: false,
      },
      fastPlataformaAlunoResponsavelEditar: {
        id_pessoa_aluno: 0,
        id_plataforma: 0,
        id_pessoa_responsavel: "",
        fone_comercial: "",
        celular: "",
        email_responsavel: "",
        parentesco: "",
        responsavel_financeiro: false,
        nome_responsavel: "",
        datanascimento_abertura: null,
        cpf_cnpj: "",
        sexo: "",
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        uf: "",
        carregando: true,
        alteracao_pendente: false,
      },
      fastPlataformaAlunoResponsaveis: [],
      // Matrícula
      fastMatriculaAluno: {
        id_matricula: "",
        id_pessoa_aluno: 0,
        tipo_matricula: "",
        data_matricula: "",
        RA: "",
        id_usuario_cadastro: "",
        ano_matricula: "",
        valor_matricula: "",
        vencimento_matricula: "",
        url_boleto: "",
        pago: null,
        data_pagto: null,
        desconto_matricula: "",
        outros_valores: "",
        path_contrato: "",
        ativo: "",
        id_programa_turma: "",
      },
      fastMatriculaAlunoEditar: {
        id_matricula: "",
        id_pessoa_aluno: 0,
        tipo_matricula: "",
        data_matricula: "",
        RA: "",
        id_usuario_cadastro: "",
        ano_matricula: "",
        valor_matricula: "",
        vencimento_matricula: "",
        url_boleto: "",
        pago: null,
        data_pagto: null,
        desconto_matricula: "",
        outros_valores: "",
        path_contrato: "",
        ativo: "",
        id_programa_turma: "",
        id_secretaria_curso: 0,
        path_inscricao: "",
        curso_concluido: null
      },
      fastMatriculasAluno: [],
      // Turmas
      fastPlataformaTurmaTotal: [],
      fastPlataformaTurmaFiltro: [],
      pageOfTurmas: [],
      // Filtros
      filtroPlataformas: [],
      filtroTurnos: [],
      filtroPlataformasEscolhido: "",
      filtroTurnosEscolhido: "",
      filtroNiveis: [],
      filtroNivelEscolhido: "",
      filtroCursos: [],
      filtroCursoEscolhido: "",
      fastTurma: {
        ativo: "",
        data_modifica: "",
        data_registro: "",
        descricao_turma: "",
        etapa: "",
        id_plataforma: 0,
        id_turma: "",
        id_usuario: "",
        id_usuario_modifica: "",
        lotacao: "",
        nivel: "",
        nome_plataforma: "",
        sigla_turma: "",
        turno: "",
        id_secretaria_curso: 0,
        programas: [],
      },
      fastPrograma: {
        id_programa_turma: "",
        ano: "",
        data_inicio: "",
        data_final: "",
        sigla_programa: "",
        sequencia: "",
        id_plataforma: 0,
        id_turma: "",
      },
      // Contrato
      fastSecretariaContratosCurso: [],
      fastContratoEscolhido: {
        id_contrato: "",
        html_template: "",
      },
      // Inscrições
      fastInscricoes: [],
      fastInscricao: {
        id_inscricao_template: "",
        html_template: "",
      },
      // Ucs da matricula
      fastUcsMatricula: {
        total: [],
        loading: true
      },
      fastUcsMatriculaMarcadas: {
        total: [],
        loading: true
      },
      // Invoice
      fastTesourariaInvoice: {
        id_plataforma: 0,
        id_invoice: "",
        data_registro: "",
        id_usuario_gerou: "",
        id_matricula: "",
        nome_aluno: "",
        nome_responsavel: "",
        cpf_responsavel: "",
        cpf_aluno: "",
        cep_responsavel: "",
        endereco_responsavel_numero: "",
        endereco_responsavel_complemento: "",
        subtotal: 0.0,
        desconto_total: 0.0,
        acrescimo: 0.0,
        total: 0.0,
        ativo: "",
        cancelado: "",
        motivo_cancelamento: "",
        id_usuario_cancelamento: "",
        data_cancelamento: "",
        obs: "",
        id_plano_pagamento: "",
        taxa_boleto: 0.0,
        taxa_cartao: 0.0,
        outras_taxas: 0.0,
        parcelas: [],
      },
      // Financeiro
      fastNavegacao: {
          exibirRelatorio : true,
          exibirDetalhes : false,
      },
      fastRecebiveisRel: {
          loading: true,
          filtro: [],
          paginacao: [],
          filtroEscolhido : {
            idPessoa: this.$route.params.id_pessoa_aluno,
            idPlataforma: this.$route.params.id_plataforma,
            nomeAluno: '',
            nomeResponsavel: '',
            idInvoice: '',
            idMatriculador: '',
            dataMatriculaStart: '',
            dataMatriculaEnd: '',
            dataVencimentoStart: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
            dataVencimentoEnd: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
            dataRecebimentoStart: '',
            dataRecebimentoEnd: '',
            dataCancelamentoStart: '',
            dataCancelamentoEnd: '',
            statusVencido: '',
            statusPago: '',
            statusPendente: '',
            statusCancelado: ''
          },
          filtros : {
            plataformas: [],
            status: '',
            usuariosGerou : [],
            usuariosCancelou : [],
            usuarioGerou: '',
            usuarioCancelou: '',
            datalistId: 'suggestions-list-' + Math.floor(Math.random() * 1000000)
          },
          modal : {
            logRecebivel: '',
            detalhesComissao: [],
            detalhesComissaoPaginacao: [],
            totalComissoes: {
              total: '',
              pago: ''
            }
          },
          gatewaysPagamento: [],
          orderBy: {
            plataforma: 0, 
            vencimento: 0, 
            recebimento: 0, 
            dias_de_atraso: 0, 
            invoice: 0, 
            parcela: 0, 
            aluno: 0, 
            valor_parcela: 0, 
            valor_recebido: 0, 
            status: 0, 
            boleto: 0, 
          },
          selectedItems: [],
          selectAll: false
        },
      // Requerimentos
      fastTipoRequerimentos: [],
      fastRequerimentos: [],
      fastRequerimentoNovo: {
        id_registro_requerimento: 0,
        id_plataforma: 0,
        id_tipo_requerimento: 0,
        id_pessoa_aluno: 0,
        detalhe_requerimento: "",
        valor_requerimento: 0,
        taxa: 0,
        desconto: 0,
        total_requerimento: 0,
        data_registro: null,
        data_inicio_analise: null,
        data_fim_analise: null,
        solicitado: null,
        em_analise: null,
        aprovada: null,
        data_aprovacao: null,
        path_anexo: "",
        id_usuario_aprovador: 0,
        obs_aprovador: null,
        pago: null,
        data_pagto: null,
        path_boleto: null,
        data_entrega: null,
        id_usuario_registro: 0,
        data_rejeicao: null,
        boleto_gerado: null,
        nome_requerimento: "",
        aceita_anexo: true,
        id_item_cobranca: 0
      },
      fastRequerimentoEditar: {
        id_registro_requerimento: 0,
        id_plataforma: 0,
        id_tipo_requerimento: 0,
        id_pessoa_aluno: 0,
        detalhe_requerimento: "",
        valor_requerimento: 0,
        taxa: 0,
        desconto: 0,
        total_requerimento: 0,
        data_registro: "",
        data_inicio_analise: null,
        data_fim_analise: null,
        solicitado: null,
        em_analise: null,
        aprovada: null,
        data_aprovacao: null,
        path_anexo: "",
        id_usuario_aprovador: 0,
        obs_aprovador: null,
        pago: null,
        data_pagto: null,
        path_boleto: null,
        data_entrega: null,
        id_usuario_registro: 0,
        data_rejeicao: null,
        boleto_gerado: null,
        nome_requerimento: "",
        aceita_anexo: false,
        id_item_cobranca: 0      
      },
      // Ocorrências
      fastTipoOcorrencias: [],
      fastOcorrencias: [],
      fastOcorrenciaNovo: {
        id_registro_ocorrencia: 0,
        id_tipo_ocorrencia: 0,
        id_pessoa_aluno: 0,
        data_registro: undefined,
        detalhe_ocorrencia: "",
        path_anexo: "",
        responsavel_notificado: false,
        data_notificacao: null,
        id_usuario_registro: 0,
        nome_ocorrencia: "",
        aceita_anexo: true      
      },
      fastOcorrenciaEditar: {
        id_registro_ocorrencia: 0,
        id_tipo_ocorrencia: 0,
        id_pessoa_aluno: 0,
        data_registro: undefined,
        detalhe_ocorrencia: "",
        path_anexo: "",
        responsavel_notificado: false,
        data_notificacao: null,
        id_usuario_registro: 0,
        nome_ocorrencia: "",
        aceita_anexo: true  
      },
      // pdf
      htmlToPdfOptions: {
        margin: 0.5,
        filename: 'Contrato.pdf',
        image: {
            type: 'jpeg',
            quality: 1
        },
        enableLinks: false,
        html2canvas: {
            scale: 2,
            scrollX: 0,
            scrollY: 0,
            useCORS: true,
            width: 960,
        },
        jsPDF: {
            unit: 'in',
            format: 'a4',
            orientation: 'portrait'
        },
      },
      // WhatsApp
      fastLinkWhats: {
        path_boleto: null,
        parcela: "",
        valor_parcela_total: "",
        data_vencimento: "",
        nome_plataforma: "",
        tipo: "",
        telefone_aluno: "",
        telefone_responsavel: ""
      },
      // Boleto da taxa de matrícula
      fastMatriculaInvoice: {
        parcelas: [],
        loading: true
      },
      fastExibirOpcoesContrato: false,
      fastExibirOpcoesInscricao: false,
      // Comissão de professores
      fastProfessorComissao: {
        id_professor: 0,
        comissao_porcent: 0,
        comissao_valor: 0,
        id_invoice: 0,
        id_plataforma: 0,
        loading: false
      },
      //#enregion
      //#region Vars Histórico
      fastHistoricoSecretariaCursos: {
        total: [],
        loading: true
      },
      boletim_html_template: '<div class="row"><div class="col-sm-12 col-md-12 col-lg-9"><div class="row"><div class="col-12"><div class="text-center p-4 border border-2 border-secondary"><h3>Extrato de Notas e Faltas Parcial</h3></div></div></div></div><div class="col-sm-12 col-md-12 col-lg-3">%img_logo_plataforma%</div><div class="col-sm-12 col-md-12 col-lg-12 mt-4"><div class="row"><div class="col-sm-12 col-md-12 col-lg-8"><span>Nome: <b>%nome_aluno%</b></span></div><div class="col-sm-12 col-md-12 col-lg-4"><span>CPF: <b>%cpf_aluno%</b></span></div></div><div class="row"><div class="col-sm-12 col-md-12 col-lg-4"><span>Nacionalidade: <b>%nacionalidade_aluno%</b></span></div><div class="col-sm-12 col-md-12 col-lg-4"></div><div class="col-sm-12 col-md-12 col-lg-4"><span>Nascimento: <b>%nascimento_aluno%</b></span></div></div><hr class="mt-2 mb-2"><div class="row"><div class="col-sm-12 col-md-12 col-lg-12"><span>Curso: <b>%nome_curso%, %nivel_curso%</b></span></div></div><div class="row"><div class="col-sm-12 col-md-12 col-lg-12"><span>%descricao_legal%</span></div></div><div class="row"><div class="col-12 table-responsive mt-4"><table class="table"><thead><tr><th scope="col">ANO</th><th scope="col">DISCIPLINA</th><th scope="col">FALTAS POSSÍVEIS</th><th scope="col">FALTAS</th><th scope="col">CH</th><th scope="col">NOTA</th><th scope="col">SITUAÇÃO</th></tr></thead><tbody>%tbody_boletim%</tbody></table></div></div></div></div>',
      fastHistoricoNovo: {
        id_aluno_historico: 0,
        ano: 2023,
        periodo: "A",
        id_secretaria_curso: 0,
        id_turma: 0,
        id_secretaria_curso_programa: 0,
        carga_horaria: 0,
        dias_letivos: 0,
        faltas: 0,
        id_tipo_resultado_aluno: 0,
        id_pessoa: 0,
        tipo_avaliacao: "N",
        nota_maxima: 10,
        nro_decimais: 2,
        turno: "M",
        forma_carga_horaria: "",
        nota_aprovacao: 7,
        frequencia: 70,
        id_plataforma: 0,
        id_pessoa_aluno: 0,
        carregando_acao: false,
        unidades: []
      },
      fastHistoricoEditar: {
        id_aluno_historico: 0,
        ano: "",
        periodo: "A",
        id_secretaria_curso: 0,
        id_turma: "",
        id_secretaria_curso_programa: 0,
        carga_horaria: 0,
        dias_letivos: 0,
        faltas: 0,
        id_tipo_resultado_aluno: "",
        id_pessoa: 0,
        tipo_avaliacao: "N",
        nota_maxima: 10,
        nro_decimais: 2,
        turno: "M",
        forma_carga_horaria: "",
        nota_aprovacao: 7,
        frequencia: 70,
        id_plataforma: 0,
        id_pessoa_aluno: 0,
        carregando_acao: false,
        unidades: []
      },
      fastSecretariaCursos: {
        total: [],
        loading: true
      },
      fastSecretariaTurmas: {
        total: [],
        loading: true
      },
      fastSecretariaCursoModulos: {
        total: [],
        loading: true
      },
      fastResultados: {
        total: [],
        loading: true
      },
      fastPessoasParceiros: {
        total: [],
        loading: true
      },
      fastCursosPrograma: {
        total: [],
        loading: true
      },
      fastHistoricoUnidade: {
        id_curso: 0,
        nome_curso: "",
        nome_curso_mae: ""
      },
      fastHistoricoAluno: [],
      fastAproveitamentoAluno: {
        total: [],
        loading: true
      },
      templatesHistorico: [],
      idTemplateHistoricoEscolhido: '',
      //#enregion
      //#region Var certificado
      templatesCertificado: [],
      idTemplateCertificadoEscolhido: '',
      htmlCertificado: '',
      fastDataParaEncerramento: "",
      fastIdMatricula: "",
      fastIdSecretariaCurso: "",
      //#endregion
      fastIdAssinaturaMatricula: "",
      fastIdAssinaturaSecretariaCurso: "",
    };
  },
  mounted: function () {
    if (
      this.$route.params.id_plataforma &&
      this.$route.params.id_pessoa_aluno
    ) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          this.$store.state.fastCarregando = false;
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {            
            //this.buscaInfoPessoa(this.$route.params.id_pessoa_aluno);

            this.getFastPlataformaAlunoDocumento(
              this.$route.params.id_plataforma,
              this.$route.params.id_pessoa_aluno
            );
            this.getFastPlataformaAlunoBolsa(
              this.$route.params.id_plataforma,
              this.$route.params.id_pessoa_aluno
            );
            this.getFastPlataformaAlunoCarteira(
              this.$route.params.id_plataforma,
              this.$route.params.id_pessoa_aluno
            );
            this.getFastPlataformaAlunoDadosMedicos(
              this.$route.params.id_plataforma,
              this.$route.params.id_pessoa_aluno
            );
            this.getSecretariaTipoDocumentos(this.$route.params.id_plataforma);
            this.getFastMatriculaAluno(
              this.$route.params.id_plataforma,
              this.$route.params.id_pessoa_aluno
            );
            this.getFastPlataformaTurmas(this.$route.params.id_plataforma);
            this.getRequerimentos(this.$route.params.id_plataforma, this.$route.params.id_pessoa_aluno);
            this.getFastOcorrencias(this.$route.params.id_pessoa_aluno);
            this.getTipoRequerimentos(this.$route.params.id_plataforma);            
            this.getTipoOcorrencias(this.$route.params.id_plataforma);
            this.getHistoricoSecretariaCursos(this.$route.params.id_plataforma, this.$route.params.id_pessoa_aluno);
            this.getHistoricoTemplates();
            this.getCertificadoTemplates();

            // Buscando info para usar no getFinanceiroAluno
            this.buscaInfoPessoa(this.$route.params.id_pessoa_aluno, this.$route.params.id_plataforma);
            

          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },

  computed: {
      totalParcelaSelected() {
        return this.fastRecebiveisRel.filtro.reduce((total, item) => {
          if (!this.fastRecebiveisRel.selectedItems.length){
            return total + item.valor_parcela
          }
          if (this.fastRecebiveisRel.selectedItems.includes(item.id_invoice + "-" + item.parcela)) {
            return total + item.valor_parcela
          } else {
            return total
          }
        }, 0)
      },
      totalParcelaPagoSelected() {
        return this.fastRecebiveisRel.filtro.reduce((total, item) => {
          if (!this.fastRecebiveisRel.selectedItems.length){
            return total + item.valor_pago
          }
          if (this.fastRecebiveisRel.selectedItems.includes(item.id_invoice + "-" + item.parcela)) {
            return total + item.valor_pago
          } else {
            return total
          }
        }, 0)
      },
      fastFiltroHistoricoCertificados(){
        const fastFiltrado = []
        this.fastHistoricoSecretariaCursos.total.forEach(item => {
          if (item.id_secretaria_curso == this.fastIdSecretariaCurso){
            fastFiltrado.push(item);
          }
        })
        return fastFiltrado
      },
  },
  watch: {
      'fastRecebiveisRel.selectAll'(val) {
        if (val) {
          this.fastRecebiveisRel.selectedItems = this.fastRecebiveisRel.filtro.map((item) => item.id_invoice + "-" + item.parcela)
        } else {
          this.fastRecebiveisRel.selectedItems = []
        }
      }
    },
  methods: {
    async buscaInfoPessoa(id_pessoa, id_plataforma) {
      this.promiseGetFastApi("api/fast_plataforma_usuario/busca_pessoa_secretaria", `id_pessoa=${id_pessoa}&id_plataforma=${id_plataforma}`)
      .then(res => {
        // Verifica JSON
        const [err, obj] = this.safeJsonParse(res);
        if (err) {
          this.exibeToasty("Falha ao interpretar json", "error") 
          console.log('Falha ao interpretar json: ' + err.message);
        } else {
          if (!obj.error) {
            if (obj.datanascimento_abertura)
              obj.datanascimento_abertura = obj.datanascimento_abertura.split(
                "T"
              )[0];
            else obj.datanascimento_abertura = "";
            if (!obj.nome_razao) obj.nome_razao = "";
            if (!obj.sobrenome_fantasia) obj.sobrenome_fantasia = "";
            if (!obj.cpf_cnpj) obj.cpf_cnpj = "";
            if (!obj.cep) obj.cep = "";
            if (!obj.cidade) obj.cidade = "";
            if (!obj.complemento) obj.complemento = "";
            if (!obj.logradouro) obj.logradouro = "";
            if (!obj.numero) obj.numero = "";
            if (!obj.sexo) obj.sexo = "";
            if (!obj.telefone) obj.telefone = "";
            if (!obj.uf) obj.uf = "";
            if (!obj.bairro) obj.bairro = "";

            if (!obj.id_usuario)
              this.exibeToasty(
                "Não foi possível identificar o id_usuario. Consulte um administrador do sistema.",
                "error"
              );
            if (!obj.id_pessoa)
              this.exibeToasty(
                "Não foi possível identificar o id_pessoa. Consulte um administrador do sistema.",
                "error"
              );
            if (!obj.email)
              this.exibeToasty(
                "Não foi possível identificar o email do usuário. Consulte um administrador do sistema.",
                "error"
              );

              this.$store.state.fastPessoa = obj;
              // Não copia por referência
              this.$store.state.fastPessoaReadOnly = Object.assign({}, obj);

              this.getRecebiveisRel();
            
        } else {
          this.exibeToasty("Erro ao recuperar pessoa", "error") 
        }
        this.$store.state.fastPessoa.loading = false
        }
      })
      .catch(e => {
        this.exibeToasty(e, "error")  
        this.$store.state.fastPessoa.loading = false  
      })
    
    },
    // Documentos
    async getFastPlataformaAlunoDocumento(id_plataforma, id_pessoa_aluno) {
      this.fastPlataformaAlunoDocumento.carregando = true;
      this.promiseGetFastApi(
        "api/fast_plataforma_aluno_documento/lista",
        "id_plataforma=" + id_plataforma + "&id_pessoa_aluno=" + id_pessoa_aluno
      )
        .then((obj) => {
          this.fastPlataformaAlunoDocumento.carregando = false;
          if (obj.length) this.fastPlataformaAlunoDocumentos = obj;
          else this.fastPlataformaAlunoDocumentos = [];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getSecretariaTipoDocumentos(id_plataforma) {
      this.promiseGetFastApi(
        "api/fast_secretaria_tipo_documento/lista",
        "id_plataforma=" + id_plataforma
      )
        .then((obj) => {
          if (obj.length) {
            this.fastSecretariaTipoDocumentos = obj;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fileValidation(e) {
      return false;
    },
    fileSuccess(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      if (!result.error) {
        this.fastPlataformaAlunoDocumento.path_documento = result.url;
        this.cadastraAlunoDocumento();
      }
    },
    async cadastraAlunoDocumento() {
      const obj = {
        id_plataforma: this.$route.params.id_plataforma,
        id_pessoa_aluno: this.$route.params.id_pessoa_aluno,
        tipo_documento: this.fastPlataformaAlunoDocumento.tipo_documento,
        path_documento: this.fastPlataformaAlunoDocumento.path_documento,
      };
      this.$store.state.fastCarregando = true;
      this.promiseInserirFastApi(obj, "fast_plataforma_aluno_documento")
        .then((res) => {
          this.$store.state.fastCarregando = false;
          if (res.length) {
            this.exibeToasty("Documento salvo com sucesso", "success");
            this.getFastPlataformaAlunoDocumento(
              this.$route.params.id_plataforma,
              this.$route.params.id_pessoa_aluno
            );
            this.hideModal("modalCadastrarAlunoDocumento");
          } else {
            this.exibeToasty("Erro ao salvar documento", "error");
          }
        })
        .catch((e) => {
          this.$store.state.fastCarregando = false;
          this.exibeToasty("Erro ao salvar documento", "error");
        });
    },
    docDuplicado(){
      this.fastPlataformaAlunoDocumentoValida =  this.fastPlataformaAlunoDocumentos.find(e => e.tipo_documento == this.fastPlataformaAlunoDocumento.tipo_documento)
    },
    exibeModalExcluirAlunoDocumento(documento) {
      this.fastPlataformaAlunoDocumentoExcluir = documento;
      this.showModal("modalExcluirAlunoDocumento");
    },
    async excluirAlunoDocumento() {
      this.promiseExcluirFastApi(
        this.fastPlataformaAlunoDocumentoExcluir,
        "fast_plataforma_aluno_documento"
      )
        .then((e) => {
          this.exibeToasty("Documento excluído com sucesso", "success");
          this.getFastPlataformaAlunoDocumento(
            this.$route.params.id_plataforma,
            this.$route.params.id_pessoa_aluno
          );
          this.hideModal("modalExcluirAlunoDocumento");
          this.hideModal("modalCadastrarAlunoDocumento");
        })
        .catch((e) => {
          this.exibeToasty("Erro ao excluir", "error");
          this.$store.state.fastCarregando = false;
        });
    },
    // Bolsa
    async getFastPlataformaAlunoBolsa(id_plataforma, id_pessoa_aluno) {
      this.fastPlataformaAlunoBolsa.carregando = true;
      this.fastPlataformaAlunoBolsa.alteracao_pendente = false;
      this.promiseGetFastApi(
        "api/fast_plataforma_aluno_bolsa/lista",
        "id_plataforma=" + id_plataforma + "&id_pessoa_aluno=" + id_pessoa_aluno
      )
        .then((obj) => {
          if (obj.length) {
            obj[0].carregando = false;
            obj[0].alteracao_pendente = false;
            this.fastPlataformaAlunoBolsa = obj[0];
          } else {
            this.fastPlataformaAlunoBolsa.carregando = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async atualizaPlataformaAlunoBolsa() {
      const obj = {
        id_plataforma: this.$route.params.id_plataforma,
        id_pessoa_aluno: this.$route.params.id_pessoa_aluno,
        bolsa: this.fastPlataformaAlunoBolsa.bolsa
          ? this.fastPlataformaAlunoBolsa.bolsa
          : null,
        id_pessoa_pagamento_bolsa: this.fastPlataformaAlunoBolsa
          .id_pessoa_pagamento_bolsa
          ? this.fastPlataformaAlunoBolsa.id_pessoa_pagamento_bolsa
          : null,
        desconto_percentual: this.fastPlataformaAlunoBolsa.desconto_percentual
          ? this.fastPlataformaAlunoBolsa.desconto_percentual
          : null,
        desconto_valor: this.fastPlataformaAlunoBolsa.desconto_valor
          ? this.fastPlataformaAlunoBolsa.desconto_valor
          : null,
        OBS: this.fastPlataformaAlunoBolsa.OBS
          ? this.fastPlataformaAlunoBolsa.OBS
          : null,
        participante_bolsa_familia: this.fastPlataformaAlunoBolsa
          .participante_bolsa_familia
          ? this.fastPlataformaAlunoBolsa.participante_bolsa_familia
          : false,
      };
      this.promiseInserirFastApi(obj, "fast_plataforma_aluno_bolsa")
        .then((res) => {
          if (res.length) {
            this.exibeToasty("Alterações salvas com sucesso", "success");
            this.getFastPlataformaAlunoBolsa(
              this.$route.params.id_plataforma,
              this.$route.params.id_pessoa_aluno
            );
          } else {
            this.exibeToasty("Erro ao salvar alterações", "error");
          }
        })
        .catch((e) => {
          this.exibeToasty("Erro ao salvar alterações", "error");
        });
    },
    // Carteira
    async getFastPlataformaAlunoCarteira(id_plataforma, id_pessoa_aluno) {
      this.fastPlataformaAlunoCarteira.carregando = true;
      this.fastPlataformaAlunoCarteira.alteracao_pendente = false;
      this.promiseGetFastApi(
        "api/fast_plataforma_aluno_carteira/lista",
        "id_plataforma=" + id_plataforma + "&id_pessoa_aluno=" + id_pessoa_aluno
      )
        .then((obj) => {
          if (obj.length) {
            obj[0].carregando = false;
            obj[0].alteracao_pendente = false;
            if (obj[0].validade)
              obj[0].validade = obj[0].validade.split("T")[0];
            this.fastPlataformaAlunoCarteira = obj[0];
          } else {
            this.fastPlataformaAlunoCarteira.carregando = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async atualizaPlataformaAlunoCarteira() {
      const obj = {
        id_plataforma: this.$route.params.id_plataforma,
        id_pessoa_aluno: this.$route.params.id_pessoa_aluno,
        carteira_gerada: this.fastPlataformaAlunoCarteira.carteira_gerada
          ? this.fastPlataformaAlunoCarteira.carteira_gerada
          : false,
        validade: this.fastPlataformaAlunoCarteira.validade
          ? this.fastPlataformaAlunoCarteira.validade
          : null,
      };
      this.promiseInserirFastApi(obj, "fast_plataforma_aluno_carteira")
        .then((res) => {
          if (res.length) {
            this.exibeToasty("Alterações salvas com sucesso", "success");
            this.getFastPlataformaAlunoCarteira(
              this.$route.params.id_plataforma,
              this.$route.params.id_pessoa_aluno
            );
          } else {
            this.exibeToasty("Erro ao salvar alterações", "error");
          }
        })
        .catch((e) => {
          this.exibeToasty("Erro ao salvar alterações", "error");
        });
    },
    // Dados complementares
    async getFastPlataformaAlunoDadoComplementar(
      id_plataforma,
      id_pessoa_aluno
    ) {
      this.fastPlataformaAlunoDadoComplementar.carregando = true;
      this.fastPlataformaAlunoDadoComplementar.alteracao_pendente = false;
      this.promiseGetFastApi(
        "api/fast_plataforma_aluno_dado_complementar/lista",
        "id_plataforma=" + id_plataforma + "&id_pessoa_aluno=" + id_pessoa_aluno
      )
        .then((obj) => {
          if (obj.length) {
            obj[0].carregando = false;
            obj[0].alteracao_pendente = false;
            this.fastPlataformaAlunoDadoComplementar = obj[0];
            // Não copia por referência
            this.fastPlataformaAlunoDadoComplementarBkp = Object.assign({}, obj[0]);
          } else {
            this.fastPlataformaAlunoDadoComplementar.carregando = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async atualizaAlunoDadoComplementar() {
      let erros = [];
      if (
        this.fastPlataformaAlunoDadoComplementar.nome_mae ||
        this.fastPlataformaAlunoDadoComplementar.email_mae
      ) {
        if (
          !this.fastPlataformaAlunoDadoComplementar.nome_mae ||
          !this.fastPlataformaAlunoDadoComplementar.email_mae
        )
          erros.push("O nome e email é obrigatório");
        else if (
          this.fastPlataformaAlunoDadoComplementar.nome_mae.split(" ").length <
          2
        )
          erros.push("O sobrenome é obrigatório");
      }
      if (
        this.fastPlataformaAlunoDadoComplementar.nome_pai ||
        this.fastPlataformaAlunoDadoComplementar.email_pai
      ) {
        if (
          !this.fastPlataformaAlunoDadoComplementar.nome_pai ||
          !this.fastPlataformaAlunoDadoComplementar.email_pai
        )
          erros.push("O nome e email é obrigatório");
        else if (
          this.fastPlataformaAlunoDadoComplementar.nome_pai.split(" ").length <
          2
        )
          erros.push("O sobrenome é obrigatório");
      }

      if (!erros.length) {
        const obj = {
          id_plataforma: this.$route.params.id_plataforma,
          id_pessoa_aluno: this.$route.params.id_pessoa_aluno,
          profissao_pai: this.fastPlataformaAlunoDadoComplementar.profissao_pai,
          profissao_mae: this.fastPlataformaAlunoDadoComplementar.profissao_mae,
          local_trabalho_pai: this.fastPlataformaAlunoDadoComplementar
            .local_trabalho_pai,
          endereco_trabalho_pai: this.fastPlataformaAlunoDadoComplementar
            .endereco_trabalho_pai,
          fone_endereco_pai: this.fastPlataformaAlunoDadoComplementar
            .fone_endereco_pai,
          fone_endereco_mae: this.fastPlataformaAlunoDadoComplementar
            .fone_endereco_mae,
          local_trabalho_mae: this.fastPlataformaAlunoDadoComplementar
            .local_trabalho_mae,
          local_endereco_mae: this.fastPlataformaAlunoDadoComplementar
            .local_endereco_mae,
          nome_mae: this.fastPlataformaAlunoDadoComplementar.nome_mae
            ? this.fastPlataformaAlunoDadoComplementar.nome_mae
            : null,
          nome_pai: this.fastPlataformaAlunoDadoComplementar.nome_pai
            ? this.fastPlataformaAlunoDadoComplementar.nome_pai
            : null,
          email_mae: this.fastPlataformaAlunoDadoComplementar.email_mae
            ? this.fastPlataformaAlunoDadoComplementar.email_mae
            : null,
          email_pai: this.fastPlataformaAlunoDadoComplementar.email_pai
            ? this.fastPlataformaAlunoDadoComplementar.email_pai
            : null,
        };
        this.promiseInserirFastApi(
          obj,
          "fast_plataforma_aluno_dado_complementar"
        )
          .then((res) => {
            if (res.length) {
              this.exibeToasty("Alterações salvas com sucesso", "success");
              this.getFastPlataformaAlunoDadoComplementar(
                this.$route.params.id_plataforma,
                this.$route.params.id_pessoa_aluno
              );
            } else {
              this.exibeToasty("Erro ao salvar alterações", "error");
            }
          })
          .catch((e) => {
            this.exibeToasty("Erro ao salvar alterações", "error");
          });
      } else {
        erros.forEach((e) => this.exibeToasty(e, "error"));
      }
    },
    // Dados médicos
    async getFastPlataformaAlunoDadosMedicos(id_plataforma, id_pessoa_aluno) {
      this.fastPlataformaAlunoDadosMedicos.carregando = true;
      this.fastPlataformaAlunoDadosMedicos.alteracao_pendente = false;
      this.promiseGetFastApi(
        "api/fast_plataforma_aluno_dados_medicos/lista",
        "id_plataforma=" + id_plataforma + "&id_pessoa_aluno=" + id_pessoa_aluno
      )
        .then((obj) => {
          if (obj.length) {
            obj[0].carregando = false;
            obj[0].alteracao_pendente = false;
            this.fastPlataformaAlunoDadosMedicos = obj[0];
          } else {
            this.fastPlataformaAlunoDadosMedicos.carregando = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async atualizaAlunoDadosMedicos() {
      const obj = {
        id_plataforma: this.$route.params.id_plataforma,
        id_pessoa_aluno: this.$route.params.id_pessoa_aluno,
        vive_com: this.fastPlataformaAlunoDadosMedicos.vive_com
          ? this.fastPlataformaAlunoDadosMedicos.vive_com
          : "Ninguém",
        parto_foi: this.fastPlataformaAlunoDadosMedicos.parto_foi
          ? this.fastPlataformaAlunoDadosMedicos.parto_foi
          : null,
        anamnse_catapora: this.fastPlataformaAlunoDadosMedicos.anamnse_catapora
          ? true
          : false,
        anamnse_meningite: this.fastPlataformaAlunoDadosMedicos
          .anamnse_meningite
          ? true
          : false,
        anamnse_hepatite: this.fastPlataformaAlunoDadosMedicos.anamnse_hepatite
          ? true
          : false,
        anamnse_dengue: this.fastPlataformaAlunoDadosMedicos.anamnse_dengue
          ? true
          : false,
        anamnse_pneumonia: this.fastPlataformaAlunoDadosMedicos
          .anamnse_pneumonia
          ? true
          : false,
        anamnse_alergia_pele: this.fastPlataformaAlunoDadosMedicos
          .anamnse_alergia_pele
          ? true
          : false,
        anamnse_alergia_alimentar: this.fastPlataformaAlunoDadosMedicos
          .anamnse_alergia_alimentar
          ? true
          : false,
        anamnse_alergia_bronquite: this.fastPlataformaAlunoDadosMedicos
          .anamnse_alergia_bronquite
          ? true
          : false,
        anamnse_alergia_rinite: this.fastPlataformaAlunoDadosMedicos
          .anamnse_alergia_rinite
          ? true
          : false,
        problema_crescimento: this.fastPlataformaAlunoDadosMedicos
          .problema_crescimento
          ? true
          : false,
        atraso_desenvolvimento: this.fastPlataformaAlunoDadosMedicos
          .atraso_desenvolvimento
          ? true
          : false,
        dificuldade_visao: this.fastPlataformaAlunoDadosMedicos
          .dificuldade_visao
          ? true
          : false,
        dificuldade_fala: this.fastPlataformaAlunoDadosMedicos.dificuldade_fala
          ? true
          : false,
        dificuldade_audicao: this.fastPlataformaAlunoDadosMedicos
          .dificuldade_audicao
          ? true
          : false,
        dificuldade_movimento: this.fastPlataformaAlunoDadosMedicos
          .dificuldade_movimento
          ? true
          : false,
        problema_coracao: this.fastPlataformaAlunoDadosMedicos.problema_coracao
          ? true
          : false,
        anamnse_intolerancia_lactose: this.fastPlataformaAlunoDadosMedicos
          .anamnse_intolerancia_lactose
          ? true
          : false,
        anamnse_intolerancia_gluten: this.fastPlataformaAlunoDadosMedicos
          .anamnse_intolerancia_gluten
          ? true
          : false,
        anamnse_usa_protese: this.fastPlataformaAlunoDadosMedicos
          .anamnse_usa_protese
          ? true
          : false,
        anamnse_convulsao: this.fastPlataformaAlunoDadosMedicos
          .anamnse_convulsao
          ? true
          : false,
        anamnse_desmaio: this.fastPlataformaAlunoDadosMedicos.anamnse_desmaio
          ? true
          : false,
        anamnse_diabetes: this.fastPlataformaAlunoDadosMedicos.anamnse_diabetes
          ? true
          : false,
        anamnse_coluna: this.fastPlataformaAlunoDadosMedicos.anamnse_coluna
          ? true
          : false,
        anamnse_fratura: this.fastPlataformaAlunoDadosMedicos.anamnse_fratura
          ? true
          : false,
        anamnse_cirurgia: this.fastPlataformaAlunoDadosMedicos.anamnse_cirurgia
          ? true
          : false,
        anamnse_problema_peso: this.fastPlataformaAlunoDadosMedicos
          .anamnse_problema_peso
          ? true
          : false,
        anamnse_foi_internado: this.fastPlataformaAlunoDadosMedicos
          .anamnse_foi_internado
          ? true
          : false,
        observacao: this.fastPlataformaAlunoDadosMedicos.observacao
          ? this.fastPlataformaAlunoDadosMedicos.observacao
          : null,
        tipo_sanquineo: this.fastPlataformaAlunoDadosMedicos.tipo_sanquineo
          ? this.fastPlataformaAlunoDadosMedicos.tipo_sanquineo
          : null,
      };


      this.promiseInserirFastApi(obj, "fast_plataforma_aluno_dados_medicos")
        .then((res) => {
          console.log("promiseInserirFastApi", res);
          if (res.length) {
            this.exibeToasty("Alterações salvas com sucesso", "success");
            this.getFastPlataformaAlunoDadosMedicos(
              this.$route.params.id_plataforma,
              this.$route.params.id_pessoa_aluno
            );
          } else {
            this.exibeToasty("Erro ao salvar alterações", "error");
          }
        })
        .catch((e) => {
          this.exibeToasty("Erro ao salvar alterações", "error");
        });
    },
    // Financeiro
    validaDataFiltro(inicio, fim, infErro){
        if(inicio === '' || fim === ''){
          return true
        } else if(inicio > fim){
          if (infErro) this.exibeToasty('A data inicial não pode ser maior que a data final', 'error');
          return false
        }
        return true;
    },
    async getRecebiveisRel(){       
      this.fastRecebiveisRel.loading = true

      this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista_contas_receber", 
        `IdPlataforma=${this.$route.params.id_plataforma}&IdPessoaAluno=${this.$route.params.id_pessoa_aluno}`)

      .then(res => {
        console.log("getRecebiveisRel", res)
        if (res.length) {
          this.fastRecebiveisRel.filtro = res
        }
        else {
          this.fastRecebiveisRel.filtro = []
        }

        this.fastRecebiveisRel.loading = false
      }).catch(e => {
        console.log(e)
        this.exibeToasty(e, "error")
        this.fastRecebiveisRel.loading = false
      })
    },
    calculaDiasAtraso(dataVenc){
      const dataAtual = new Date()
      const dataCalc = new Date(dataVenc)

      const difMs = dataAtual - dataCalc
      const difDias = difMs / 86400000 - 1

      return difDias.toFixed();
    },
    criaTooltip(e){

      return `<div class="text-left">
                Aluno: ${e.nome_aluno}<br>
                Resp.: ${e.nome_responsavel}<br>
                Data Vencimento: ${this.formataDataT(e.data_vencimento)}<br>
                Valor: R$ ${this.formataPreco(e.valor_parcela)}<br>
                Valor Pago: R$ ${e.valor_pago ? this.formataPreco(e.valor_pago) : this.formataPreco(0)}
              </div>`;
    },  
    orderByColumn(coluna) {
      Object.keys(this.fastRecebiveisRel.orderBy).forEach(key => {
        if (key !== coluna) {
          this.fastRecebiveisRel.orderBy[key] = 0;
        }
      });
      // Atualiza a ordem de classificação para a coluna clicada
      this.fastRecebiveisRel.orderBy[coluna] = this.fastRecebiveisRel.orderBy[coluna] === 1 ? -1 : 1;

      // Classifica a tabela de acordo com as colunas ordenadas e a ordem de classificação
      this.fastRecebiveisRel.filtro.sort((a, b) => {
        const order = this.fastRecebiveisRel.orderBy[coluna];

        if (coluna === 'plataforma') {
          return (a.plataforma > b.plataforma ? 1 : -1) * order; 
        } else if (coluna === 'vencimento') {
          return (a.data_vencimento > b.data_vencimento ? 1 : -1) * order;
        } else if (coluna === 'recebimento') {
          function ordenarPorData(a, b, order) {
            // Verifica se ambos os valores são nulos ou iguais a "Sem data"
            if ((!a || a === 'Sem data') && (!b || b === 'Sem data')) {
              return 0;
            }

            // Verifica se apenas um dos valores é nulo ou "Sem data" e coloca por último
            if (!a || a === 'Sem data') {
              return 1 * order;
            }

            if (!b || b === 'Sem data') {
              return -1 * order;
            }

            // Ordena as datas restantes normalmente
            const dataA = new Date(a);
            const dataB = new Date(b);

            return (dataA > dataB ? 1 : -1) * order;
          }

          return ordenarPorData(a.data_pagamento, b.data_pagamento, order);
        } else if (coluna === "dias_de_atraso") {
            const aDias = this.calculaDiasAtraso(a.data_vencimento);
            const bDias = this.calculaDiasAtraso(b.data_vencimento);
            return (aDias - bDias) * order;
        } else if (coluna === 'invoice') {
          return (a.id_invoice > b.id_invoice ? 1 : -1) * order; 
        } else if (coluna === 'aluno') {
          return a.nome_aluno.localeCompare(b.nome_aluno) * order;
        } else if (coluna === 'valor_parcela') {
          return (a.valor_parcela - b.valor_parcela) * order;
        } else if (coluna === 'valor_recebido') {
          return (a.valor_pago - b.valor_pago) * order;
        } else if (coluna === 'status') {
            if (a.cancelado) return -1 * order;
            if (b.cancelado) return 1 * order;
            if (a.pago && !b.pago) return -1 * order;
            if (!a.pago && b.pago) return 1 * order;
            if (new Date(a.data_vencimento) < new Date() && new Date(b.data_vencimento) >= new Date()) return -1 * order;
            if (new Date(b.data_vencimento) < new Date() && new Date(a.data_vencimento) >= new Date()) return 1 * order;
            return 0;
          } else {
            return 0;
        }
      });
    },
    // Matrícula
    retornaTotalMatricula(valor_matricula, desconto_matricula, outros_valores){
      let valor_total = 0
      if (valor_matricula) {
        valor_total = parseFloat(valor_matricula)
        if (desconto_matricula) valor_total -= parseFloat(desconto_matricula)
        if (outros_valores) valor_total += parseFloat(outros_valores)
      }
      return this.formataPreco(valor_total)
    },
    async getFastMatriculaAluno(id_plataforma, id_pessoa_aluno) {
      this.promiseGetFastApi(
        "api/fast_secretaria_matricula_aluno/lista",
        "id_plataforma=" +
          id_plataforma +
          "&id_programa=0&id_pessoa_aluno=" +
          id_pessoa_aluno
      )
        .then((obj) => {
          if (obj.length) {
            this.fastMatriculasAluno = obj;
            this.fastPessoaReadOnly = true
          }
          else {
            this.fastMatriculasAluno = [];
            this.fastPessoaReadOnly = false
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async gerarMatricula() {
      let erros = [];
      this.fastMatriculasAluno.forEach((e) => {
        if (
          e.id_programa_turma == this.fastPrograma.id_programa_turma &&
          e.ativo
        ) {
          erros.push("O aluno já possui uma matrícula ativa nesse módulo");
        }
      });

      if (!erros.length) {
        switch (this.fastTurma.nivel.trim()) {
          case "Infantil":
            this.fastMatriculaAluno.tipo_matricula = "I";
            break;
          case "Fundamental":
            this.fastMatriculaAluno.tipo_matricula = "F";
            break;
          case "Médio":
            this.fastMatriculaAluno.tipo_matricula = "M";
            break;
          case "Técnico":
            this.fastMatriculaAluno.tipo_matricula = "T";
            break;
          case "Superior":
            this.fastMatriculaAluno.tipo_matricula = "G";
            break;
          case "Pós-Graduação":
            this.fastMatriculaAluno.tipo_matricula = "P";
            break;
          case "Mestrado":
            this.fastMatriculaAluno.tipo_matricula = "M";
            break;
          case "Doutorado":
            this.fastMatriculaAluno.tipo_matricula = "D";
            break;
          default:
            break;
        }
        this.fastMatriculaAluno.id_pessoa_aluno = this.$route.params.id_pessoa_aluno;
        this.fastMatriculaAluno.id_programa_turma = this.fastPrograma.id_programa_turma;
        this.fastMatriculaAluno.ano_matricula = this.fastPrograma.ano;
        this.fastMatriculaAluno.pago = this.fastMatriculaAluno.pago
          ? true
          : false;
        this.fastMatriculaAluno.ativo = this.fastMatriculaAluno.ativo
          ? true
          : false;

        console.log("this.fastMatriculaAluno", this.fastMatriculaAluno);

        this.promiseInserirFastApi(
          this.fastMatriculaAluno,
          "fast_secretaria_matricula_aluno"
        )
          .then((res) => {
            console.log("gerarMatricula", res);
            if (res.length) {
              this.exibeToasty("Matrícula gerada com sucesso", "success");
              this.getFastMatriculaAluno(
                this.$route.params.id_plataforma,
                this.$route.params.id_pessoa_aluno
              );
              this.hideModal("modalMatricularAluno");
            } else {
              this.exibeToasty("Erro ao gerar matrícula", "error");
            }
          })
          .catch((e) => {
            this.exibeToasty("Erro ao gerar matrícula", "error");
          });
      } else {
        erros.forEach((e) => this.exibeToasty(e, "error"));
      }
    },
    exibeModalMatricularAlunoEditar(matricula) {
      this.fastTesourariaInvoice.parcelas = [];
      
      this.fastMatriculaAlunoEditar = matricula;

      console.log("this.fastMatriculaAlunoEditar", this.fastMatriculaAlunoEditar)

      this.fastMatriculaInvoice.parcelas = []
      if (matricula.id_invoice) {
        this.fastMatriculaInvoice.loading = true
        this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista", "id_invoice=" + matricula.id_invoice).then(res => { 
          if (res.length) {     
            this.fastMatriculaInvoice.parcelas = res;              
          } else {
            this.exibeToasty("Nenhuma parcela para a taxa de matrícula", "error");
          }      
          this.fastMatriculaInvoice.loading = false  
        }).catch(e => {
          console.log(e);
          this.exibeToasty("Erro ao recuperar parcelas", "error");
          this.fastMatriculaInvoice.loading = false 
        })                
      } else {
        this.fastMatriculaInvoice.loading = false 
      }
      // Templates de contrato
      this.getSecretariaContratosCurso(
        this.$route.params.id_plataforma,
        matricula.id_secretaria_curso
      );
      // Templates de inscrição
      this.getSecretariaInscricao(
        this.$route.params.id_plataforma,
        matricula.id_secretaria_curso
      );
      // UCs do curso
      this.promiseGetFastApi("api/fast_secretaria_matricula_aluno/lista_curso_ucs", "id_plataforma=" + this.$route.params.id_plataforma + "&id_matricula=" + this.fastMatriculaAlunoEditar.id_matricula)
      .then(res => {
        this.fastUcsMatricula.loading = false
        if (res.length) this.fastUcsMatricula.total = res
      })
      .then(() => {
        if (this.fastMatriculaAlunoEditar.matricula_por_programa) {
        this.promiseGetFastApi("api/fast_secretaria_matricula_aluno/lista_programa_ucs", "id_plataforma=" + this.$route.params.id_plataforma + "&id_matricula=" + this.fastMatriculaAlunoEditar.id_matricula)
          .then(res => {
            console.log("lista_programa_ucs", res)
            this.fastUcsMatriculaMarcadas.loading = false
            if (res.length) this.fastUcsMatriculaMarcadas.total = res
          })
          .catch(e => this.exibeToasty(e, "error"))
        } else if (this.fastMatriculaAlunoEditar.matricula_por_unidade) {
          this.promiseGetFastApi("api/fast_secretaria_matricula_aluno/lista_unidade_ucs", "id_plataforma=" + this.$route.params.id_plataforma + "&id_matricula=" + this.fastMatriculaAlunoEditar.id_matricula)
          .then(res => {
            console.log("lista_unidade_ucs", res)
            this.fastUcsMatriculaMarcadas.loading = false
            if (res.length) this.fastUcsMatriculaMarcadas.total = res
          })
          .catch(e => this.exibeToasty(e, "error"))
        } else {
          this.fastUcsMatriculaMarcadas.loading = false
          this.fastUcsMatriculaMarcadas.total = this.fastUcsMatricula.total
        }
      })
      .catch(e => this.exibeToasty(e, "error"))
      
      if (matricula.vencimento_matricula) {
        this.fastMatriculaAlunoEditar.vencimento_matricula = matricula.vencimento_matricula.split(
          "T"
        )[0];
      }
      if (matricula.data_pagto) {
        this.fastMatriculaAlunoEditar.data_pagto = matricula.data_pagto.split(
          "T"
        )[0];
      }
      this.$store.state.fastCarregando = true;

      // Informações de pagamento
      this.promiseGetFastApi(
        "api/fast_tesouraria_invoice/lista",
        "id_plataforma=" +
          this.$route.params.id_plataforma +
          "&id_matricula=" +
          this.fastMatriculaAlunoEditar.id_matricula
      )
      .then((json) => {
        console.log("api/fast_tesouraria_invoice/lista", json)
        if (json.length) {

          // Verifica se tem taxa de matrícula
          let index = json.length === 2 ? 1 : 0;
          json[index].parcelas = [];
          this.fastTesourariaInvoice = json[index];

          this.promiseGetFastApi(
            "api/fast_tesouraria_invoice_parcela/lista",
            "id_invoice=" + this.fastTesourariaInvoice.id_invoice
          )
            .then((json2) => {
              console.log("api/fast_tesouraria_invoice_parcela/lista", json2)
              if (json2.length) {
                this.fastTesourariaInvoice.parcelas = json2;
              } else {
                this.exibeToasty(
                  "Nenhuma parcela para esse invoice",
                  "error"
                );
              }
              this.$store.state.fastCarregando = false;
              this.showModal("modalMatricularAlunoEditar");
              console.log(
                "this.fastTesourariaInvoice",
                this.fastTesourariaInvoice
              );
            })
            // Comissão de professores
            .then(() => {
              if (this.fastTesourariaInvoice.id_invoice) {
                this.promiseGetFastApi("api/fast_plataforma_professor_comissao/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_invoice=" + this.fastTesourariaInvoice.id_invoice)
                .then(res => { 
                  console.log("api/fast_plataforma_professor_comissao/lista", res)
                  if (res.length) {     
                    this.fastProfessorComissao = res[0];              
                  }   
                }).catch(e => {
                  console.log(e);
                  this.exibeToasty("Erro ao recuperar dados da comissão dos professores", "error");
                })    
              }              
            })
            .catch((e) => {
              this.$store.state.fastCarregando = false;
              console.log(e);
              this.showModal("modalMatricularAlunoEditar");
              this.exibeToasty("Erro ao recuperar parcelas", "error");
            });
        } else {
          this.$store.state.fastCarregando = false;
          this.showModal("modalMatricularAlunoEditar");
          this.exibeToasty(
            "Nenhum registro invoice para essa matrícula",
            "error"
          );
        }
      })
      .catch((e) => {
        console.log(e);
        this.$store.state.fastCarregando = false;
        this.showModal("modalMatricularAlunoEditar");
        this.exibeToasty("Erro ao recuperar invoice", "error");
      });
    },
    exibeModalHistoricosECertificados(matricula, id_secretaria_curso) {
      this.showModal("modalHistoricosECertificados");
      this.fastIdMatricula = matricula;
      this.fastIdSecretariaCurso = id_secretaria_curso;
      console.log(this.fastIdSecretariaCurso)
      console.log(this.fastFiltroHistoricoCertificados[0].nome_curso)
    },
    gerarHistoricoEscolar(){
      this.$store.state.fastCarregando = true
      this.promiseGetFastApi("api/fast_secretaria_historico_escolar/gerar_historico_escolar", `id_secretaria_historico_escolar=${this.idTemplateHistoricoEscolhido}&id_plataforma=${this.$route.params.id_plataforma}&id_pessoa_aluno=${this.$route.params.id_pessoa_aluno}&id_matricula=${this.fastIdMatricula}`).then(json => {  
        let res = JSON.parse(json)
        if(res.url){
          window.open(res.url, '_blank');
          this.exibeToasty("Se não foi aberta aba/janela contendo o documento, verifique se os pop-ups estão bloqueados", "info")
          this.$store.state.fastCarregando = false
        } else {
          this.exibeToasty("Erro ao gerar documento", "error")
          this.$store.state.fastCarregando = false
        }
      }).catch((e) => {
        this.exibeToasty(e, "error")
        this.$store.state.fastCarregando = false
      });
    },
    async atualizarMatricula() {
      let matriculas = [];
      /*
      this.fastMatriculasAluno.forEach((e) => {
        if (
          e.id_programa_turma ==
            this.fastMatriculaAlunoEditar.id_programa_turma &&
          e.ativo
        ) {
          matriculas.push("O aluno já possui uma matrícula ativa nesse módulo");
        }
      });*/

      this.fastMatriculaAlunoEditar.pago = this.fastMatriculaAlunoEditar.pago
        ? true
        : false;
      this.fastMatriculaAlunoEditar.ativo = this.fastMatriculaAlunoEditar
        .ativo
        ? true
        : false;

      this.promiseAtualizarFastApi(
        this.fastMatriculaAlunoEditar,
        "fast_secretaria_matricula_aluno"
      )
        .then((res) => {
          console.log("promiseAtualizarFastApi", res);
          if (res.length) {
            this.exibeToasty("Alterações salvas com sucesso", "success");
            this.hideModal("modalMatricularAlunoEditar");
          } else {
            this.exibeToasty("Erro ao atualizar matrícula", "error");
          }
        })
        .catch((e) => {
          this.exibeToasty("Erro ao atualizar matrícula", "error");
        });
            
    },
    fileSuccessEditar(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      if (!result.error) {
        this.fastMatriculaAlunoEditar.path_contrato = result.url;
      }
    },
    fileSuccessEditarInscricao(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      if (!result.error) {
        this.fastMatriculaAlunoEditar.path_inscricao = result.url;
      }
    },
    retornaNivelMatricula(nivel) {
      console.log("retornaNivelMatricula", nivel);
      switch (nivel) {
        case "I":
          return "Infantil";
          break;
        case "F":
          return "Fundamental";
          break;
        case "M":
          return "Médio";
          break;
        case "T":
          return "Técnico";
          break;
        case "G":
          return "Superior";
          break;
        case "P":
          return "Pós-Graduação";
          break;
        case "M":
          return "Mestrado";
          break;
        case "D":
          return "Doutorado";
          break;
        default:
          break;
      }
    },
    async getFastPlataformaTurmas(id_plataforma) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_secretaria_turma/lista?id_plataforma=" +
            id_plataforma +
            "&id_turma=0",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          obj.forEach((e) => {
            e.ativo == "S" ? (e.ativo = true) : (e.ativo = false);
            this.filtroPlataformas.push(e.nome_plataforma);
            this.filtroTurnos.push(e.turno);
            this.filtroNiveis.push(e.nivel);
            this.filtroCursos.push(e.nome_curso);

            if (e.data_final) {
              if (
                new Date(e.data_atual.replaceAll("-", "/")) >
                new Date(e.data_final.split("T")[0].replaceAll("-", "/"))
              )
                console.log("Finalizado");
              else console.log("Não finalizado");
            }
          });
          // Remove valores duplicados do array
          this.filtroPlataformas = this.filtroPlataformas.filter(
            (a, b) => this.filtroPlataformas.indexOf(a) === b
          );
          this.filtroTurnos = this.filtroTurnos.filter(
            (a, b) => this.filtroTurnos.indexOf(a) === b
          );
          this.filtroNiveis = this.filtroNiveis.filter(
            (a, b) => this.filtroNiveis.indexOf(a) === b
          );
          this.filtroCursos = this.filtroCursos.filter(
            (a, b) => this.filtroCursos.indexOf(a) === b
          );
          this.filtroCursos.sort();

          this.fastPlataformaTurmaTotal = obj;
          this.fastPlataformaTurmaFiltro = obj;
        } else {
          this.fastPlataformaTurmaTotal = [];
          this.fastPlataformaTurmaFiltro = [];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    filtroSiglaTurma(e) {
      let text = e.target.value;
      this.fastPlataformaTurmaFiltro = this.fastPlataformaTurmaTotal.filter(
        (e) => {
          return (
            e.sigla_turma.toLowerCase().indexOf(text.toLowerCase()) != "-1"
          );
        }
      );
    },
    aplicaFiltroTurmas() {
      this.fastPlataformaTurmaFiltro = this.fastPlataformaTurmaTotal.filter(
        (e) => {
          if (this.filtroPlataformasEscolhido) {
            return (
              e.nome_plataforma
                .toLowerCase()
                .indexOf(this.filtroPlataformasEscolhido.toLowerCase()) != "-1"
            );
          } else {
            return true;
          }
        }
      );

      this.fastPlataformaTurmaFiltro = this.fastPlataformaTurmaFiltro.filter(
        (e) => {
          if (this.filtroCursoEscolhido) {
            return (
              e.nome_curso
                .toLowerCase()
                .indexOf(this.filtroCursoEscolhido.toLowerCase()) != "-1"
            );
          } else {
            return true;
          }
        }
      );

      this.fastPlataformaTurmaFiltro = this.fastPlataformaTurmaFiltro.filter(
        (e) => {
          if (this.filtroTurnosEscolhido) {
            return e.turno == this.filtroTurnosEscolhido;
          } else {
            return true;
          }
        }
      );

      this.fastPlataformaTurmaFiltro = this.fastPlataformaTurmaFiltro.filter(
        (e) => {
          if (this.filtroNivelEscolhido) {
            return e.nivel == this.filtroNivelEscolhido;
          } else {
            return true;
          }
        }
      );

      this.fastPlataformaTurmaFiltro = this.fastPlataformaTurmaFiltro.filter(
        (e) => {
          if (this.filtroCursoEscolhido) {
            return (
              e.nome_curso
                .toLowerCase()
                .indexOf(this.filtroCursoEscolhido.toLowerCase()) != "-1"
            );
          } else {
            return true;
          }
        }
      );
    },
    async getFastProgramas(turma) {
      this.getSecretariaContratosCurso(
        turma.id_plataforma,
        turma.id_secretaria_curso
      );
      this.fastTurma = {
        ativo: turma.ativo,
        data_modifica: turma.data_modifica,
        data_registro: turma.data_registro,
        descricao_turma: turma.descricao_turma,
        etapa: turma.etapa,
        id_plataforma: turma.id_plataforma,
        id_turma: turma.id_turma,
        id_usuario: turma.id_usuario,
        id_usuario_modifica: turma.id_usuario_modifica,
        lotacao: turma.lotacao,
        nivel: turma.nivel,
        nome_plataforma: turma.nome_plataforma,
        sigla_turma: turma.sigla_turma,
        turno: turma.turno,
        id_secretaria_curso: turma.id_secretaria_curso,
        programas: [],
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_secretaria_turma_programa/lista?id_plataforma=" +
            this.$route.params.id_plataforma +
            "&id_turma=" +
            turma.id_turma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        console.log("getFastProgramas", obj);
        if (obj.length) {
          obj.forEach((e) => {
            if (e.data_inicio) e.data_inicio = e.data_inicio.split("T")[0];
            if (e.data_final) e.data_final = e.data_final.split("T")[0];
            if (e.data_inicio_ferias)
              e.data_inicio_ferias = e.data_inicio_ferias.split("T")[0];
            if (e.data_fim_ferias)
              e.data_fim_ferias = e.data_fim_ferias.split("T")[0];
          });
          this.fastTurma.programas = obj;
        }
        console.log("this.fastTurma", this.fastTurma);
        this.showModal(
          "modalPlataformaTurmaProgramas",
          "modalPlataformaTurmas"
        );
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
      }
    },
    selecionaProgramaMatricula(programa) {
      // Evita matrícula no mesmo módulo
      let erros = [];
      this.fastMatriculasAluno.forEach((e) => {
        if (e.id_programa_turma == programa.id_programa_turma && e.ativo) {
          erros.push("O aluno já possui uma matrícula ativa nesse módulo");
        }
      });

      if (!erros.length) {
        this.fastPrograma = programa;
        console.log("this.fastPrograma", this.fastPrograma);
        this.showModal("modalMatricularAluno", "modalPlataformaTurmaProgramas");
      } else {
        erros.forEach((e) => this.exibeToasty(e, "error"));
      }
    },
    async excluirMatriculaAluno() {
      this.promiseExcluirFastApi(
        this.fastMatriculaAlunoEditar,
        "fast_secretaria_matricula_aluno"
      )
        .then((res) => {
          this.exibeToasty("Matrícula excluída com sucesso", "success");
          this.getFastMatriculaAluno(
            this.$route.params.id_plataforma,
            this.$route.params.id_pessoa_aluno
          );
          this.hideModal("modalExcluirMatriculaAluno");
        })
        .catch((e) => {
          this.exibeToasty("Erro ao excluir matrícula", "error");
        });
    },
    retornaCursoNivel(nivel) {
      switch (nivel) {
        case "F":
          return "Ensino fundamental";
          break;
        case "E":
          return "Ensino médio";
          break;
        case "J":
          return "EJA";
          break;
        case "T":
          return "Técnico";
          break;
        case "S":
          return "Ensino superior";
          break;
        case "P":
          return "Pós-graduação";
          break;
        case "M":
          return "Mestrado";
          break;
        case "D":
          return "Doutorado";
          break;
        default:
          break;
      }
    },
    validaDadosMatricula() {
      let erros = [];
      if (!this.$store.state.fastPessoa.first_name)
        erros.push(
          "O nome do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.last_name)
        erros.push(
          "O sobrenome do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.cpf_cnpj)
        erros.push(
          "O CPF/CNPJ do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.logradouro)
        erros.push(
          "O endereço do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.numero)
        erros.push(
          "O número do endereço do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.bairro)
        erros.push(
          "O bairro do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.cep)
        erros.push(
          "O cep do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.cidade)
        erros.push(
          "O cidade do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.uf)
        erros.push(
          "O estado do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.telefone)
        erros.push(
          "O telefone do aluno não foi cadastrado. Verifique os dados pessoais."
        );
      if (!this.$store.state.fastPessoa.datanascimento_abertura)
        erros.push(
          "A data de nascimento do aluno não foi cadastrada. Verifique os dados pessoais."
        );

      if (erros.length) {
        erros.forEach((e) => this.exibeToasty(e, "error"));
      } else {
        window.location.href =
          "/plataforma/" +
          this.$route.params.id_plataforma +
          "/secretaria/matricula/" +
          this.$route.params.id_pessoa_aluno;
      }
    },
    exibeModalWhatsApp(boleto){
      this.fastLinkWhats.path_boleto = boleto.path_boleto
      this.fastLinkWhats.parcela = boleto.parcela
      this.fastLinkWhats.valor_parcela_total = boleto.valor_parcela_total
      this.fastLinkWhats.data_vencimento = boleto.data_vencimento
      this.fastLinkWhats.nome_plataforma = boleto.nome_plataforma
      this.fastLinkWhats.tipo = boleto.tipo
      this.fastLinkWhats.telefone_aluno = this.$store.state.fastPessoa.telefone

      console.log("boleto", boleto)

      this.showModal('modalWhatsApp', 'modalMatricularAlunoEditar')
    },
    retornaLinkWhats(telefone, path_boleto, parcela, valor_parcela_total, data_vencimento, nome_plataforma, tipo){
      let link = ""
      let tel = telefone
      if (tel) {
        tel = telefone.replaceAll("-", "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "")
        tel = `55${tel}`
        if (path_boleto) {
          let text = path_boleto
          if (parcela && valor_parcela_total && data_vencimento && nome_plataforma && tipo) {
            let referente = ""
            if (tipo == "I") {
              referente = "à cobrança"
            } else if (tipo == "M"){
              referente = "à taxa de matrícula"
            } else if (tipo == "C"){
              referente = "ao curso definido"
            } else {
              referente = "à cobrança"
            }
            text = `Olá, segue a parcela ${parcela} do carnê no valor de R$ ${this.formataPreco(valor_parcela_total)}. Cobrança referente ${referente}, emitida por ${nome_plataforma}, com vencimento para ${this.formataDataT(data_vencimento)}. Acesse o boleto pelo link: ${path_boleto}`
          }
          link = `https://api.whatsapp.com/send?phone=${tel}&text=${text}`

        } else {
          //this.exibeToasty("Boleto não cadastrado", "error")
        }
      } else {
        //this.exibeToasty("Telefone não registrado", "error")
      }
      return link
    },
    // Contrato
    getSecretariaContratosCurso(id_plataforma, id_secretaria_curso) {
      this.promiseGetFastApi(
        "api/fast_secretaria_contrato/lista_curso",
        "id_plataforma=" +
          id_plataforma +
          "&id_secretaria_curso=" +
          id_secretaria_curso
      )
        .then((obj) => {
          console.log("getSecretariaContratos", obj);
          if (obj.length) {
            this.fastSecretariaContratosCurso = obj;
            this.fastContratoEscolhido.id_contrato = obj[0].id_contrato;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    geraPdfContrato() {
      this.$store.state.fastCarregando = true;
      if (!this.fastContratoEscolhido.id_contrato) {
        this.exibeToasty("Selecione um template de contrato", "error");
      } else {
        this.fastSecretariaContratosCurso.forEach((c) => {
          if (c.id_contrato == this.fastContratoEscolhido.id_contrato) {
            this.fastContratoEscolhido.html_template = c.html_template;
          }
        });

        if (!this.fastContratoEscolhido.html_template) {
          this.exibeToasty("Erro ao buscar template de contrato", "error");
          this.$store.state.fastCarregando = false
        }
        else {
          let contrato = this.fastContratoEscolhido.html_template;
          let erros = [];

          let id_pessoa_responsavel = this.fastPlataformaAlunoResponsaveis.map(r => {
            if (r.responsavel_financeiro) return r.id_pessoa_responsavel
          })

          // Se não tem responsável o aluno será
          if (!id_pessoa_responsavel.length) {
            id_pessoa_responsavel[0] = this.$store.state.fastPessoa.id_pessoa
          }

          if (!id_pessoa_responsavel.length) {
            this.exibeToasty("Nenhum responsável financeiro identificado", "error");
          } else {
            if (this.fastContratoEscolhido.html_template.indexOf("%nome_aluno%") != "-1" && !this.$store.state.fastPessoa.first_name) erros.push("O nome do aluno não foi cadastrado")
            if (this.fastContratoEscolhido.html_template.indexOf("%nome_aluno%") != "-1" && !this.$store.state.fastPessoa.last_name) erros.push("O sobrenome do aluno não foi cadastrado")
            if (this.fastContratoEscolhido.html_template.indexOf("%cpf_aluno%") != "-1" && !this.$store.state.fastPessoa.cpf_cnpj) erros.push("O CPF/CNPJ do aluno não foi cadastrado")
            if (this.fastContratoEscolhido.html_template.indexOf("%logradouro_aluno%") != "-1" && !this.$store.state.fastPessoa.logradouro) erros.push("O endereço do aluno não foi cadastrado")
            if (this.fastContratoEscolhido.html_template.indexOf("%numero_aluno%") != "-1" && !this.$store.state.fastPessoa.numero) erros.push("O número do endereço do aluno não foi cadastrado")
            if (this.fastContratoEscolhido.html_template.indexOf("%bairro_aluno%") != "-1" && !this.$store.state.fastPessoa.bairro) erros.push("O bairro do aluno não foi cadastrado")
            if (this.fastContratoEscolhido.html_template.indexOf("%cep_aluno%") != "-1" && !this.$store.state.fastPessoa.cep) erros.push("O cep do aluno não foi cadastrado")
            if (this.fastContratoEscolhido.html_template.indexOf("%cidade_aluno%") != "-1" && !this.$store.state.fastPessoa.cidade) erros.push("O cidade do aluno não foi cadastrado")
            if (this.fastContratoEscolhido.html_template.indexOf("%uf_aluno%") != "-1" && !this.$store.state.fastPessoa.uf) erros.push("O estado do aluno não foi cadastrado")

            if (erros.length) {
            erros.forEach(e => this.exibeToasty(e, "error"))
            this.$store.state.fastCarregando = false
          } else {
            this.promiseGetFastApi("api/fast_plataforma_usuario/busca_pessoa_secretaria", "id_pessoa=" + id_pessoa_responsavel[0] + "&id_plataforma=" + this.$route.params.id_plataforma).then(obj => {       
              let objPessoa = JSON.parse(obj)
              if (objPessoa.id_pessoa) {    
                  if (this.fastContratoEscolhido.html_template.indexOf("%nome_responsavel%") != "-1" && !objPessoa.first_name) erros.push("O nome do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%nome_responsavel%") != "-1" && !objPessoa.last_name) erros.push("O sobrenome do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%cpf_responsavel%") != "-1" && !objPessoa.cpf_cnpj) erros.push("O CPF/CNPJ do aluno responsavel foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%logradouro_responsavel%") != "-1" && !objPessoa.logradouro) erros.push("O endereço do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%numero_responsavel%") != "-1" && !objPessoa.numero) erros.push("O número do endereço do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%bairro_responsavel%") != "-1" && !objPessoa.bairro) erros.push("O bairro do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%cep_responsavel%") != "-1" && !objPessoa.cep) erros.push("O cep do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%cidade_responsavel%") != "-1" && !objPessoa.cidade) erros.push("O cidade do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%uf_responsavel%") != "-1" && !objPessoa.uf) erros.push("O estado do responsavel não foi cadastrado")

                  if (!erros.length) {
                    contrato = contrato.replaceAll(
                    "%nome_aluno%",
                      this.$store.state.fastPessoa.first_name.trim() + " " + this.$store.state.fastPessoa.last_name.trim()
                    );
                    contrato = contrato.replaceAll(
                    "%nome_responsavel%",
                      objPessoa.first_name.trim() + " " + objPessoa.last_name.trim()
                    );
                    contrato = contrato.replaceAll(
                      "%cpf_aluno%",
                      this.$store.state.fastPessoa.cpf_cnpj.trim()
                    );
                    contrato = contrato.replaceAll(
                      "%cpf_responsavel%",
                      objPessoa.cpf_cnpj.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%logradouro_aluno%",
                      this.$store.state.fastPessoa.logradouro.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%logradouro_responsavel%",
                      objPessoa.logradouro.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%numero_aluno%",
                      this.$store.state.fastPessoa.numero.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%numero_responsavel%",
                      objPessoa.numero.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%bairro_aluno%",
                      this.$store.state.fastPessoa.bairro.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%bairro_responsavel%",
                      objPessoa.bairro.trim() 
                    );                
                    contrato = contrato.replaceAll(
                      "%cep_aluno%",
                      this.$store.state.fastPessoa.cep.trim().replaceAll("-","")
                    );                    
                    contrato = contrato.replaceAll(
                      "%cep_responsavel%",
                      objPessoa.cep.trim().replaceAll("-","")
                    );
                    contrato = contrato.replaceAll(
                      "%cidade_aluno%",
                      this.$store.state.fastPessoa.cidade.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%cidade_responsavel%",
                      objPessoa.cidade.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%uf_aluno%",
                      this.$store.state.fastPessoa.uf.trim()
                    );
                    contrato = contrato.replaceAll(
                      "%uf_responsavel%",
                      objPessoa.uf.trim()
                    );
                    contrato = contrato.replaceAll(
                      "%complemento_aluno%",
                      this.$store.state.fastPessoa.complemento.trim()
                    );
                    contrato = contrato.replaceAll(
                      "%complemento_responsavel%",
                      objPessoa.complemento.trim()
                    );

                    // Valores
                    let valor_total = 0
                    valor_total += this.fastMatriculaAlunoEditar.valor_matricula ? parseFloat(this.fastMatriculaAlunoEditar.valor_matricula) : 0
                    valor_total += this.fastTesourariaInvoice.total ? this.fastTesourariaInvoice.total : 0

                    //https://github.com/portujs/extenso.js
                    let extenso = require('extenso')

                    // Valor total = matricula + curso
                    valor_total = valor_total.toFixed(2)
                    let valor_total_extenso = extenso(valor_total.replace(".", ","), { mode: 'currency', currency: { type: 'BRL' }, locale: 'br', number: { decimalSeparator: 'comma' } })
                    contrato = contrato.replaceAll("%valor_total%", `R$ ${this.formataPreco(valor_total)} (${valor_total_extenso})`);

                    // Valor da matrícula
                    let valor_matricula = this.fastMatriculaAlunoEditar.valor_matricula ? parseFloat(this.fastMatriculaAlunoEditar.valor_matricula) : 0
                    valor_matricula = valor_matricula.toFixed(2)
                    let valor_matricula_extenso = extenso(valor_matricula.replace(".", ","), { mode: 'currency', currency: { type: 'BRL' }, locale: 'br', number: { decimalSeparator: 'comma' } })
                    contrato = contrato.replaceAll("%valor_matricula%", `R$ ${this.formataPreco(valor_matricula)} (${valor_matricula_extenso})`);

                    // Valor total do curso = soma todas as parcelas
                    let valor_total_curso = 0;
                    if (this.fastTesourariaInvoice.parcelas.length) {
                      valor_total_curso = this.fastTesourariaInvoice.parcelas.map(o=>o.valor_parcela).reduce((a,c)=>a+c);
                    }
                    valor_total_curso = valor_total_curso.toFixed(2)
                    let valor_total_curso_extenso = extenso(valor_total_curso.replace(".", ","), { mode: 'currency', currency: { type: 'BRL' }, locale: 'br', number: { decimalSeparator: 'comma' } })
                    contrato = contrato.replaceAll("%valor_total_curso%", `R$ ${this.formataPreco(valor_total_curso)} (${valor_total_curso_extenso})`);

                    // Número de parcelas
                    let numero_parcelas = this.fastTesourariaInvoice.parcelas.length;
                    contrato = contrato.replaceAll("%numero_parcelas%", `${numero_parcelas}`);

                    // Valor da parcela
                    let valor_parcela = 0
                    if (this.fastTesourariaInvoice.parcelas.length) {
                      valor_parcela = this.fastTesourariaInvoice.parcelas[this.fastTesourariaInvoice.parcelas.length - 1].valor_parcela                      
                    }
                    valor_parcela = valor_parcela.toFixed(2)
                    let valor_parcela_extenso = extenso(valor_parcela.replace(".", ","), { mode: 'currency', currency: { type: 'BRL' }, locale: 'br', number: { decimalSeparator: 'comma' } })
                    contrato = contrato.replaceAll("%valor_parcela%", `R$ ${this.formataPreco(valor_parcela)} (${valor_parcela_extenso})`);

                    // Dia da parcela
                    let dia_parcela = ""
                    if (this.fastTesourariaInvoice.parcelas.length) {
                      if (this.fastTesourariaInvoice.parcelas[0].data_vencimento) dia_parcela = this.fastTesourariaInvoice.parcelas[0].data_vencimento.split("T")[0].split("-")[2]
                    }
                    contrato = contrato.replaceAll(
                      "%dia_parcela%",
                      dia_parcela.trim()
                    );
                    
                    // Mês da parcela
                    let mes_parcela = ""
                    if (this.fastTesourariaInvoice.parcelas.length) {
                      if (this.fastTesourariaInvoice.parcelas[0].data_vencimento) mes_parcela = this.fastTesourariaInvoice.parcelas[0].data_vencimento.split("-")[1]
                    }
                    contrato = contrato.replaceAll(
                      "%mes_primeira_parcela%",
                      mes_parcela.trim()
                    );
                    // Ano da parcela
                    let ano_parcela = ""
                    if (this.fastTesourariaInvoice.parcelas.length) {
                      if (this.fastTesourariaInvoice.parcelas[0].data_vencimento) ano_parcela = this.fastTesourariaInvoice.parcelas[0].data_vencimento.split("T")[0].split("-")[0]
                    }
                    contrato = contrato.replaceAll(
                      "%ano_primeira_parcela%",
                      ano_parcela.trim()
                    );

                    // Gera pdf
                    this.fastContratoEscolhido.html_template = contrato;
                    this.$refs.html2Pdf.generatePdf();

                    this.$store.state.fastCarregando = false;
                  } else {                    
                    erros.forEach(e => this.exibeToasty(e, "error"))
                    this.$store.state.fastCarregando = false;
                  }  
              } else {
                this.exibeToasty("Erro ao buscar dados do responsável", "error");
                this.$store.state.fastCarregando = false;
              }
            }).catch(e => {
              console.log(e);
              this.exibeToasty("Erro ao buscar dados do responsável", "error");
              this.$store.state.fastCarregando = false;
            })
          }      
          }   
        }
        
      }
    },
    fileSuccessemail(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      if (!result.error) {
        let fast_secretaria_contrato_aluno = {
          id_usuario_logado: this.$store.state.fastPessoa.id_usuario,
          id_plataforma: this.$route.params.id_plataforma,
          email_recuperacao: this.$store.state.fastPessoa.email,
          path_contrato: result.url
        }

        console.log("fast_secretaria_contrato_aluno", fast_secretaria_contrato_aluno)

        this.$store.state.fastCarregando = true;
        // Controller fast_secretaria_contrato
        this.promiseInserirFastApi(fast_secretaria_contrato_aluno, "fast_secretaria_contrato_aluno").then((res) => {
          if (res == "email enviado") {
            this.exibeToasty("email enviado com sucesso", "success");
            this.hideModal("modalEnviarContratoemail")
          } else {
            this.exibeToasty(res, "error");
          }
          this.$store.state.fastCarregando = false;
        })
        .catch(e => {
          this.exibeToasty(e, "error")
          this.$store.state.fastCarregando = false;
        });
      }
    }, 
    // Requerimentos
    async getTipoRequerimentos(id_plataforma) {
        this.promiseGetFastApi("api/fast_secretaria_tipo_requerimento/lista", "id_plataforma=" + id_plataforma)
        .then(tipoRequerimentos => { 
            this.fastTipoRequerimentos = tipoRequerimentos
        })
        .catch(e => this.exibeToasty(e, "error"))
    },
    async getRequerimentos(id_plataforma, id_pessoa_aluno) {      
      this.promiseGetFastApi("api/fast_secretaria_requerimento/lista", "id_plataforma=" + id_plataforma + "&id_pessoa_aluno=" + id_pessoa_aluno)
      .then(fastRequerimentos => { 
        if (fastRequerimentos.length) this.fastRequerimentos = fastRequerimentos
        else
        this.fastRequerimentos = []
      })
      .catch(e => this.exibeToasty(e, "error"))
    },
    validaTipoRequerimento(e) {
      if (e.target.value) {
        this.fastTipoRequerimentos.forEach(t => {
          if (t.id_tipo_requerimento == e.target.value) {
            this.fastRequerimentoNovo.valor_requerimento = t.preco_servico
            this.fastRequerimentoNovo.id_tipo_requerimento = t.id_tipo_requerimento
            this.fastRequerimentoNovo.nome_requerimento = t.nome_requerimento
            this.fastRequerimentoNovo.aceita_anexo = t.aceita_anexo
            this.fastRequerimentoNovo.id_item_cobranca = t.id_item_cobranca
          }
        })        

        this.showModal('modalCriarRequerimento', 'modalSelecionaTipoRequerimento')
      } else {
        this.exibeToasty("Selecione um tipo de requerimento", "error")
      }
    },
    fileSuccessRequerimento(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      if (!result.error) {
        this.fastRequerimentoNovo.path_anexo = result.url;
      }
    },
    async criarRequerimento() {      
      let erros = []
      this.fastRequerimentoNovo.id_plataforma = this.$route.params.id_plataforma
      this.fastRequerimentoNovo.id_pessoa_aluno = this.$route.params.id_pessoa_aluno
      
      if (!this.fastRequerimentoNovo.id_tipo_requerimento) erros.push("O tipo de requerimento é obrigatório")
      if (!this.fastRequerimentoNovo.detalhe_requerimento) erros.push("Campo obrigatório: Detalhes do requerimento")

      if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
          this.$store.state.fastCarregando = true;
          this.promiseInserirFastApi(this.fastRequerimentoNovo, "fast_secretaria_requerimento").then((res) => {
              this.exibeToasty("Requerimento criado com sucesso", "success");
              this.hideModal("modalCriarRequerimento");
              this.fastRequerimentos = res;
              this.fastRequerimentoNovo = {
                id_registro_requerimento: 0,
                id_plataforma: 0,
                id_tipo_requerimento: 0,
                id_pessoa_aluno: 0,
                detalhe_requerimento: "",
                valor_requerimento: 0,
                taxa: 0,
                desconto: 0,
                total_requerimento: 0,
                data_registro: "",
                data_inicio_analise: null,
                data_fim_analise: null,
                solicitado: null,
                em_analise: null,
                aprovada: null,
                data_aprovacao: null,
                path_anexo: "",
                id_usuario_aprovador: 0,
                obs_aprovador: null,
                pago: null,
                data_pagto: null,
                path_boleto: null,
                data_entrega: null,
                id_usuario_registro: 0,
                data_rejeicao: null,
                boleto_gerado: null,
                nome_requerimento: "",
                aceita_anexo: false    
              }
              this.$store.state.fastCarregando = false;
          }).catch((e) => {
              this.exibeToasty("Erro ao criar requerimento", "error");
              this.$store.state.fastCarregando = false;
          });
      }      
    },
    async criarRequerimentoPago() {      
      let erros = []
      this.fastRequerimentoNovo.id_plataforma = this.$route.params.id_plataforma
      this.fastRequerimentoNovo.id_pessoa_aluno = this.$route.params.id_pessoa_aluno
      
      if (!this.fastRequerimentoNovo.id_tipo_requerimento) erros.push("O tipo de requerimento é obrigatório")
      if (!this.fastRequerimentoNovo.detalhe_requerimento) erros.push("Campo obrigatório: Detalhes do requerimento")

      if (!this.fastRequerimentoNovo.id_tipo_requerimento) erros.push("O tipo de requerimento é obrigatório")

      if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
          this.$store.state.fastCarregando = true;
          this.promisePostFastApi(this.fastRequerimentoNovo, "api/fast_secretaria_requerimento/insere_invoice").then((res) => {
              this.exibeToasty("Requerimento criado com sucesso", "success");
              this.hideModal("modalCriarRequerimento");
              this.fastRequerimentos = res;
              this.fastRequerimentoNovo = {
                id_registro_requerimento: 0,
                id_plataforma: 0,
                id_tipo_requerimento: 0,
                id_pessoa_aluno: 0,
                detalhe_requerimento: "",
                valor_requerimento: 0,
                taxa: 0,
                desconto: 0,
                total_requerimento: 0,
                data_registro: "",
                data_inicio_analise: null,
                data_fim_analise: null,
                solicitado: null,
                em_analise: null,
                aprovada: null,
                data_aprovacao: null,
                path_anexo: "",
                id_usuario_aprovador: 0,
                obs_aprovador: null,
                pago: null,
                data_pagto: null,
                path_boleto: null,
                data_entrega: null,
                id_usuario_registro: 0,
                data_rejeicao: null,
                boleto_gerado: null,
                nome_requerimento: "",
                aceita_anexo: false    
              }
              this.$store.state.fastCarregando = false;
          }).catch((e) => {
              this.exibeToasty("Erro ao criar requerimento", "error");
              this.$store.state.fastCarregando = false;
          });
      }      
    },
    fileSuccessRequerimentoEditar(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      if (!result.error) {
        this.fastRequerimentoEditar.path_anexo = result.url;
      }
    },
    exibeModalEditarRequerimento(requerimento) {      

      this.fastTipoRequerimentos.forEach(t => {
        if (t.id_tipo_requerimento == requerimento.id_tipo_requerimento) {
          requerimento.nome_requerimento = t.nome_requerimento
          requerimento.aceita_anexo = t.aceita_anexo
          requerimento.id_item_cobranca = t.id_item_cobranca
        }
      }) 

      // Não copia por referência
      this.fastRequerimentoEditar = Object.assign({}, requerimento);

      if (this.fastRequerimentoEditar.data_inicio_analise) this.fastRequerimentoEditar.data_inicio_analise = this.fastRequerimentoEditar.data_inicio_analise.split("T")[0]
      if (this.fastRequerimentoEditar.data_fim_analise) this.fastRequerimentoEditar.data_fim_analise = this.fastRequerimentoEditar.data_fim_analise.split("T")[0]
      this.showModal('modalEditarRequerimento')
    },
    async atualizarRequerimento() {
       let erros = []
      this.fastRequerimentoEditar.id_plataforma = this.$route.params.id_plataforma
      this.fastRequerimentoEditar.id_pessoa_aluno = this.$route.params.id_pessoa_aluno
      
      if (!this.fastRequerimentoEditar.id_tipo_requerimento) erros.push("O tipo de requerimento é obrigatório")
      if (!this.fastRequerimentoEditar.detalhe_requerimento) erros.push("Campo obrigatório: Detalhes do requerimento")

      if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
          this.$store.state.fastCarregando = true;
          this.promisePostFastApi(this.fastRequerimentoEditar, "api/fast_secretaria_requerimento/atualiza").then((res) => {
              this.exibeToasty("Requerimento atualizado com sucesso", "success");
              this.hideModal("modalEditarRequerimento");
              this.fastRequerimentos = res;              
              this.$store.state.fastCarregando = false;
          }).catch((e) => {
              this.exibeToasty("Erro ao atualizar requerimento", "error");
              this.$store.state.fastCarregando = false;
          });
      }      
    },
    // Ocorrências
    async getTipoOcorrencias(id_plataforma) {
        this.promiseGetFastApi("api/fast_secretaria_tipo_ocorrencia/lista", "id_plataforma=" + id_plataforma)
        .then(res => {           
            this.fastTipoOcorrencias = res
        })
        .catch(e => this.exibeToasty(e, "error"))
    },
    async getFastOcorrencias(id_pessoa_aluno) {      
      this.promiseGetFastApi("api/fast_secretaria_ocorrencia/lista", "id_pessoa_aluno=" + id_pessoa_aluno)
      .then(res => { 
        if (res.length) this.fastOcorrencias = res
        else
        this.fastOcorrencias = []
      })
      .catch(e => this.exibeToasty(e, "error"))
    },
    validaTipoOcorrencia(e) {
      if (e.target.value) {
        this.fastTipoOcorrencias.forEach(t => {
          if (t.id_tipo_ocorrencia == e.target.value) {
            this.fastOcorrenciaNovo.id_tipo_ocorrencia = t.id_tipo_ocorrencia
            this.fastOcorrenciaNovo.nome_ocorrencia = t.nome_ocorrencia
            this.fastOcorrenciaNovo.aceita_anexo = t.aceita_anexo
          }
        })        

        this.showModal('modalCriarOcorrencia', 'modalSelecionaTipoOcorrencia')
      } else {
        this.exibeToasty("Selecione um tipo de ocorrência", "error")
      }
    },
    fileSuccessOcorrencia(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      if (!result.error) {
        this.fastOcorrenciaNovo.path_anexo = result.url;
      }
    },
    async criarOcorrencia() {      
      let erros = []
      this.fastOcorrenciaNovo.id_pessoa_aluno = this.$route.params.id_pessoa_aluno
      
      if (!this.fastOcorrenciaNovo.id_tipo_ocorrencia) erros.push("O tipo de ocorrência é obrigatório")
      if (!this.fastOcorrenciaNovo.detalhe_ocorrencia) erros.push("Campo obrigatório: Detalhes da ocorrência")

      if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
          const model = {
            fast_secretaria_ocorrencia: this.fastOcorrenciaNovo,
            fast_secretaria_tipo_ocorrencia: {
              id_plataforma: this.$route.params.id_plataforma
            }
          }
          this.$store.state.fastCarregando = true;
          this.promiseInserirFastApi(model, "fast_secretaria_ocorrencia").then((res) => {
              this.exibeToasty("Ocorrência criada com sucesso", "success");
              this.hideModal("modalCriarOcorrencia");
              this.fastOcorrencias = res;
              this.fastOcorrenciaNovo = {
                id_registro_ocorrencia: 0,
                id_tipo_ocorrencia: 0,
                id_pessoa_aluno: 0,
                data_registro: undefined,
                detalhe_ocorrencia: "",
                path_anexo: "",
                responsavel_notificado: false,
                data_notificacao: null,
                id_usuario_registro: 0,
                nome_ocorrencia: "",
                aceita_anexo: true   
              }
              this.$store.state.fastCarregando = false;
          }).catch((e) => {
              this.exibeToasty("Erro ao criar ocorrência", "error");
              this.$store.state.fastCarregando = false;
          });
      }      
    },
    fileSuccessOcorrenciaEditar(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      if (!result.error) {
        this.fastOcorrenciaEditar.path_anexo = result.url;
      }
    },
    exibeModalEditarOcorrencia(ocorrencia) {
      this.fastTipoOcorrencias.forEach(t => {
          if (t.id_tipo_ocorrencia == ocorrencia.id_tipo_ocorrencia) {
            ocorrencia.nome_ocorrencia = t.nome_ocorrencia
            ocorrencia.aceita_anexo = t.aceita_anexo
          }
        }) 

      // Não copia por referência
      this.fastOcorrenciaEditar = Object.assign({}, ocorrencia);

      this.showModal('modalEditarOcorrencia')
    },
    async atualizarOcorrencia() {
       let erros = []
      this.fastOcorrenciaEditar.id_pessoa_aluno = this.$route.params.id_pessoa_aluno
      
      if (!this.fastOcorrenciaEditar.id_tipo_ocorrencia) erros.push("O tipo de ocorrência é obrigatório")
      if (!this.fastOcorrenciaEditar.detalhe_ocorrencia) erros.push("Campo obrigatório: Detalhes da ocorrência")

      if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
          this.$store.state.fastCarregando = true;
          this.promisePostFastApi(this.fastOcorrenciaEditar, "api/fast_secretaria_ocorrencia/atualiza").then((res) => {
              this.exibeToasty("Ocorrência atualizada com sucesso", "success");
              this.hideModal("modalEditarOcorrencia");
              this.fastOcorrencias = res;              
              this.$store.state.fastCarregando = false;
          }).catch((e) => {
              this.exibeToasty("Erro ao atualizar ocorrência", "error");
              this.$store.state.fastCarregando = false;
          });
      }      
    },
    // Template de inscrição
    getSecretariaInscricao(id_plataforma, id_secretaria_curso) {       
      this.fastInscricoes = []       
        this.fastInscricoes.push({
          id_inscricao_template: 1,
          nome_inscricao_template: "Template de inscrição",
          html_template: `
          <div class="col-12">
            <div class="row">
              <div class="col-6">
                %logo_plataforma%                
              </div>
              <div class="col-6 text-right">
                <h5 class="text-info d-none">Educação de Jovens e Adultos</h5>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12 text-center">
                <h5 class="text-info">Edital ${new Date().getFullYear()}</h5>
              </div>
              <div class="col-12 text-center mb-2">
                <p>REQUERIMENTO DE INSCRIÇÃO</p>
              </div>
              <div class="col-12">
                <label>Nome:</label> %nome_aluno%
              </div>
              <div class="col-6">
                <label>Local de nascimento:</label> _________________________
              </div>
              <div class="col-2">
                <label>UF:</label> %uf_aluno%
              </div>
              <div class="col-4">
                <label>Data de nascimento:</label> %datanascimento_abertura_aluno%
              </div>
              <div class="col-5">
                <label>Nacionalidade:</label> (  ) &nbsp;Brasileira (  ) &nbsp;Estrangeira
              </div>
              <div class="col-3">
                <label>RG:</label> _________________________
              </div>
              <div class="col-4">
                <label>CPF:</label> %cpf_aluno%
              </div>
              <div class="col-6">
                <label>End:</label> %logradouro_aluno%
              </div>
              <div class="col-2">
                <label>nº:</label> %numero_aluno%
              </div>
              <div class="col-4">
                <label>CEP:</label> %cep_aluno%
              </div>
              <div class="col-4">
                <label>Cidade:</label> %cidade_aluno%
              </div>
              <div class="col-4">
                <label>Bairro:</label> %bairro_aluno%
              </div>
              <div class="col-4">
                <label>Complemento:</label> %complemento_aluno%
              </div>
              <div class="col-12">
                <label>Telefone:</label> %telefone_aluno%
              </div>
              <div class="col-4">
                <label>Aluno EJA:</label> (  ) &nbsp;Sim (  ) &nbsp;Não
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 text-center mb-4">
                <h6>DISCIPLINAS DE INSCRIÇÃO</h6>
              </div>              
              <div class="col-12 table-responsive">
                <table class="table table-sm table-bordered">
                  <tr class="text-center">
                    <th colspan="2" class="pt-0 pb-0">Nível %turma_nivel%</th>
                  </tr>
                  <tr class="text-center">
                    <th colspan="2" class="pt-0 pb-0">DISCIPLINA</th>
                  </tr>
                  %lista_ucs_curso%                  
                </table>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <p>Venho requerer minha inscrição no curso %nome_secretaria_curso% nas disciplinas assinaladas, dentro dos critérios estabelecidos e atesto serem verídicas as informações aqui prestadas.</p>
              </div>
              <div class="col-12 text-right mt-2">
                <p>%cidade_aluno%, ___________ de _________________________ de ${new Date().getFullYear()}</p>
              </div>
              <div class="col-12 text-center mt-2">
                ______________________________________________________________________________
                <p class="m-0">Assinatura do (a) candidato (a)</p>
                <small>(Conforme identificação)</small>
              </div>
              <div class="col-12 text-center mt-4">
                ______________________________________________________________________________
                <p>Assinatura do responsável pela inscrição do condidato</p>
              </div>
              <div class="col-12 text-center mt-2">
                <h6>(ANEXAR AO REQUERIMENTO CÓPIA DO DOCUMENTO DE IDENTIFICAÇÃO)</h6>
                <p>Mantenedora/Diretora</p>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 text-center">
                <p>%logradouro_empresa%, n. %numero_empresa% - %complemento_empresa% %cidade_empresa%-%uf_empresa%</p>
                <p>%email_empresa% - Tel. %telefone_empresa%</p>
              </div>
            </div>
          </div>
          `
        })
        this.fastInscricao.id_inscricao_template = 1
    },
    gerarPdfInscricao(){

      this.$store.state.fastCarregando = true;
      if (!this.fastInscricao.id_inscricao_template) {
        this.exibeToasty("Selecione um template de inscrição", "error");
      } else {
        this.fastInscricoes.forEach((c) => {
          if (c.id_inscricao_template == this.fastInscricao.id_inscricao_template) {
            this.fastInscricao.html_template = c.html_template;
          }
        });

        if (!this.fastInscricao.html_template) {
          this.exibeToasty("Erro ao buscar template de inscrição", "error");
          this.$store.state.fastCarregando = false
        }
        else {
          let contrato = this.fastInscricao.html_template;
          let erros = [];

          if (this.fastUcsMatricula.loading) erros.push("Aguarde o carregamento das UCs")
          if (!this.fastUcsMatricula.total.length) erros.push("Nenhum UC foi encontrada para o curso dessa matrícula")
          

          if (this.fastInscricao.html_template.indexOf("%nome_aluno%") != "-1" && !this.$store.state.fastPessoa.first_name) erros.push("O nome do aluno não foi cadastrado")
          if (this.fastInscricao.html_template.indexOf("%nome_aluno%") != "-1" && !this.$store.state.fastPessoa.last_name) erros.push("O sobrenome do aluno não foi cadastrado")
          if (this.fastInscricao.html_template.indexOf("%cpf_aluno%") != "-1" && !this.$store.state.fastPessoa.cpf_cnpj) erros.push("O CPF/CNPJ do aluno não foi cadastrado")
          if (this.fastInscricao.html_template.indexOf("%logradouro_aluno%") != "-1" && !this.$store.state.fastPessoa.logradouro) erros.push("O endereço do aluno não foi cadastrado")
          if (this.fastInscricao.html_template.indexOf("%numero_aluno%") != "-1" && !this.$store.state.fastPessoa.numero) erros.push("O número do endereço do aluno não foi cadastrado")
          if (this.fastInscricao.html_template.indexOf("%bairro_aluno%") != "-1" && !this.$store.state.fastPessoa.bairro) erros.push("O bairro do aluno não foi cadastrado")
          if (this.fastInscricao.html_template.indexOf("%cep_aluno%") != "-1" && !this.$store.state.fastPessoa.cep) erros.push("O cep do aluno não foi cadastrado")
          if (this.fastInscricao.html_template.indexOf("%cidade_aluno%") != "-1" && !this.$store.state.fastPessoa.cidade) erros.push("O cidade do aluno não foi cadastrado")
          if (this.fastInscricao.html_template.indexOf("%uf_aluno%") != "-1" && !this.$store.state.fastPessoa.uf) erros.push("O estado do aluno não foi cadastrado")

          // Novas variáveis
          if (this.fastInscricao.html_template.indexOf("%telefone_aluno%") != "-1" && !this.$store.state.fastPessoa.telefone) erros.push("O telefone do aluno não foi cadastrado")
          if (this.fastInscricao.html_template.indexOf("%datanascimento_abertura_aluno%") != "-1" && !this.$store.state.fastPessoa.datanascimento_abertura) erros.push("A data de nascimento do aluno não foi cadastrado")    
          

          if (erros.length) {
            erros.forEach(e => this.exibeToasty(e, "error"))
            this.$store.state.fastCarregando = false
          } else {
            contrato = contrato.replaceAll(
            "%nome_aluno%",
              this.$store.state.fastPessoa.first_name.trim() + " " + this.$store.state.fastPessoa.last_name.trim()
            );
            contrato = contrato.replaceAll(
              "%cpf_aluno%",
              this.$store.state.fastPessoa.cpf_cnpj.trim()
            );
            contrato = contrato.replaceAll(
              "%logradouro_aluno%",
              this.$store.state.fastPessoa.logradouro.trim() 
            );
            contrato = contrato.replaceAll(
              "%numero_aluno%",
              this.$store.state.fastPessoa.numero.trim() 
            );
            contrato = contrato.replaceAll(
              "%bairro_aluno%",
              this.$store.state.fastPessoa.bairro.trim() 
            );       
            contrato = contrato.replaceAll(
              "%cep_aluno%",
              this.$store.state.fastPessoa.cep.trim().replaceAll("-","")
            );  
            contrato = contrato.replaceAll(
              "%cidade_aluno%",
              this.$store.state.fastPessoa.cidade.trim() 
            );
            contrato = contrato.replaceAll(
              "%uf_aluno%",
              this.$store.state.fastPessoa.uf.trim()
            );
            contrato = contrato.replaceAll(
              "%complemento_aluno%",
              this.$store.state.fastPessoa.complemento.trim()
            );
            contrato = contrato.replaceAll(
              "%telefone_aluno%",
              this.$store.state.fastPessoa.telefone.trim()
            );
            contrato = contrato.replaceAll(
              "%datanascimento_abertura_aluno%",
              this.$store.state.fastPessoa.datanascimento_abertura.trim().split("-")[2] + '/' + this.$store.state.fastPessoa.datanascimento_abertura.trim().split("-")[1] + '/' + this.$store.state.fastPessoa.datanascimento_abertura.trim().split("-")[0]
            );


            // Turma
            contrato = contrato.replaceAll(
              "%turma_nivel%",
              this.retornaCursoNivel(this.fastMatriculaAlunoEditar.nivel)
            );
            // UCs
            let lista_ucs_curso = "";
            this.fastUcsMatricula.total.forEach(e => {
              let marcar = ""
              this.fastUcsMatriculaMarcadas.total.forEach(m => {
                if (m.id_secretaria_curso_programa_unidade == e.id_secretaria_curso_programa_unidade) marcar = "X"
              })
              lista_ucs_curso += `<tr>
                    <td class="p-1 text-center">${marcar}</td>
                    <td class="pt-0 pb-0"><small>${e.nome_uc}</small></td>
                  </tr>`
            })
            contrato = contrato.replaceAll(
              "%lista_ucs_curso%",
              lista_ucs_curso
            );

            contrato = contrato.replaceAll(
              "%nome_secretaria_curso%",
              this.fastMatriculaAlunoEditar.nome_curso + " - " + this.fastMatriculaAlunoEditar.sigla_turma
            );

            // Plataforma
            contrato = contrato.replaceAll(
              "%logo_plataforma%", `<img src="${this.$store.state.fastPlataforma.logo_plataforma}" style="max-width: 200px; max-height: 100px">`              
            );
        
            // Informações da empresa
            this.promiseGetFastApi("api/fast_plataforma/lista_responsavel", "id_plataforma=" + this.$route.params.id_plataforma)
            .then(res => {
              console.log("lista_responsavel", res)
              if (res.length) {
                console.log("res[0].logradouro", res[0].logradouro)
                contrato = contrato.replaceAll(
                  "%logradouro_empresa%",
                  res[0].logradouro
                )
                contrato = contrato.replaceAll(
                  "%numero_empresa%",
                  res[0].numero
                )
                contrato = contrato.replaceAll(
                  "%complemento_empresa%",
                  res[0].complemento
                )
                contrato = contrato.replaceAll(
                  "%cidade_empresa%",
                  res[0].cidade
                )
                contrato = contrato.replaceAll(
                  "%uf_empresa%",
                  res[0].uf
                )
                contrato = contrato.replaceAll(
                  "%cep_empresa%",
                  res[0].cep
                )
                contrato = contrato.replaceAll(
                  "%email_empresa%",
                  res[0].email
                )
                contrato = contrato.replaceAll(
                  "%telefone_empresa%",
                  res[0].telefone
                )
              } else {
                this.exibeToasty("Configure o responsável pela plataforma", "error")
              }

            })
            .then(() => {
              this.fastInscricao.html_template = contrato;
              this.$refs.html2PdfRequerimento.generatePdf();
              this.$store.state.fastCarregando = false;
            })
            .catch(e => {
              this.$store.state.fastCarregando = false;
              this.exibeToasty(e, "error")
            })

            
          }

          
        }
        
      }
      
    },    
    //#region Histórico
    async getHistoricoSecretariaCursos(id_plataforma, id_pessoa_aluno) {
      this.fastHistoricoSecretariaCursos.loading = true
        this.promiseGetFastApi("api/fast_secretaria_curso/lista_aluno", `id_plataforma=${id_plataforma}&id_pessoa_aluno=${id_pessoa_aluno}`)
        .then(res => {           
            this.fastHistoricoSecretariaCursos.total = res
            this.fastHistoricoSecretariaCursos.loading = false
        })
        .catch(e => {
          this.exibeToasty(e, "error")
          this.fastHistoricoSecretariaCursos.loading = false
        })
    },
    getHistoricoTemplates(){
      this.promiseGetFastApi("api/fast_secretaria_historico_escolar/lista", `id_plataforma=${this.$route.params.id_plataforma}`).then(json => {
        let obj = Array.from(json)   
        this.templatesHistorico = obj;
        this.templatesHistorico.forEach(t => {
          if (t.padrao) {
            this.idTemplateHistoricoEscolhido = t.id_secretaria_historico_escolar
          }
        });   
      })
    },  
    exibeModalHistoricoSecretariaCurso(){      
      this.showModalBootstrap('modalGerarHistorico')
    },
    exibeModalHistoricoNovo(id_secretaria_curso){
      this.fastHistoricoNovo.unidades = []
      this.showModal('modalHistoricoNovo')

      //this.getSecretariaCursos(this.$route.params.id_plataforma)
      this.getfastResultados(this.$route.params.id_plataforma)
      this.getfastPessoasParceiros(this.$route.params.id_plataforma)
      this.fastHistoricoNovo.id_plataforma = this.$route.params.id_plataforma
      this.fastHistoricoNovo.id_pessoa_aluno = this.$route.params.id_pessoa_aluno
      this.fastHistoricoNovo.id_secretaria_curso = id_secretaria_curso
      
      this.fastSecretariaCursos.loading = true
      this.promiseGetFastApi("api/fast_secretaria_curso/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_secretaria_curso=" + id_secretaria_curso)
      .then(json => {
        let obj = Array.from(json);        
        if (obj.length) {  
          obj.sort(this.reornedarArrayObjetos("nome_curso"))
          this.fastSecretariaCursos.total = obj
          this.fastHistoricoNovo.id_secretaria_curso = obj[0].id_secretaria_curso
          this.fastHistoricoNovo.carga_horaria = obj[0].carga_horaria
          this.getSecretariaCursoTurmas()     
          this.getSecretariaCursoModulos()   
        } else {
          this.fastSecretariaCursos.total = []
        }        
        this.fastSecretariaCursos.loading = false
      }).catch(e => {
        console.log(e);
        this.fastSecretariaCursos.loading = false
      })
      
    },    
    getSecretariaCursos(id_plataforma){
      this.fastSecretariaCursos.loading = true
      this.promiseGetFastApi("api/fast_secretaria_curso/lista", "id_plataforma=" + id_plataforma + "&id_secretaria_curso=0")
      .then(json => {
        let obj = Array.from(json);        
        if (obj.length) {  
          obj.sort(this.reornedarArrayObjetos("nome_curso"))
          this.fastSecretariaCursos.total = obj
          this.fastHistoricoNovo.id_secretaria_curso = obj[0].id_secretaria_curso
          this.fastHistoricoNovo.carga_horaria = obj[0].carga_horaria
          this.getSecretariaCursoTurmas()     
          this.getSecretariaCursoModulos()     
        } else {
          this.fastSecretariaCursos.total = []
        }        
        this.fastSecretariaCursos.loading = false
      }).catch(e => {
        console.log(e);
        this.fastSecretariaCursos.loading = false
      })
    },
    selecionaHistoricoNovoCurso(){      
      this.fastSecretariaCursos.total.forEach(e => {
        if (e.id_secretaria_curso == this.fastHistoricoNovo.id_secretaria_curso) {
          console.log("selecionaHistoricoNovoCurso")
          this.fastHistoricoNovo.carga_horaria = e.carga_horaria
          this.getSecretariaCursoTurmas()
          this.getSecretariaCursoModulos()
        }
      })      
    },
    getSecretariaCursoTurmas(){
      this.fastSecretariaTurmas.loading = true
      this.promiseGetFastApi("api/fast_secretaria_turma/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_turma=0")
      .then(json => {
        let obj = Array.from(json)        
        if (obj.length) {  
          obj.sort(this.reornedarArrayObjetos("sigla_turma"))
          this.fastSecretariaTurmas.total = obj.filter(e =>  e.id_secretaria_curso === this.fastHistoricoNovo.id_secretaria_curso)   
          this.fastHistoricoNovo.id_turma = obj[0].id_turma
          
        } else {
          this.fastSecretariaTurmas.total = [];
        }
        this.fastSecretariaTurmas.loading = false

      }).catch(e => {
        console.log(e)
        this.fastSecretariaTurmas.loading = false
      })
    },
    async getfastResultados(id_plataforma){
      this.fastResultados.loading = true
      this.promiseGetFastApi("api/fast_secretaria_resultado_aluno/lista", "id_plataforma=" + id_plataforma)
      .then(obj => {
        if (obj.length) {
          this.fastResultados.total = obj
        }
        else {
          this.fastResultados.total = []
        }            
        this.fastResultados.loading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar resultados", "error");
        this.fastResultados.loading = false
      })
    },
    async getSecretariaCursoModulos(){
      this.fastSecretariaCursoModulos.loading = true
      this.promiseGetFastApi("api/fast_secretaria_curso_programa/lista", `id_plataforma=${this.$route.params.id_plataforma}&id_secretaria_curso=${this.fastHistoricoNovo.id_secretaria_curso}`)
      .then(obj => {
        if (obj.length) {
          obj.sort(this.reornedarArrayObjetos("sequencia"))
          obj.forEach((e) => {
              if (e.data_inicio) e.data_inicio = e.data_inicio.split("T")[0];
              if (e.data_final) e.data_final = e.data_final.split("T")[0];              
            });
          this.fastSecretariaCursoModulos.total = obj
          this.fastHistoricoNovo.id_secretaria_curso_programa = obj[0].id_secretaria_curso_programa
          this.getFastCursosPrograma()
        }
        else {
          this.fastSecretariaCursoModulos.total = []
        }            
        this.fastSecretariaCursoModulos.loading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar módulos", "error");
        this.fastSecretariaCursoModulos.loading = false
      })
    },
    async getSecretariaCursoModulosEditar(){
      this.fastSecretariaCursoModulos.loading = true
      this.promiseGetFastApi("api/fast_secretaria_curso_programa/lista", `id_plataforma=${this.$route.params.id_plataforma}&id_secretaria_curso=${this.fastHistoricoEditar.id_secretaria_curso}`)
      .then(obj => {
        if (obj.length) {
          obj.sort(this.reornedarArrayObjetos("sequencia"))
          obj.forEach((e) => {
              if (e.data_inicio) e.data_inicio = e.data_inicio.split("T")[0];
              if (e.data_final) e.data_final = e.data_final.split("T")[0];              
            });
          this.fastSecretariaCursoModulos.total = obj
          this.fastHistoricoEditar.id_secretaria_curso_programa = obj[0].id_secretaria_curso_programa
          this.getFastCursosProgramaEditar()
        }
        else {
          this.fastSecretariaCursoModulos.total = []
        }            
        this.fastSecretariaCursoModulos.loading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar módulos", "error");
        this.fastSecretariaCursoModulos.loading = false
      })
    },
    async getfastPessoasParceiros(id_plataforma){
      this.fastPessoasParceiros.loading = true
      this.promiseGetFastApi("api/fast_pessoa_funcao/lista_parceiros", "id_plataforma=" + id_plataforma)
      .then(obj => {
        if (obj.length) {
          this.fastPessoasParceiros.total = obj
        }
        else {
          this.fastPessoasParceiros.total = []
        }            
        this.fastPessoasParceiros.loading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar parceiros", "error");
        this.fastPessoasParceiros.loading = false
      })
    },
    async getFastCursosPrograma() {
      this.fastCursosPrograma.loading = true
      this.fastCursosPrograma.total = [];
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_secretaria_curso_programa_unidade/lista?id_secretaria_curso_programa=" +
            this.fastHistoricoNovo.id_secretaria_curso_programa,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        
        if (obj.length > 0) {    
          obj.forEach(e => {
            e.ano = ""
            e.carga_horaria = ""
            e.nota = ""
            e.id_tipo_resultado_aluno = ""
            e.id_plataforma = this.$route.params.id_plataforma
          })
          this.fastCursosPrograma.total = obj
        } else {
          this.fastCursosPrograma.total = [];
        }
        this.fastCursosPrograma.loading = false
      } catch (e) {
        console.log("Erro", e)
        this.fastCursosPrograma.loading = false
      }
    },
    async getFastCursosProgramaEditar() {
      this.fastCursosPrograma.loading = true
      this.fastCursosPrograma.total = [];
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_secretaria_curso_programa_unidade/lista?id_secretaria_curso_programa=" +
            this.fastHistoricoEditar.id_secretaria_curso_programa,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        
        if (obj.length > 0) {    
          obj.forEach(e => {
            e.ano = ""
            e.carga_horaria = ""
            e.nota = ""
            e.id_tipo_resultado_aluno = ""
            e.id_plataforma = this.$route.params.id_plataforma
          })
          this.fastCursosPrograma.total = obj
        } else {
          this.fastCursosPrograma.total = [];
        }
        this.fastCursosPrograma.loading = false
      } catch (e) {
        console.log("Erro", e)
        this.fastCursosPrograma.loading = false
      }
    },
    adicionaUnidadeHistorico(){
      if (this.fastHistoricoUnidade.id_curso) {
        let curso = this.fastCursosPrograma.total.filter(e => e.id_curso == this.fastHistoricoUnidade.id_curso)
        let curso_inserido = false
        this.fastHistoricoNovo.unidades.forEach(e => {
          if (e.id_curso == this.fastHistoricoUnidade.id_curso) curso_inserido = true
        })
        if (!curso_inserido) this.fastHistoricoNovo.unidades.push(curso[0])
      }
      console.log("this.fastHistoricoNovo.unidades", this.fastHistoricoNovo.unidades)

    },
    adicionaUnidadeHistoricoEditar(){
      //console.log("this.fastHistoricoUnidade.id_curso", this.fastHistoricoUnidade.id_curso)
      if (this.fastHistoricoUnidade.id_curso) {

        let curso = this.fastCursosPrograma.total.filter(e => e.id_curso == this.fastHistoricoUnidade.id_curso)
        let curso_inserido = false

        //console.log("Curso selecionado", curso)
        //console.log("this.fastAproveitamentoAluno", this.fastAproveitamentoAluno)

        this.fastAproveitamentoAluno.total.forEach(modulo => {
          if (modulo.unidades.length) {
            modulo.unidades.forEach(u => {
              if (u.id_curso == this.fastHistoricoUnidade.id_curso) curso_inserido = true
            })
          }
        })

        if (!curso_inserido) {
          let obj = {
            historico_unidades: [
              {
                id_aluno_historico: this.fastHistoricoEditar.id_aluno_historico,
                id_secretaria_curso_programa: this.fastHistoricoEditar.id_secretaria_curso_programa,
                id_curso: this.fastHistoricoUnidade.id_curso,
                id_plataforma: this.$route.params.id_plataforma,
                ano: 0,
                carga_horaria: 0,
                nota: 0,
                id_tipo_resultado_aluno: 0
              }
            ],
            id_plataforma: this.$route.params.id_plataforma
          } 
            
          this.promisePostFastApi(obj, "api/fast_secretaria_aluno_historico_escolar_unidades/insere_n")
          .then(res => {
            this.exibeToasty("Alterações salvas com sucesso", "success") 
            this.fastHistoricoEditar.carregando_acao = false
            this.fastHistoricoEditar.id_aluno_historico = 0
            this.exibeModalAproveitamentoSecretariaCurso(this.fastHistoricoEditar)
          })
          .catch(e => {
            this.exibeToasty("Erro ao adicionar", "error")
          })

        }
        console.log("this.fastAproveitamentoAluno", this.fastAproveitamentoAluno)
      }
      

    },
    excluirHistoricoNovoUnidade(unidade) {
      this.fastHistoricoNovo.unidades = this.fastHistoricoNovo.unidades.filter(e => e.id_curso != unidade.id_curso)
    },
    excluirHistoricoEditarUnidade(unidade) {
      const obj = {
        id_aluno_historico: unidade.id_aluno_historico,
        id_secretaria_curso_programa: unidade.id_secretaria_curso_programa,
        id_curso: unidade.id_curso,
        id_plataforma: unidade.id_plataforma,
      }
      this.promisePostFastApi(obj, "api/fast_secretaria_aluno_historico_escolar_unidades/exclui")
      .then((res) => {
          this.exibeToasty("Excluído com sucesso", "success")   
          this.exibeModalAproveitamentoSecretariaCurso({ id_secretaria_curso: this.fastHistoricoEditar.id_secretaria_curso }) 
      }).catch((e) => {
        console.error(e)
          this.exibeToasty("Erro ao excluir", "error")
      });
    },
    criarHistoricoNovo(){
      let erros = []
      let acao = "insere"
      if (this.fastHistoricoNovo.id_aluno_historico) acao = "atualiza"

      console.log("this.fastHistoricoNovo", this.fastHistoricoNovo)
      if (!this.fastHistoricoNovo.ano) erros.push("O ano é obrigatório")
      if (!this.fastHistoricoNovo.id_secretaria_curso) erros.push("O curso é obrigatório")
      if (!this.fastHistoricoNovo.id_turma) erros.push("A turma é obrigatória")
      if (!this.fastHistoricoNovo.id_pessoa) erros.push("A instituição é obrigatória")

      if (this.fastHistoricoNovo.tipo_avaliacao == "N") {
        if (!this.fastHistoricoNovo.nota_maxima) erros.push("A nota máxima é obrigatória")
        if (!this.fastHistoricoNovo.nota_aprovacao) erros.push("A nota para aprovação é obrigatória")
      }

      if (!this.fastHistoricoNovo.unidades.length) erros.push("Adicione ao menos uma unidade curricular")
      else {
        this.fastHistoricoNovo.unidades.forEach(e => {
          //if (!e.ano) erros.push("O ano da unidade curricular é obrigatório")
          if (!e.carga_horaria) erros.push("A carga horária da unidade curricular é obrigatória")
          //if (!e.nota) erros.push("A nota da unidade curricular é obrigatória")
          if (!e.id_tipo_resultado_aluno) erros.push("O tipo de resultado da unidade curricular é obrigatório")
          e.id_plataforma = this.$route.params.id_plataforma
        })
      }

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.fastHistoricoNovo.carregando_acao = true
        this.promisePostFastApi(this.fastHistoricoNovo, `api/fast_secretaria_aluno_historico_escolar/${acao}`)
        .then((res) => {
          if (res.length) {  
            if (acao == "insere") {
              this.fastHistoricoNovo.id_aluno_historico = res[0].id_aluno_historico  
            } 
            
            this.fastHistoricoNovo.unidades.forEach(e => {
              e.id_aluno_historico = this.fastHistoricoNovo.id_aluno_historico 
            })
            let obj = {
              historico_unidades: this.fastHistoricoNovo.unidades,
              id_plataforma: this.$route.params.id_plataforma
            } 
            return this.promisePostFastApi(obj, "api/fast_secretaria_aluno_historico_escolar_unidades/insere_n")        
          } else {    
            this.exibeToasty("Erro ao cadastrar", "error")         
            return false            
          } 
        })
        .then((res) => {
          if (res.length == this.fastHistoricoNovo.unidades.length) {
            this.exibeToasty("Cadastro efetuado com sucesso", "success") 
            this.hideModal('modalHistoricoNovo')
            this.fastHistoricoNovo.carregando_acao = false
            this.fastHistoricoNovo.id_aluno_historico = 0
            this.getHistoricoSecretariaCursos(this.$route.params.id_plataforma, this.$route.params.id_pessoa_aluno)
          } else {
            this.exibeToasty("Erro ao cadastrar", "error")    
            return false
          }          
        }).catch((e) => {
            this.exibeToasty(e, "error")
            this.fastHistoricoNovo.carregando_acao = false
        });
      }
    },
    getHistoricoAluno(id_plataforma, id_secretaria_curso){
      return new Promise(async (resolve, reject) => {
          this.promiseGetFastApi("api/fast_secretaria_aluno_historico_escolar/lista", "id_plataforma=" + id_plataforma + "&id_secretaria_curso=" + id_secretaria_curso).then(json => {
            let obj = Array.from(json)   
            resolve(obj);
            return true;
          }).catch(e => {
            reject(true)
            return false
          })    
      });
    },
    exibeModalAproveitamentoSecretariaCurso(curso){ 
      this.fastAproveitamentoAluno.loading = true
      this.getHistoricoAluno(this.$route.params.id_plataforma, curso.id_secretaria_curso)
      .then((obj) => {
        if (obj.length) {
          this.fastHistoricoEditar = obj[0]

          // Cursos
          this.fastSecretariaCursos.loading = true
          this.promiseGetFastApi("api/fast_secretaria_curso/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_secretaria_curso=0")
          .then(json => {
            let obj = Array.from(json);        
            if (obj.length) {  
              obj.sort(this.reornedarArrayObjetos("nome_curso"))
              this.fastSecretariaCursos.total = obj 
            } else {
              this.fastSecretariaCursos.total = []
            }        
            this.fastSecretariaCursos.loading = false
          }).catch(e => {
            console.log(e);
            this.fastSecretariaCursos.loading = false
          })
          // Turmas
          this.fastSecretariaTurmas.loading = true
          this.promiseGetFastApi("api/fast_secretaria_turma/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_turma=0")
          .then(json => {
            let obj = Array.from(json)
            if (obj.length) {  
              obj.sort(this.reornedarArrayObjetos("sigla_turma"))
              this.fastSecretariaTurmas.total = obj.filter(e =>  e.id_secretaria_curso === this.fastHistoricoEditar.id_secretaria_curso)  
            } else {
              this.fastSecretariaTurmas.total = [];
            }
            this.fastSecretariaTurmas.loading = false

          }).catch(e => {
            console.log(e)
            this.fastSecretariaTurmas.loading = false
          })
          // Módulos
          this.fastSecretariaCursoModulos.loading = true
          this.promiseGetFastApi("api/fast_secretaria_curso_programa/lista", `id_plataforma=${this.$route.params.id_plataforma}&id_secretaria_curso=${this.fastHistoricoEditar.id_secretaria_curso}`)
          .then(obj => {
            if (obj.length) {
              obj.sort(this.reornedarArrayObjetos("sequencia"))
              obj.forEach((e) => {
                  if (e.data_inicio) e.data_inicio = e.data_inicio.split("T")[0];
                  if (e.data_final) e.data_final = e.data_final.split("T")[0];              
                });
              this.fastSecretariaCursoModulos.total = obj
              this.fastHistoricoEditar.id_secretaria_curso_programa = obj[0].id_secretaria_curso_programa
              this.getFastCursosProgramaEditar()
            }
            else {
              this.fastSecretariaCursoModulos.total = []
            }            
            this.fastSecretariaCursoModulos.loading = false
          }).catch(e => { 
            this.exibeToasty("Erro ao buscar módulos", "error");
            this.fastSecretariaCursoModulos.loading = false
          })

          this.getfastResultados(this.$route.params.id_plataforma)
          this.getfastPessoasParceiros(this.$route.params.id_plataforma)
          this.fastHistoricoEditar.id_plataforma = this.$route.params.id_plataforma
          this.fastHistoricoEditar.id_pessoa_aluno = this.$route.params.id_pessoa_aluno
          
          const questoesModulos = Array.from(new Set(obj.map((a) => a.id_secretaria_curso_programa))).map((id_secretaria_curso_programa) => {
              return obj.find((a) => a.id_secretaria_curso_programa === id_secretaria_curso_programa);
            });

          questoesModulos.forEach((a, index) => {
            obj.forEach((q, index2) => {
              if (a.id_secretaria_curso_programa == q.id_secretaria_curso_programa) {
                if (!questoesModulos[index].unidades) questoesModulos[index].unidades = []
                questoesModulos[index].unidades.push(q);
              }
            })
          })          

          this.fastAproveitamentoAluno.total = questoesModulos
          
          console.log("this.fastAproveitamentoAluno.total", this.fastAproveitamentoAluno.total)
          this.showModalBootstrap('modalAproveitamentos')
        } else {
          this.exibeToasty("Nenhum aproveitamento cadastrado", "info")
          this.exibeModalHistoricoNovo(curso.id_secretaria_curso)
        }
        
        this.fastAproveitamentoAluno.loading = false
        
      })
      .catch(e => {
        console.log("Erro", e)
        this.exibeToasty(e, "error")
        this.fastAproveitamentoAluno.loading = false
      })
    },
    salvarAlteracoesHistorico(){
      let erros = []
      let acao = "atualiza"

      console.log("this.fastHistoricoEditar", this.fastHistoricoEditar)
      if (!this.fastHistoricoEditar.id_aluno_historico) erros.push("id_aluno_historico não identificado")
      if (!this.fastHistoricoEditar.ano) erros.push("O ano é obrigatório")
      if (!this.fastHistoricoEditar.id_secretaria_curso) erros.push("O curso é obrigatório")
      if (!this.fastHistoricoEditar.id_turma) erros.push("A turma é obrigatória")
      if (!this.fastHistoricoEditar.id_pessoa) erros.push("A instituição é obrigatória")

      if (this.fastHistoricoEditar.tipo_avaliacao == "N") {
        if (!this.fastHistoricoEditar.nota_maxima) erros.push("A nota máxima é obrigatória")
        if (!this.fastHistoricoEditar.nota_aprovacao) erros.push("A nota para aprovação é obrigatória")
      }

      if (!this.fastHistoricoEditar.unidades.length) erros.push("Adicione ao menos uma unidade curricular")
      else {
        this.fastHistoricoEditar.unidades.forEach(e => {
          //if (!e.ano) erros.push("O ano da unidade curricular é obrigatório")
          if (!e.carga_horaria) erros.push("A carga horária da unidade curricular é obrigatória")
          //if (!e.nota) erros.push("A nota da unidade curricular é obrigatória")
          if (!e.id_tipo_resultado_aluno) erros.push("O tipo de resultado da unidade curricular é obrigatório")
          e.id_plataforma = this.$route.params.id_plataforma
        })
      }

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.fastHistoricoEditar.carregando_acao = true   
        
        // Remove atribuição por referência
        let objHistorico = Object.assign({}, this.fastHistoricoEditar)
        objHistorico.unidades = []
        
        this.promisePostFastApi(objHistorico, `api/fast_secretaria_aluno_historico_escolar/${acao}`)
        .then((res) => {
          this.exibeToasty("Alterações salvas com sucesso", "success") 
            this.hideModalBootstrap('modalAproveitamentos')
            this.fastHistoricoEditar.carregando_acao = false
            this.fastHistoricoEditar.id_aluno_historico = 0
            this.getHistoricoSecretariaCursos(this.$route.params.id_plataforma, this.$route.params.id_pessoa_aluno)
        }).catch((e) => {
            this.exibeToasty(e, "error")
            this.fastHistoricoEditar.carregando_acao = false
        });
      }
    },
    atualizaUnidadeHistorico(obj){
      console.log("obj", obj)

      if (!this.fastCursosPrograma.loading) {
        let erros = []
      
        if (!obj.id_aluno_historico) erros.push("id_aluno_historico não identificado")
        if (!obj.id_secretaria_curso_programa) erros.push("id_secretaria_curso_programa não identificado")
        if (!obj.id_curso) erros.push("id_curso não identificado")
        if (!obj.id_plataforma) erros.push("id_plataforma não identificado")

        const objAtualizacao = {
          id_aluno_historico: obj.id_aluno_historico,
          id_secretaria_curso_programa: obj.id_secretaria_curso_programa,
          id_curso: obj.id_curso,
          id_plataforma: obj.id_plataforma,
          nota: obj.nota_unidade,
          ano: obj.ano_unidade,
          carga_horaria: obj.carga_horaria_unidade,
          id_tipo_resultado_aluno: obj.id_tipo_resultado_aluno
        }

        if (erros.length) {
            erros.forEach(e => this.exibeToasty(e, "error"))
        } else {
            this.promisePostFastApi(objAtualizacao, "api/fast_secretaria_aluno_historico_escolar_unidades/atualiza")
            .then((res) => {
                this.exibeToasty("Alteração realizada atualizada com sucesso", "success")    
            }).catch((e) => {
                this.exibeToasty("Erro ao atualizar", "error")
            });
        }      
      }

      
    },
    //#endregion
    //#region Certificado
    getCertificadoTemplates(){
      this.promiseGetFastApi("api/fast_secretaria_certificado/lista", `id_plataforma=${this.$route.params.id_plataforma}`).then(json => {
        let obj = Array.from(json)   
        this.templatesCertificado = obj;
        this.templatesCertificado.forEach(t => {
          if (t.padrao) {
            this.idTemplateCertificadoEscolhido = t.id_secretaria_certificado
          }
        });      
      })
    },
    exibeModalGerarCertificado(){
      this.showModalBootstrap('modalGerarCertificado')
    },
    gerarCertificadoConclusao(){
      this.$store.state.fastCarregando = true
      this.promiseGetFastApi("api/fast_secretaria_certificado/gerar_certificado", `id_secretaria_certificado=${this.idTemplateCertificadoEscolhido}&id_plataforma=${this.$route.params.id_plataforma}&id_pessoa_aluno=${this.$route.params.id_pessoa_aluno}&id_matricula=${this.fastIdMatricula}`).then(json => {  
        let res = JSON.parse(json)
        if(res.url){
          window.open(res.url, '_blank');
          this.exibeToasty("Se não foi aberta aba/janela contendo o documento, verifique se os pop-ups estão bloqueados", "info")
          this.$store.state.fastCarregando = false
        } else {
          this.exibeToasty("Erro ao gerar documento", "error")
          this.$store.state.fastCarregando = false
        }
      }).catch((e) => {
        this.exibeToasty(e, "error")
        this.$store.state.fastCarregando = false
      });
    },
    //#endregion
    //#region Encerrar Matrícula
    async encerraCursoMatricula() {
      let obj = {
        id_plataforma: this.$route.params.id_plataforma,
        id_matricula: this.fastMatriculaAlunoEditar.id_matricula,
        data_conclusao: new Date(),
        data_certificacao: this.fastDataParaEncerramento,
        curso_concluido: true
      }
      this.promisePostFastApi(obj,
        "api/fast_secretaria_matricula_aluno/conclui_curso"
      )
      .then((res) => {
        if (res) {
          this.hideModal("modalMatriculaAlunoEncerrarCursoMatricula");
          this.hideModal('modalConfirmaEncerrarCursoMatricula')
          if (res == true) {
            this.exibeToasty("Matrícula encerrada com sucesso", "success");
            this.getFastMatriculaAluno(this.$route.params.id_plataforma, this.$route.params.id_pessoa_aluno);
          } else {
            this.exibeToasty("Erro ao encerrar matrícula", "error");
          }
        }
      })
      .catch((e) => {
        this.exibeToasty(e, "error");
      })
    },
    exibeModalMatriculaAlunoEncerrarCursoMatricula(matricula) {
      this.fastMatriculaAlunoEditar = matricula;
      this.showModal("modalMatriculaAlunoEncerrarCursoMatricula");
    },
    exibeModalConfirmaEncerrarCursoMatricula() {
      let data = new Date();
      let ano = data.getFullYear();
      let mes = ('0' + (data.getMonth() + 1)).slice(-2);
      let dia = ('0' + data.getDate()).slice(-2); 
      let dataFormatada = `${ano}-${mes}-${dia}`;
      if(!this.fastDataParaEncerramento) {
      this.exibeToasty("A data do certificado deve ser preenchida","error")
      } else if(this.fastDataParaEncerramento > dataFormatada){
      this.exibeToasty("A data do certificado não pode ser superior a hoje","error")
      } else if(this.fastDataParaEncerramento){
        this.showModal("modalConfirmaEncerrarCursoMatricula");
      } 
    },
    //#endregion
    //Assinatura de documentos
    exibeModalAssinaturaDocumentos(matricula, id_secretaria_curso) {
      this.fastIdMatricula = matricula
      this.fastIdAssinaturaSecretariaCurso = id_secretaria_curso
      this.showModal('modalAssinaturaDeDocumentos')
    },
    exibeModalConsultaDocumentos(matricula_assinatura, matricula) {
      this.fastIdAssinaturaMatricula = matricula_assinatura
      this.fastIdMatricula = matricula
      this.showModal('modalConsultaAssinaturaDeDocumentos')
    },
  },
};
</script>

<style scope>

/* AJUSTAR PDF PARA QUILL EDITOR */
.fast-pdf-html-template *{
  margin: 0 !important;
}
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

select[readonly='true'] {
  background: #eee; /*Simular campo inativo */
  pointer-events: none;
  touch-action: none;
}
.min-height-table {
  min-height: 9em;
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
