<template>
  <div class="col-12">
    <div class="row">
      <div class="col-sm-4 col-md-4 col-lg-4 shadow overflow-y-auto">
        <div class="bg-light border border-dark rounded p-2 my-1">
          <div class="border-bottom border-dark rounded d-flex justify-content-between">
            <div class="mr-1">
              <h4>Status</h4>
            </div>
            <div class="ml-1">
              <a class="btn-novo btn-primary text-white text-nowrap" @click="atualizarInformacoes()"><small>Sincronizar</small></a>
            </div>
          </div>
          <p class="my-3">
            <span v-if="fastConsultaDocumentosAssinatura.assinado" class="info-sign-text-positive">Assinado</span>  
            <span v-else class="info-sign-text-negative">Não assinado</span>  
          </p>
          <!-- <div v-if="fastConsultaDocumentosAssinatura.id_assinatura_documento_tipo">
            <label>Tipo do Documento</label>
            <p>
              <span class="info-sign-text">{{ fastConsultaDocumentosAssinatura.fast_assinatura_documento_tipo.nome_assinatura_documento_tipo }}</span>
            </p>
          </div> -->
          <div v-if="fastConsultaDocumentosAssinatura.id_assinatura_plataforma_documento_categoria">
            <label>Categoria do Documento</label>
            <p>
              <span class="info-sign-text">{{ getNameCategoriaDocumento(fastConsultaDocumentosAssinatura.id_assinatura_plataforma_documento_categoria) }}</span>
            </p>
          </div>
          <div>
            <label>Data do Documento</label>
            <p>
              <span class="info-sign-text">{{ new Date(fastConsultaDocumentosAssinatura.data_registro_documento).toLocaleString("pt-br")}}</span>
            </p>
          </div>
        </div>
        <div class="bg-light border border-dark rounded p-2 my-1">
          <h4 class="border-bottom border-dark rounded">Signatários</h4>
          <div v-for="signatario in fastConsultaDocumentosAssinatura.fast_assinatura_plataforma_documento_usuario" :key="signatario.id_assinatura_plataforma_documento_usuario">
            <h4 v-if="signatario.nome_usuario">{{ signatario.nome_usuario }}</h4>
            <h4 v-else>Signatário</h4>
            <div class="my-1">
              <label>CPF: </label>
              <a class="info-sign-text mx-1"><small>{{ signatario.cpf_usuario }}</small></a> 
            </div>
            <div class="my-1">
              <label>Contato: </label>
              <a v-if="signatario.enviado_email" class="info-sign-text mx-1"><small>{{ signatario.email_usuario }}</small></a>
              <a v-else-if="signatario.enviado_sms" class="info-sign-text mx-1"><small>+{{ signatario.ddi }} {{ signatario.telefone_usuario }} (SMS)</small></a>
              <a v-else-if="signatario.enviado_whatsapp" class="info-sign-text mx-1"><small>+{{ signatario.ddi }} {{ signatario.telefone_usuario }} (WApp)</small></a>
            </div>
            <div class="my-1">
              <label>Data envio: </label>
              <a v-if="signatario.enviado_email" class="info-sign-text-info mx-1"><small>Via email em: {{ new Date(signatario.data_envio).toLocaleDateString("pt-br") }}</small></a>
              <a v-else-if="signatario.enviado_sms" class="info-sign-text-info mx-1"><small>Via SMS em: {{ new Date(signatario.data_envio).toLocaleDateString("pt-br") }}</small></a>
              <a v-else-if="signatario.enviado_whatsapp" class="info-sign-text-info mx-1"><small>Via WhatsApp em: {{ new Date(signatario.data_envio).toLocaleDateString("pt-br") }}</small></a>
              <a v-else class="info-sign-text-negative mx-1"><small>Não enviado</small></a>
            </div>
            <div class="my-1">
              <label>Visualizou? </label>
              <a v-if="signatario.visualizou" class="info-sign-text-info mx-1"><small> Visualizado em {{ new Date(signatario.visualizou_data).toLocaleString("pt-br") }}</small></a>
              <a v-else class="info-sign-text-negative mx-1"><small>Não visualizou</small></a>
            </div>
            <div class="my-1">
              <label>Assinou? </label>
              <a v-if="signatario.assinou" class="info-sign-text-positive mx-1"><small> Assinado em {{ new Date(signatario.assinou_data).toLocaleString("pt-br") }}</small></a>
              <a v-else-if="signatario.rejeitou" class="info-sign-text-dark mx-1"><small>Documento rejeitado pelo signatário</small></a>
              <a v-else class="info-sign-text-negative mx-1"><small>Não assinou</small></a>
            </div>
          </div>
        </div>
        <div class="text-center" v-if="!$store.state.fastControlaComponentes.showHideExcluiDocumentoAutenticacao">
          <span>Caso existam dados incompletos ou incorretos, o documento deve ser excluído e deve ser criado um novo com os dados atualizados.</span>
          <p>
            <a class="btn-novo btn-danger text-white" @click="showModal('modalExcluirDocumento')"><small>Excluir documento</small></a>
          </p>
        </div>
      </div>
      <div class="col-sm-8 col-md-8 col-lg-8">
        <div v-if="!fastConsultaDocumentosAssinatura.assinado" class="d-flex justify-content-center w-100">
          <object
          :data="fastConsultaDocumentosAssinatura.url_arquivo_original"
          type="application/pdf"
          class="w-100"
          style="min-height: 40rem;"
          >
            <embed
              :src="fastConsultaDocumentosAssinatura.url_arquivo_original"
              type="application/pdf"
            >
          </object>
        </div>
        <div v-else class="d-flex justify-content-center w-100">
          <object
          :data="fastConsultaDocumentosAssinatura.url_arquivo_assinado"
          type="application/pdf"
          class="w-100"
          style="min-height: 40rem;"
          >
            <embed
              :src="fastConsultaDocumentosAssinatura.url_arquivo_assinado"
              type="application/pdf"
            >
          </object>
        </div>
      </div>
    </div>
    <!-- modals -->
    <modal
      name="modalExcluirDocumento"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
      :width="modalWidthSm"
    >
      <div v-if="$store.state.fastCarregando" class="row">
        <div class="text-center my-5 mx-auto">
          <b-icon icon="gear-fill" animation="spin" /> Carregando
        </div>
      </div>
      <div v-else class="fd-app-table-novo-logs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Remover documento</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalExcluirDocumento') ">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div class="col-sm-12 col-md-12 col-lg-12 p-3 text-center">
          <p>Deseja realmente excluir o documento?</p>
          <a class="btn-novo btn-danger m-2 text-white" @click="excluirDocumento()">Sim, excluir o documento</a>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
    name: "FastConsultaDocumentosAssinatura",
    components: {
    },
    props: {
        id_assinatura_matricula: {
          type: Number,
          required: false,
        },
        id_plataforma: {
          type: Number,
          required: true,
        },
        id_matricula: {
          type: Number,
          required: false,
        }
    },
    
    mixins: [methods],
    data: function () {
      return {
        modalWidthSm: this.isMobile() ? "60%" : "50%",
        modalWidthMd: this.isMobile() ? "90%" : "50%",
        modalWidthLg: this.isMobile() ? "100%" : "90%",
        // Variáveis de documento
        fastAssinaturaCarregando: true,
        fastDocumentosTipos: [],
        fastDocumentosCategorias: [],
        fastConsultaDocumentosAssinatura: {
          id_assinatura_plataforma_documento: "",
          id_assinatura_gateway: "",
          id_plataforma: "",
          id_documento_gateway: "",
          url_arquivo_original: "",
          url_arquivo_assinado: "",
          assinado: "",
          id_assinatura_documento_tipo: "",
          id_assinatura_plataforma_documento_categoria: "",
          data_registro_documento: "",
          fast_assinatura_plataforma_documento_usuario: [
            {
              id_assinatura_plataforma_documento_usuario: "",
              id_assinatura_plataforma_documento: "",
              id_plataforma: "",
              id_pessoa_gateway: "",
              assinou: "",
              rejeitou: "",
              visualizou: "",
              enviado_email: "",
              enviado_sms: "",
              enviado_whatsapp: "",
              log_auditoria: "",
              id_pessoa: "",
              nome_usuario: "",
              cpf_usuario: "",
              email_usuario: "",
              telefone_usuario: "",
              data_envio: "",
              ddi: "",
              assinou_data: "",
              visualizou_data: "",
              fast_assinatura_plataforma_documento: ""
            },
          ]
        },
        // Upload arquivo
      }        
    },
    mounted: function () {   
      if (this.id_assinatura_matricula && this.id_plataforma){
        this.fastAssinaturaCarregando = true
        this.getDocumentoAssinaturaInfo();
        this.getTiposDeDocumento();
        this.getCategoriasDeDocumento();
        this.fastAssinaturaCarregando = false
      } else {
        this.exibeToasty("O identificador da pessoa ou da plataforma não foram informados", "error")
        this.fastAssinaturaCarregando = false      
      }
    },
    computed: {
    },
    methods: {
    getDocumentoAssinaturaInfo(){
      this.fastAssinaturaCarregando = true
      this.promiseGetFastApi("api/fast_assinatura_plataforma_documento/lista_id", `id_plataforma=${this.$route.params.id_plataforma}&id_assinatura_plataforma_documento=${this.id_assinatura_matricula}`)
      .then((res) => {
        if (res) {
          this.fastConsultaDocumentosAssinatura = res
        }
        if(this.fastConsultaDocumentosAssinatura.assinado){
          this.$store.state.fastControlaComponentes.showHideAprovarSolicitacaoMatricula = true;
        }
        this.fastAssinaturaCarregando = false
      }).catch(e => {
        this.exibeToasty(e, "error")
        console.log(e)
        this.fastAssinaturaCarregando = false
      })
    },
    async getTiposDeDocumento(){
      this.fastAssinaturaCarregando = true
      await this.promiseGetFastApi("api/fast_assinatura_documento_tipo/lista_todos", `id_plataforma=${this.$route.params.id_plataforma}`)
      .then((res) => {
        if (res) {
          this.fastDocumentosTipos = res
        }
        // console.log("TiposDeDocumento: ", this.fastDocumentosTipos)
        this.fastAssinaturaCarregando = false
      }).catch(e => {
        this.exibeToasty(e, "error")
        console.log(e)
        this.fastAssinaturaCarregando = false
      })
    },
    getCategoriasDeDocumento(){
      this.fastAssinaturaCarregando = true
      this.promiseGetFastApi("api/fast_assinatura_plataforma_documento_categoria/lista_id", `id_plataforma=${this.$route.params.id_plataforma}`)
      .then((res) => {
        if (res) {
          this.fastDocumentosCategorias = res
        }
        // console.log("CategoriasDeDocumento: ", this.fastDocumentosCategorias)
        this.fastAssinaturaCarregando = false
      }).catch(e => {
        this.exibeToasty(e, "error")
        console.log(e)
        this.fastAssinaturaCarregando = false
      })
    },
    getNameTipoDocumento(id_tipo){
      if (id_tipo) {
        const nome_tipo = [...this.fastDocumentosTipos].find(tipo => tipo.id_assinatura_documento_tipo === id_tipo)
          return nome_tipo.nome_assinatura_documento_tipo ? nome_tipo.nome_assinatura_documento_tipo : null;
      }
    },
    getNameCategoriaDocumento(id_categoria){
      if (id_categoria) {
        const nome_categoria = [...this.fastDocumentosCategorias].find(categoria => categoria.id_assinatura_plataforma_documento_categoria === id_categoria)
          return nome_categoria.nome_assinatura_plataforma_documento_categoria ? nome_categoria.nome_assinatura_plataforma_documento_categoria : null;
      }
    },
    async excluirDocumento(){
      this.fastAssinaturaCarregando = true
      if (this.id_matricula) {
        let obj = {
          id_assinatura_plataforma_documento: this.id_assinatura_matricula,
          id_plataforma: this.id_plataforma,
          id_matricula: this.id_matricula
        }
        return new Promise(async (resolve, reject) => {
          try {
            let resp = await fetch(settings.endApiFastEad + "api/fast_secretaria_matricula_aluno/exclui_documento_assinatura", this.fastAjaxOptions("POST", JSON.stringify(obj)))
            let res = await resp.json();
            if (res.success == false) {
              console.log(res.error)
              console.log(res.description)
              this.exibeToasty(res.error + " " + res.description, "error")
            } else {
              this.exibeToasty("Documento removido com sucesso", "success")
              resolve(resp)
              window.location.reload();
            }
            this.fastAssinaturaCarregando = false
          } catch (error) {
            console.log(error)
            this.exibeToasty(error, "error")
            reject(error)
            this.fastAssinaturaCarregando = false
          }
        })
      } else {
        let obj = {
          id_assinatura_plataforma_documento: this.id_assinatura_matricula,
          id_plataforma: this.id_plataforma
        }
        return new Promise(async (resolve, reject) => {
          try {
            let resp = await fetch(settings.endApiFastEad + "api/fast_assinatura_plataforma_documento/exclui_documento_gateway", this.fastAjaxOptions("POST", JSON.stringify(obj)))
            let res = await resp.json();
            if (res.success == false) {
              console.log(res.error)
              console.log(res.description)
              this.exibeToasty(res.error + " " + res.description, "error")
            } else {
              this.exibeToasty("Documento removido com sucesso", "success")
              resolve(resp)
              window.location.reload();
            }
            this.fastAssinaturaCarregando = false
          } catch (error) {
            console.log(error)
            this.exibeToasty(error, "error")
            reject(error)
            this.fastAssinaturaCarregando = false
          }
        })
        
      }
    },
    atualizarInformacoes () {
      this.fastAssinaturaCarregando = true
      this.promisePostFastApi("", `api/fast_assinatura_plataforma_documento/sincroniza_documento_gateway?id_plataforma=${this.id_plataforma}&id_assinatura_plataforma_documento=${this.id_assinatura_matricula}`)
      .then((res) => {
        if (res.success == false) {
          console.log(res.error)
          console.log(res.description)
          this.exibeToasty(res.error + " " + res.description, "error")
        } else {
          console.log(res)
          this.exibeToasty("Informações atualizadas!", "success")
          this.getDocumentoAssinaturaInfo()
        }
        this.fastAssinaturaCarregando = false
      }).catch((e) => {
        console.log(e)
        this.exibeToasty(e, "error")
        this.fastAssinaturaCarregando = false
      })
    },
  }
}
</script>
<style scoped>
.info-sign-text, .info-sign-text-negative, .info-sign-text-positive, .info-sign-text-info, .info-sign-text-dark {
  border-radius: 5px;
  padding: .2em .5em;
}
.info-sign-text {
  background-color: #EBE9E8;
}
.info-sign-text-negative {
  background-color: #FDCBBF;
}
.info-sign-text-positive {
  background-color: #BDF6B2;
}
.info-sign-text-info {
  background-color: #c3f5f7;
}
.info-sign-text-dark {
  background-color: #333;
  color: #FFF;
}

.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}
.overflow-y-auto {
  max-height: 40rem;
  overflow-y: scroll; 
  overflow-x: hidden;
}
@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
</style>